import { Box } from '@mui/material'
import { mapLoader } from 'components/domain/GoogleMaps'
import { CONTACT_PHONE } from 'config'
import type React from 'react'
import { useEffect } from 'react'

export const ContactMap: React.FC = () => {
    useEffect(() => {
        mapLoader.importLibrary('maps').then(({ Map, InfoWindow }) => {
            const map = new Map(document.getElementById('map') as HTMLElement, {
                center: { lat: 12.4773632, lng: -69.9795995 },
                zoom: 15,
                mapId: 'DEMO_MAP_ID',
                streetViewControl: false,
                mapTypeControl: false
            })
            const infoWindowContent = `
                <div>
                    <div style="display: flex; justify-content: space-between; width: 100%">
                        <div style="text-align: left; width: 75%">
                            <div><b>Water-En Energiebedrijf Aruba</b></div>
                            <div>Balashi 76, Oranjestad, Aruba</div>
                            <div>Monday - Friday 7:45 AM - 3:45 PM</div>
                            <div>${CONTACT_PHONE}</div>
                            <a href="https://maps.app.goo.gl/AadHTLGSS4yGHPd8A"
                            target="_blank"
                            rel="noreferrer"
                        >
                            View larger map
                        </a>
                        </div>
                        <div style="width: 25%">
                            <a style="color: #3a84df; display: block; height: 43px; text-decoration: none; width: 54.7344px" target="_blank" href="https://www.google.com/maps/dir//F2G9%2B4C2+Water-En+Energiebedrijf+Aruba,+Balashi+76,+Oranjestad,+Aruba/@12.4749119,-69.9795995,1009m/data=!3m1!1e3!4m9!4m8!1m0!1m5!1m1!1s0x8e853ec740ad9ff1:0xf72552719f0567b6!2m2!1d-69.9795995!2d12.4749119!3e0?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D">
                            <div style="background: rgba(0, 0, 0, 0) url(https://maps.gstatic.com/mapfiles/embed/images/entity11.png) no-repeat; height: 22px; width: 22px; margin-right: auto; margin-left: auto"></div>    
                            Directions
                            </a>
                        </div>
                    </div>
                </div>
            `
            const infoWindow = new InfoWindow({
                content: infoWindowContent,
                disableAutoPan: true
            })
            mapLoader.importLibrary('marker').then(({ AdvancedMarkerElement }) => {
                const marker = new AdvancedMarkerElement({
                    map,
                    position: { lat: 12.4749119, lng: -69.9795995 },
                    title: 'Water-En Energiebedrijf Aruba'
                })
                infoWindow.open(map, marker)
                return marker
            }).catch((e) => { console.log(e) })
        }).catch((e) => { console.log(e) })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box id="map" height="30vh" />
    )
}

export default ContactMap
