import { Box } from '@mui/material'
import { ProgressLoaders } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'utils/hooks'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ContactUsByEmail, Form } from './Components'

export const Contact: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  const { account, getAccount, currentAccount, isAdmin, userEmail } = useAuth()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [authToken] = useLocalStorage(USER_TOKEN, '')

  useEffect(() => {
    if (
      prevAccountId === currentAccount?.accountId ||
      currentAccount?.accountId === account?.myAccount?.accountId
    ) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId(
      currentAccount?.accountId ?? account?.myAccount?.accountId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  return (
    <>
      {isMobile && <QuickLinks />}
      {account !== null
      ? (
        <Box
          display='flex'
          mt={3}
          flexDirection={isMobile ? 'column' : 'row'}
          p={isMobile ? 3 : 4}
          bgcolor={color.white}
          gap={5}
        >
          <Form />
          <ContactUsByEmail isAuthenticated />
        </Box>
      )
      : (
        <Box mt={5}>
          <ProgressLoaders height='100%' />
        </Box>
      )}
    </>
  )
}
