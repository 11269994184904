import Box from '@mui/material/Box'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { GenericDialog, MButton } from 'components'
import { Typography } from '@mui/material'
import { color } from 'config/color'

export const UnsavedChangesDialog = ({
    open,
    isLoading,
    onSave,
    onClose,
    onConfirm
}: any): JSX.Element => {
    const isMobile = useMobileBreakpoints()
    const handleOnSave = async (): Promise<void> => {
        await onSave()
        await onClose()
    }

    return (
        <Box>
            <GenericDialog
                open={open}
                onClose={onClose}
                isLoading={isLoading}
                title='Unsaved Changes'
                iconType='warning'
            >
                <Box>
                    <Typography
                        color={color.grey[700]}
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                    >
                        You are about to leave this page without saving. All changes will be lost.
                    </Typography>

                    <Typography
                        color={color.grey[700]}
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                        mb={3}
                    >
                        Do you want to leave without saving?
                    </Typography>

                    <Box display='flex' flexDirection='column' gap={1}>
                        <MButton
                            variant='contained'
                            size='large'
                            rounded='true'
                            type='button'
                            fullWidth
                            onClick={handleOnSave}
                            texttransform='none'
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Save Changes
                        </MButton>

                        <MButton
                            variant='outlined'
                            type='button'
                            size='large'
                            rounded='true'
                            fullWidth
                            onClick={onClose}
                            texttransform='none'
                            disabled={isLoading}
                        >
                            Keep Editing
                        </MButton>
                        <MButton
                            type='button'
                            size='large'
                            rounded='true'
                            fullWidth
                            onClick={onConfirm}
                            texttransform='none'
                            disabled={isLoading}
                        >
                            Leave without saving
                        </MButton>
                    </Box>
                </Box>
            </GenericDialog>
        </Box>
    )
}
