export function formatValue (
  balance: string,
  includeDecimals: boolean = true,
  includeCurrencySymbol: boolean = true
): string {
  const numBalance = Number(balance)

  if (isNaN(numBalance)) {
    return balance
  }

  const isNegative = numBalance < 0
  const formattedBalance = Math.abs(numBalance).toFixed(2)

  const formattedValue = includeDecimals
    ? formattedBalance
    : Math.floor(Math.abs(numBalance)).toString()
  const currencyValue = isNegative
    ? `-${formattedValue} AWG`
    : `${formattedValue} AWG`

  return includeCurrencySymbol ? currencyValue : formattedValue
}
