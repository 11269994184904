import { Grid, Alert, Typography } from '@mui/material'
import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface NotificationBannerProps {
    onClose: () => void
    isAuthenticated?: boolean
}

export const NotificationBanner: React.FC<NotificationBannerProps> = ({
    onClose
}: NotificationBannerProps): JSX.Element => {
    const isMobile = useMobileBreakpoints()
    return (
        <Grid container justifyContent="center">
            <Alert
                severity="warning"
                icon={false}
                onClose={onClose}
                sx={{
                    bgcolor: color.background[900],
                    color: '#FFFFFF',
                    px: { xs: 2, md: 30 },
                    py: 3,
                    width: '100%',
                    mx: 'auto',
                    textAlign: 'center',
                    borderRadius: '0px'
                }}
            >
                <Typography fontWeight="bold" marginLeft={isMobile ? '0' : '100px'}>
                    Your billing and payment information will be available starting in November 2024. For previous billing information, please contact our Call Center at 280-4600 or reach out via WEB ARUBA CHAT on WhatsApp at 525-4600.{' '}
                </Typography>
            </Alert>
        </Grid>
    )
}
