import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Typography, OutlinedInput } from '@mui/material'
import { GenericDialog, FormLabel, ErrorText, MButton } from 'components'
import { color } from 'config/color'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'

interface INewUserDialog {
  open: boolean
  isLoading: boolean
  onClose: () => void
  addUserSubmit: (data: any) => void
}

interface IFormData {
  name: string
  phoneNumber: string
  email: string
  emailConfirm: string
  isLoading: boolean
}
export const NewUserDialog = ({
  open,
  isLoading,
  onClose,
  addUserSubmit
}: INewUserDialog): JSX.Element => {
  const schema = yup
    .object({
      name: yup.string()
        .required('Name is required'),
      phoneNumber: yup
        .string()
        .matches(
          /^\d{3}-\d{4}$/,
          'Please enter a valid phone number in the format 999-9999'
        )
        .required('Phone number is required'),
      email: yup.string()
        .email('Please enter a valid email')
        .required('Email is required'),
      emailConfirm: yup
        .string()
        .email('Please enter a valid email')
        .oneOf([yup.ref('email'), null], 'Email must match')
    })
    .required()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IFormData>({
    mode: 'all',
    defaultValues: {
      email: '',
      emailConfirm: ''
    },
    resolver: yupResolver(schema)
  }) as {
    handleSubmit: any
    control: any
    formState: { errors: any, isValid: any }
  }

  return (
    <Box>
      <GenericDialog open={open} onClose={onClose}>
        <form method="post" onSubmit={handleSubmit(addUserSubmit)}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column">
              <Typography
                color={color.grey[900]}
                fontWeight={500}
                fontSize={24}
              >
                New Secondary User
              </Typography>

              <Typography
                color={color.grey[700]}
                fontWeight={400}
                fontSize={16}
              >
                Enter the following data to grant access to a new user.
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <>
                    <FormLabel textcolor={color.grey[700]}>Name *</FormLabel>
                    <OutlinedInput
                      name={name}
                      placeholder="Enter secondary user name"
                      onChange={onChange}
                      value={value}
                      type="text"
                      fullWidth
                    />
                  </>
                )}
              />

              <Controller
                name="phoneNumber"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <>
                    <FormLabel textcolor={color.grey[700]}>
                      Phone Number *
                    </FormLabel>
                    <OutlinedInput
                      name={name}
                      placeholder="Enter phone number"
                      onChange={onChange}
                      inputProps={{ maxLength: 8 }}
                      value={value}
                      type="text"
                      fullWidth
                    />
                  </>
                )}
              />
              {errors.phoneNumber && (
                <ErrorText>{errors.phoneNumber.message}</ErrorText>
              )}

              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <>
                    <FormLabel textcolor={color.grey[700]}>
                      Email address *
                    </FormLabel>
                    <OutlinedInput
                      name={name}
                      placeholder="Enter email address"
                      onChange={onChange}
                      value={value}
                      type="text"
                      fullWidth
                    />
                  </>
                )}
              />
              {errors.email && (
                <ErrorText>{errors.email.message}</ErrorText>
              )}

              <Controller
                name="emailConfirm"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <>
                    <FormLabel textcolor={color.grey[700]}>
                      Confirm email address *
                    </FormLabel>
                    <OutlinedInput
                      name={name}
                      placeholder="Enter email address"
                      onChange={onChange}
                      value={value}
                      type="text"
                      fullWidth
                    />
                    {errors.emailConfirm && (
                      <ErrorText>{errors.emailConfirm.message}</ErrorText>
                    )}
                  </>
                )}
              />
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <MButton
                loading={isLoading}
                type="submit"
                variant="contained"
                rounded="true"
                texttransform="none"
                disabled={!isValid}
              >
                Continue
              </MButton>

              <MButton
                variant="outlined"
                type="button"
                rounded="true"
                texttransform="none"
                onClick={onClose}
              >
                Go Back
              </MButton>
            </Box>
          </Box>
        </form>
      </GenericDialog>
    </Box>
  )
}
