import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import WidgetsIcon from '@mui/icons-material/Widgets'
import { AccordionDetails, AccordionSummary, Box, IconButton, Typography, type SvgIconTypeMap } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import { type OverridableComponent } from '@mui/material/OverridableComponent'
import { MButton } from 'components/ui'
import { MESSAGES, PAY_TOOLS } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IQuickLinks {
    title: string
    Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>
    page?: number
    navigate: string
}

const QuickLinks = (): JSX.Element => {
    const isMobile = useMobileBreakpoints()
    const { account } = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const handleItem = (item: IQuickLinks): void => {
        if (item.title === 'View Bill') {
            handleDownloadBill()
        } else if (item.title === 'Pay Bill') {
            window.open(item.navigate, '_blank')
        } else if (item.title === 'Paperless Billing') {
            navigate(item.navigate)
        }
    }
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const handleAccordionClick = (): void => {
        setIsExpanded(!isExpanded)
    }
    const handleDownloadBill = (): void => {
        if (!isNil(account?.myAccount?.billDisplayURL)) {
            window.open(account?.myAccount?.billDisplayURL, '_blank', 'noopener,noreferrer')
        } else {
            enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
                variant: 'error',
                autoHideDuration: 5000
            })
        }
    }
    return (isMobile
        ? (
            <>
                <Box mt={2}>
                    <Accordion onClick={handleAccordionClick}>
                        <AccordionSummary
                            expandIcon={<IconButton disableRipple={true} size="small" sx={{ bgcolor: color.grey[200] }}>{isExpanded ? <RemoveIcon sx={{ color: color.primary[600] }} /> : <AddIcon sx={{ color: color.primary[600] }} />}</IconButton>}
                        >
                            <Box display="flex" gap={1} p={0.5}>
                                <WidgetsIcon sx={{ color: color.primary[600] }} />
                                <Typography >Quick Links</Typography>
                            </Box>

                        </AccordionSummary>
                        <AccordionDetails>
                            {PAY_TOOLS.map((tool, index) => (
                                <MButton
                                    key={index}
                                    texttransform="none"
                                    rounded="true"
                                    size="large"
                                    sx={{
                                        background: color.background[100],
                                        boxShadow: 'none',
                                        color: color.grey[800],
                                        px: 2
                                    }}
                                    startIcon={<tool.Icon sx={{ color: color.primary[700] }} />}
                                    onClick={() => {
                                        handleItem(tool)
                                    }}
                                >
                                    {tool.title}
                                </MButton>
                            ))}
                        </AccordionDetails>

                    </Accordion>
                </Box>
            </>
        )
        : (
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    zIndex={999}
                    py={3}
                    px={5}
                    bgcolor={color.background[50]}
                    borderBottom={`solid 1px ${color.background[300]}`}
                >
                    <Typography color={color.grey[700]} fontSize={12} fontWeight={500} letterSpacing={2}>
                        QUICK LINKS
                    </Typography>
                    <Box display="flex" flexDirection="row" gap={3}>
                        {PAY_TOOLS.map((tool, index) => (
                            <MButton
                                key={index}
                                texttransform="none"
                                rounded="true"
                                size="large"
                                sx={{
                                    background: color.background[100],
                                    boxShadow: 'none',
                                    color: color.grey[800],
                                    px: 2
                                }}
                                startIcon={<tool.Icon sx={{ color: color.primary[700] }} />}
                                onClick={() => {
                                    handleItem(tool)
                                }}
                            >
                                {tool.title}
                            </MButton>
                        ))}
                    </Box>
                </Box>
            </>
        ))
}

export default QuickLinks
