import { Box, styled } from '@mui/material'

export const TableHeader = styled(Box)({
  display: 'flex',
  background: '#F6F6F6'
})

export const ItemWrapper = styled(Box)<{ small?: string, width?: string }>(
  ({ small, width }) => ({
    width,
    padding: 15,
    color: '#010B0E',
    fontSize: small === 'true' ? '14px' : '16px',
    fontWeight: small === 'true' ? '400' : 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  })
)

export const TableItem = styled(Box)({
  display: 'flex',
  borderBottom: '1px solid #eee',
  '&:hover': {
    backgroundColor: '#FBFBFB'
  }
})
