import styled from '@emotion/styled'
import { Add } from '@mui/icons-material'
import { Box, Divider, Radio, Typography } from '@mui/material'
import { FormLabel } from 'components'
import { MESSAGES, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { APIs } from 'services'
import { useLocalStorage, useMobileBreakpoints } from 'utils'
import { type ServiceAddress } from 'utils/hooks/types'

export const AccountTable = ({ serviceAddresses }: { serviceAddresses: ServiceAddress[] }): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  const { enqueueSnackbar } = useSnackbar()
  const { account, currentAccount, getAccount, isAdmin, userEmail, handleCurrentAccount } = useAuth()
  const [defaultAccountId, setDefaultAccountId] = useState<string | null>(null)
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [active] = useState<number>(0)
  const [authToken] = useLocalStorage(USER_TOKEN, '')


  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  const handleCheckBox = async (item: any): Promise<void> => {
    try {
      const body: any = {
        AccessToken: authToken,
        accountId: account?.myAccount.accountId,
        admin: isAdmin,
        email: userEmail
      }
      body.updates = [
        {
          object: 'DEFAULTACCOUNT',
          value: item.accountId
        }
      ]
      await APIs.updateUser(body)
      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
        variant: 'success',
        autoHideDuration: 3000
      })
      setDefaultAccountId(item.accountId)
      handleCurrentAccount({ accountId: item.accountId ?? '', premiseId: item?.premiseId ?? '' })
      void getAccount({
        AccessToken: authToken,
        accountId: item.accountId
      })
    } catch (e) {
      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  useEffect(() => {
    if ((account !== null) && (defaultAccountId === null)) {
      setDefaultAccountId(account?.myAccount?.defaultAccountId ?? null)
    }
  }, [account, defaultAccountId])

  return (
    <div>
      {serviceAddresses.filter((value: any, index: any, self: any) =>
        self.findIndex((v: any) => v.accountId === value.accountId) === index
      ).map((service: any, i: number) => (
        <Box border={`1px solid ${color.background[200]}`} key={i}>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            p={1}
            bgcolor={color.grey[50]}
            gap={isMobile ? 1 : 0}
          >
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Box
                display="flex"
                flexDirection='row'
                alignItems="center"
                gap={1}
                bgcolor={active === 0 ? '#F9F9FD' : '#ffffff'}
              >
                <Radio
                  onChange={() => { void handleCheckBox(service) }}
                  checked={service?.accountId === defaultAccountId}
                />
                <Box>
                  <Typography
                    fontSize={10}
                    color={color.grey[700]}
                    letterSpacing={1}
                  >
                    ACCOUNT NUMBER
                  </Typography>
                  <Typography fontSize={16} color={color.grey[900]}>
                    {service.accountId}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* TODO: add logic for adding, linking, and removing guest users */}

            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1}>
              {/* <Box
                component="button"
                width={isMobile ? '100%' : 'auto'}
                borderRadius="20px"
                border={`solid 1px ${color.grey[200]}`}
                bgcolor={color.white}
                pl={2}
                py={0.5}
                onClick={() => { setActive(0) }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box display="flex" flexDirection="column">
                    <Typography
                      color={color.grey[800]}
                      fontWeight={400}
                      fontSize={14}
                    >
                      {account?.myAccount?.personName}
                    </Typography>

                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={1}
                    >
                      <Circle
                        sx={{
                          color: color.success[600],
                          fontSize: 12
                        }}
                      />

                      <Typography
                        color={color.grey[700]}
                        fontWeight={400}
                        fontSize={10}
                      >
                        Linked
                      </Typography>
                    </Box>
                  </Box>

                  <Link
                    sx={{
                      color: color.background[700],
                      backgroundColor: color.grey[100],
                      borderRadius: '50%',
                      padding: 1,
                      fontSize: 20
                    }}
                  />
                </Box>
              </Box> */}

              <Box
                component="button"
                width={isMobile ? '100%' : '130px'}
                borderRadius="20px"
                border={`solid 1px ${color.grey[200]}`}
                bgcolor={color.white}
                pl={2}
                py={0.5}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems='center'
                >
                  <Typography
                    color={color.grey[700]}
                    fontWeight={400}
                    fontSize={14}
                  >
                    Add User
                  </Typography>
                  <Add
                    sx={{
                      color: color.background[700],
                      backgroundColor: color.grey[100],
                      borderRadius: '50%',
                      padding: 1,
                      fontSize: 20
                    }}
                  />
                </Box>
              </Box>

              <Box
                component="button"
                width={isMobile ? '100%' : '130px'}
                borderRadius="20px"
                border={`solid 1px ${color.grey[200]}`}
                bgcolor={color.white}
                pl={2}
                py={0.5}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems='center'
                >
                  <Typography
                    color={color.grey[700]}
                    fontWeight={400}
                    fontSize={14}
                  >
                    Add User
                  </Typography>
                  <Add
                    sx={{
                      color: color.background[700],
                      backgroundColor: color.grey[100],
                      borderRadius: '50%',
                      padding: 1,
                      fontSize: 20
                    }}
                  />
                </Box>
              </Box>

            </Box>
          </Box>

          <Divider sx={{ color: color.background[200] }} />

          {isMobile
            ? (
              <Box>
                <LinkedAccountsTableMobileView
                  accountId={service.legacyAcctNumber}
                  serviceAddress={service.serviceAddress}
                />
              </Box>
            )
            : (
              <Table>
                <TableHeader>
                  <ItemWrapper width="70%">
                    <FormLabel textcolor={color.grey[600]}>
                      PROPERTY ADDRESS (1)
                    </FormLabel>
                  </ItemWrapper>
                  <ItemWrapper width="30%">
                    <FormLabel textcolor={color.grey[600]}>
                      OLD ACCOUNT NUMBER
                    </FormLabel>
                  </ItemWrapper>
                </TableHeader>

                <Divider sx={{ color: color.background[200] }} />

                <TableContent>
                  <TableItem>
                    <ItemWrapper width="70%">
                      <Typography color={color.grey[700]} fontSize={14}>
                        {service.serviceAddress}
                      </Typography>
                    </ItemWrapper>
                    <ItemWrapper width="30%">
                      <Typography color={color.grey[700]} fontSize={14}>
                        {service.legacyAcctNumber}
                      </Typography>
                    </ItemWrapper>
                  </TableItem>
                </TableContent>
              </Table>
            )}
        </Box>
      )) ?? null}
    </div>
  )
}

interface ILinkedAccountsTableMobileView {
  accountId: string
  serviceAddress: string
}

const LinkedAccountsTableMobileView = (
  props: ILinkedAccountsTableMobileView
): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      gap={2}
      borderBottom={`solid 1px ${color.background[200]}`}
    >
      <Box display="flex" flexDirection="column">
        <FormLabel textcolor={color.grey[600]}>Account Number</FormLabel>
        <Typography color={color.grey[700]} fontSize={14}>
          {props?.accountId}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <FormLabel textcolor={color.grey[600]}>Service Address</FormLabel>
        <Typography color={color.grey[700]} fontSize={14}>
          {props?.serviceAddress}
        </Typography>
      </Box>
    </Box>
  )
}

const Table = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  background: color.white
})

const TableHeader = styled(Box)({
  display: 'flex',
  borderBottom: color.background[200],
  fontSize: 14,
  fontWeight: 600,
  paddingLeft: 20,
  paddingRight: 20
})

const TableContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 20,
  paddingRight: 20
})

const TableItem = styled(Box)({
  display: 'flex'
  // '&:hover': {
  //   backgroundColor: color.primary[50]
  // }
})

const ItemWrapper = styled(Box)<{ width?: string }>(({ width }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  width,
  paddingTop: 10,
  paddingBottom: 10,
  color: color.black,
  fontSize: 14,
  fontWeight: 400
}))
