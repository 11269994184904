import { Box, Grid, Typography } from '@mui/material'
import { ProgressLoaders } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'utils'
import { type UsageData, type UsageResponse } from 'utils/hooks/types'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import {
  Bill,
  NumberStats,
  PowerCompare,
  PowerUsage
} from './components'

const IS_FIRST_LOGIN = 'IS_FIRST_LOGIN'

export const Dashboard: React.FC = () => {
  const { account, currentAccount, isAdmin, getUsage, premiseId, userEmail, usage } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [dashboardStats, setDashboardStats] = useState<IDashboardStats | null>(null)
  const [dashboardUsage, setDashboardUsage] = useState<UsageData | null>(null)
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [isFirstLogin, setIsFirstLogin] = useLocalStorage(IS_FIRST_LOGIN, '')
  const emptyDashboardData = (): void => {
    setDashboardUsage({
      status: '',
      premiseId: '',
      frequency: '',
      isNEM1: '',
      isNEM2: '',
      usageHistory: []
    })
    setDashboardStats({
      usageThisMonth: 0,
      highestThisYear: 0,
      monthlyAverage: 0,
      totalUsage: 0
    })
  }

  useEffect(() => {
    setDashboardUsage(null)
    setDashboardStats(null)
    const fetchDashboardUsage = async (): Promise<void> => {
      const usageStatus = usage?.usage?.status ?? false
      if (account?.perAcctList?.length === 0) emptyDashboardData()
      if ((prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) && usage !== null && usageStatus === false) {
        handleDashboardUsageData(usage)
      } else {
        const dashboardUsage = await getUsage({
          AccessToken: authToken,
          premiseId: currentAccount?.premiseId ?? premiseId,
          frequency: 'M',
          startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
          service: 'WATER',
          admin: isAdmin,
          email: userEmail
        })
        if (dashboardUsage?.usage?.usageHistory != null) handleDashboardUsageData(dashboardUsage)
      }
      setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    }
    void fetchDashboardUsage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, userEmail, isAdmin, authToken])

  useEffect(() => {
    if (isFirstLogin.length === 0) {
      setIsFirstLogin('true')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDashboardUsageData = (usageRes: UsageResponse): void => {
    if (usageRes?.usage?.usageHistory[0]?.usageData != null) {
      const usageArr = usageRes?.usage?.usageHistory[0]?.usageData
      const valuesArray = usageArr.map((item) => parseInt(item.value))
      const usageThisMonth = valuesArray[valuesArray.length - 1]
      const highestThisYear = Math.max(...valuesArray)
      const totalUsage = +(valuesArray.reduce((acc, value) => acc + value, 0).toFixed(2))
      const monthlyAverage = +(totalUsage / valuesArray.length).toFixed(2)
      setDashboardUsage(usageRes.usage)
      setDashboardStats({
        usageThisMonth: usageThisMonth ?? 0,
        highestThisYear,
        monthlyAverage,
        totalUsage
      })
    } else {
      emptyDashboardData()
    }
  }

  return (
    <>
      {isMobile && <Box pb={2}>
        <Typography fontSize={24} fontWeight={300}>Welcome back <b>{account?.myAccount?.personName}</b></Typography>
        <QuickLinks />
      </Box>}
      <Grid container spacing={3}>
        <Grid item xs={12} xl={7}>
          <Bill />
        </Grid>
        <Grid item xs={12} xl={5}>
          {dashboardStats != null
            ? <NumberStats dashboardStats={dashboardStats} />
            : <ProgressLoaders height="100%" />}
        </Grid>
        <Grid item xs={12} xl={7}>
          {dashboardUsage != null
            ? <PowerUsage dashboardUsage={dashboardUsage} />
            : <ProgressLoaders height="100%" />}
        </Grid>
        <Grid item xs={12} xl={5}>

          {dashboardStats != null
            ? <PowerCompare dashboardStats={dashboardStats} />
            : <ProgressLoaders height="100%" />}
        </Grid>

      </Grid >

    </>
  )
}

export interface IDashboardStats {
  usageThisMonth: number
  highestThisYear: number
  monthlyAverage: number
  totalUsage: number
}
