import { Box, Grid } from '@mui/material'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { color } from 'config/color'
import { ContactUsByEmail, Form } from './Components'
import { AuthLayout } from 'components'
import { AuthWrapper } from 'containers/Auth'

export const ContactUs: React.FC = () => {
  const isMobile = useMobileBreakpoints()

  return (
    <AuthLayout>
      <AuthWrapper>
        <Grid container justifyContent='center'>
          <Grid item>
            <Box
              display='flex'
              mt={3}
              flexDirection={isMobile ? 'column' : 'row'}
              p={isMobile ? 3 : 4}
              bgcolor={color.white}
              gap={5}
              width='70vw'
            >
              <Form isAuthenticated={false} />
              <ContactUsByEmail isAuthenticated />
            </Box>
          </Grid>
        </Grid>
      </AuthWrapper>
    </AuthLayout>
  )
}
