import { yupResolver } from '@hookform/resolvers/yup'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
  styled
} from '@mui/material'
import { ErrorText, MButton, Text } from 'components'
import { EMAIL, ROUTES_PATH } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'utils/hooks'
import * as yup from 'yup'

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required()
  })
  .required()

export interface IAuthForm {
  email: string
  password: string
}

export const LoginForm: React.FC = () => {
  const { rememberMe, handleRememberMe, handleLogin, isLoading } = useAuth()
  const [error, setError] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [email, setEmail] = useLocalStorage(EMAIL, '')
  const { handleSubmit, formState: { errors, isValid }, control } = useForm<IAuthForm>({
    mode: 'all',
    defaultValues: {
      email: email ?? '',
      password: ''
    },
    resolver: yupResolver(schema)
  })
  const navigate = useNavigate()

  const onSubmit = async (data: IAuthForm): Promise<void> => {
    try {
      const result = await handleLogin(data)
      if (result) {
        navigate(ROUTES_PATH.DASHBOARD)
        if (rememberMe) {
          setEmail(data.email)
        } else {
          setEmail('')
        }
      }
    } catch (err: any) {
      setError(err)
    }
  }
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  const handleRegister = (): void => {
    navigate(ROUTES_PATH.SIGNUP)
  }

  const handleChange = (e: any, onChange: any): void => {
    onChange(e.target.value)
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={1} mb={2}>
          <Typography fontSize={24} color={color.grey[900]}>Enter your login credentials&nbsp;or
            <Link fontSize={24} sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(ROUTES_PATH.SIGNUP) }}> register </Link>
            to create an account</Typography>
        </Box>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <Box>
              <FormLabel htmlFor="username">Email address</FormLabel>
              <OutlinedInput
                name={name}
                placeholder="Enter your email address"
                onChange={(e) => {
                  handleChange(e, onChange)
                }}
                value={value}
                type="email"
                required={true}
                fullWidth
                autoComplete="username"
              />
              {(errors.email != null) && (
                <ErrorText>{errors.email.message}</ErrorText>
              )}
            </Box>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <Box>
              <FormLabel htmlFor="password">Password</FormLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                placeholder="Enter your password"
                required={true}
                onChange={(e) => {
                  handleChange(e, onChange)
                }}
                value={value}
                name={name}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {(errors.password != null) && (
                <ErrorText>{errors.password.message}</ErrorText>
              )}
            </Box>
          )}
        />
        <FormControlLabel
          control={<Checkbox size="small" sx={{ color: color.background[300] }} />}
          label={<Typography fontSize={12}>Remember Me</Typography>}
          checked={rememberMe}
          onChange={() => { handleRememberMe(!rememberMe) }}
        />
        {(error.length > 0) && (
          <Text color={color.error[700]} fontSize="16px" margin="8px 0">
            {error}
          </Text>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={2} my={2}>
        <MButton
          variant="contained"
          type="submit"
          size="large"
          rounded="true"
          loading={isLoading}
          fullWidth
          disabled={!isValid}
          texttransform="none"
          sx={{ fontSize: 16 }}
        >
          Log into Portal
        </MButton>
        <MButton
          variant="outlined"
          type="button"
          size="large"
          rounded="true"
          onClick={handleRegister}
          fullWidth
          sx={{ fontSize: 16 }}
          texttransform="none"
        >
          Register
        </MButton>
        <Box display="flex" justifyContent="center" gap={5} alignItems="center" mt={3}>
          <MButton size="large" texttransform="none" onClick={() => { navigate(ROUTES_PATH.FORGOT_PASSWORD) }}>Reset Password </MButton>
          <MButton size="large" texttransform="none" onClick={() => { navigate(ROUTES_PATH.FORGOT_EMAIL) }}> Retrieve Email </MButton>
        </Box>

        <Typography fontSize={14} mt={1} color={color.grey[700]} textAlign="center">Still having problems? Please <Link fontSize={14} sx={{ cursor: 'pointer' }} onClick={() => { navigate(ROUTES_PATH.CONTACT_US) }}>Contact Us</Link>.</Typography>
      </Box>
    </form>
  )
}

export const FormLabel = styled(InputLabel)({
  fontSize: 12,
  color: color.grey[700],
  textTransform: 'uppercase',
  letterSpacing: 2,
  marginBottom: 5
})
