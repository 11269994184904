import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { UsageView } from './components/UsageView'

export const Usage: React.FC = () => {
  const isMobile = useMobileBreakpoints()

  return (
    <>
      {isMobile && <QuickLinks />}

      <UsageView />
    </>
  )
}
