import { Box } from '@mui/material'
import { BarChart as MUIBarChart } from '@mui/x-charts/BarChart'
import { color } from 'config/color'
import { isEmpty } from 'lodash'
import { ProgressLoaders } from '../ProgressLoaders'
import { NoResults } from './NoResult'

interface BarChartProps {
  seriesData: number[] | null
  xAxisData: string[]
  valueFormatter: (value: number | string | null) => string
  loading: boolean
}

export const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
  if (props.loading) {
    return <ProgressLoaders height={476} />
  }

  if (
    !((props.seriesData?.some((el) => el !== null)) ?? false) ||
    isEmpty(props.seriesData)
  ) {
    return (
      <Box minHeight={476}>
        <NoResults />
      </Box>
    )
  }


  return (
    <MUIBarChart
      series={[
        {
          color: color.secondary[200],
          data: props.seriesData ?? [],
          valueFormatter: props.valueFormatter
        }
      ]}
      height={476}
      xAxis={[{ data: props.xAxisData, scaleType: 'band' }]}
      grid={{ horizontal: true }}
      tooltip={{ trigger: 'axis' }}
      axisHighlight={{
        x: 'none'
      }}
      sx={sx}
    />
  )
}

const sx = {
  '& .MuiChartsAxis-left .MuiChartsAxis-tick': {
    stroke: color.white
  },
  '& .MuiChartsAxis-left .MuiChartsAxis-line': {
    stroke: color.white
  },
  '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
    fill: color.grey[700]
  },
  '.MuiChartsAxis-bottom .MuiChartsAxis-tick': {
    stroke: color.background[300]
  },
  '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
    stroke: color.background[300]
  },
  '& .MuiChartsGrid-line ': {
    stroke: color.background[300],
    shapeRendering: 'auto !important'
  },
  '& .MuiBarElement-root:hover': {
    fill: color.primary[600]
  }
}
