import { createTheme } from '@mui/material'
import { color } from './color'

export const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: color.primary[700]
    },
    secondary: {
      main: color.secondary[700]
    },
    background: {
      default: color.white
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          'box-shadow': 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          'box-shadow': 'none',
          'background-image': 'none'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          'box-shadow': 'none'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          'padding-top': '10px',
          'padding-bottom': '10px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75em'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px'
        },
        root: {
          borderRadius: '2px',
          border: `solid 1px ${color.white}`
        }
      }
    },
    MuiButton: {}
  }
})
