import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { ProgressLoaders, Text } from 'components'
import { ItemWrapper, TableHeader, TableItem } from 'components/ui/Table/Table'
import { USER_TOKEN } from 'config'
import { useAuth } from 'context'
import { formatValue, useLocalStorage, useMobileBreakpoints } from 'utils'
import { type DepositData } from 'utils/hooks/types'
import { NoData } from './NoData'

export function DepositRecords(): JSX.Element {
  const { account, deposits, getDeposits, currentAccount } = useAuth()
  const isMobile = useMobileBreakpoints()
  const [savedAuthToken] = useLocalStorage(USER_TOKEN, null)
  const [isLoading, setIsLoading] = useState<boolean>(false)


  useEffect(() => {
    const getDepositHistory = async (): Promise<void> => {
      setIsLoading(true)
      try {
        await getDeposits({
          accountId: account?.myAccount.accountId,
          AccessToken: savedAuthToken ?? '',
          startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        })
      } finally {
        setIsLoading(false)
      }
    }

    if (deposits == null) {
      void getDepositHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, savedAuthToken])


  return (
    <Box bgcolor="#FFF" p={3}>
      {isLoading
        ? <ProgressLoaders />
        : <>
          {(deposits?.deposit != null) && deposits?.deposit?.length === 0
            ? (
              <NoData transactionType="deposit" />
            )
            : (
              <>
                <DepositRecordsMobileView deposit={deposits?.deposit ?? []} />

                {!isMobile && (
                  <>
                    <Box mb={3}>
                      <Text fontSize="18px" color="#010B0E" fontWeight="500">
                        Deposit (s) Records
                      </Text>
                      <Text fontSize="14px" color="#5D6365">
                        All amounts are in (AWG)
                      </Text>
                    </Box>
                    <Box>
                      <TableHeader>
                        <ItemWrapper width="20%">Service</ItemWrapper>
                        <ItemWrapper width="20%">Deposit Date</ItemWrapper>
                        <ItemWrapper width="20%">Deposit Type</ItemWrapper>
                        <ItemWrapper width="20%">Deposit Amount </ItemWrapper>
                        <ItemWrapper width="20%">Last Applied</ItemWrapper>
                      </TableHeader>
                      {deposits?.deposit?.map((item) => (
                        <TableItem key={item.service}>
                          <ItemWrapper width="20%" small="true">
                            {item.service}
                          </ItemWrapper>
                          <ItemWrapper width="20%" small="true">
                            {item.depositDate}
                          </ItemWrapper>
                          <ItemWrapper width="20%" small="true">
                            {item.depositType}
                          </ItemWrapper>
                          <ItemWrapper width="20%" small="true">
                            {formatValue(item.depositAmount, true)}
                          </ItemWrapper>
                          <ItemWrapper width="20%" small="true">
                            {item.lastApplied}
                          </ItemWrapper>
                        </TableItem>
                      ))}
                    </Box>
                  </>
                )}
              </>
            )}
        </>}
    </Box>
  )
}

interface DepositRecordsMobileViewProps {
  deposit: DepositData[]
}
const DepositRecordsMobileView = ({
  deposit
}: DepositRecordsMobileViewProps): JSX.Element => {
  const isMobile = useMobileBreakpoints()

  return (
    <Box>
      {isMobile &&
        deposit?.map((dr: DepositData, index: number) => {
          return (
            <Box key={index} py={2} borderBottom="solid 1px #EDEFF0">
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Service
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {dr.service}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Deposit Date
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {dr.depositDate}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Deposit Type
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {dr.depositType}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Deposit Amount
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {formatValue(dr.depositAmount, true)}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Last Applied
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {dr.lastApplied}
                </Typography>
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}
