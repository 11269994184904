import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'

import { NOT_AUTHORIZED_EXCEPTION, REMEMBERME, USER_TOKEN } from 'config'
import { type IAuthForm } from 'containers/Auth/SignIn/component'
import {
  APIs,
  type IBilling,
  type IMeterHistory,
  type IPayments,
  type IUpdateMailAddress,
  type IUsageV2,
  type IUser
} from 'services'
import { type AccountResponse, type BillingResponse, type CurrentAccountType, type DepositResponse, type MeterRead, type PaymentResponse, type UsageResponse } from './types'
import { useLocalStorage } from './useLocalStorage'
export interface UseProvideAuthType {
  isLoading: boolean
  checkingUser: boolean
  premiseId: string
  userEmail: string
  account: AccountResponse | null
  payments: PaymentResponse | null
  billing: BillingResponse | null
  deposits: DepositResponse | null
  usage: UsageResponse | null
  isAdmin: boolean
  rememberMe: boolean
  currentAccount: CurrentAccountType | null
  meterRead: MeterRead[] | null
  getMeterRead: (param: IMeterHistory) => Promise<MeterRead[] | null>
  getAccount: (param: IUser) => Promise<AccountResponse | null>
  getPayments: (param: IPayments) => Promise<PaymentResponse | null>
  getBilling: (param: IBilling) => Promise<BillingResponse | null>
  getDeposits: (param: IBilling) => Promise<DepositResponse | null>
  getUsage: (param: IUsageV2) => Promise<UsageResponse | null>
  updateMailAddress: (param: IUpdateMailAddress) => Promise<any>
  handleLogin: (data: IAuthForm) => Promise<boolean | Error>
  handleLoginFromAdmin: (param: IUser) => Promise<boolean>
  handleLogOut: (url?: string) => Promise<void>
  handleRememberMe: (rememberMe: boolean) => void
  handleCurrentAccount: (param: CurrentAccountType) => void
}

export const useProvideAuth = (): UseProvideAuthType => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [premiseId, setPremiseId] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')
  const [authToken, setAuthToken] = useLocalStorage(USER_TOKEN, '')
  const [checkingUser, setCheckingUser] = useState<boolean>(false)
  const [account, setAccount] = useState<AccountResponse | null>(null)
  const [payments, setPayments] = useState<PaymentResponse | null>(null)
  const [billing, setBilling] = useState<BillingResponse | null>(null)
  const [deposits, setDeposits] = useState<DepositResponse | null>(null)
  const [meterRead, setMeterRead] = useState<MeterRead[] | null>(null)
  const [usage, setUsage] = useState<UsageResponse | null>(null)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useLocalStorage(REMEMBERME, false)
  const [currentAccount, setCurrentAccount] = useState<CurrentAccountType | null>(null)

  const handleLogin = async ({ email, password }: IAuthForm): Promise<boolean> => {
    setIsLoading(true)
    try {
      const user = await Auth.signIn(email, password)
      setAuthToken(user?.signInUserSession?.accessToken?.jwtToken)
      setUserEmail(user?.username)
      const accountData = await getAccount({
        AccessToken: user?.signInUserSession?.accessToken?.jwtToken
      })

      setIsLoading(false)
      setCheckingUser(true)
      setCurrentAccount({
        accountId: accountData?.myAccount.accountId ?? null,
        premiseId: accountData?.myAccount?.serviceAddresses[0].premiseId ?? null
      })

      /* commenting, we might need this in the future
      if (!rememberMe) {
        // Force logout after 15 mins if user didn't check remember me
        setTimeout(() => {
          void handleLogOut()
        }, 900000)
      }
      */

      return true
    } catch (error: any) {
      setIsLoading(false)
      throw (error?.message ?? error?.response?.data?.message ?? 'Login failed')
    }
  }

  const handleCurrentAccount = ({ accountId, premiseId }: CurrentAccountType): void => {
    setCurrentAccount({ accountId, premiseId })
  }


  const handleLoginFromAdmin = async (param: IUser): Promise<boolean> => {
    try {
      setIsLoading(true)
      const customerAccount = await getAccount({
        AccessToken: param.AccessToken,
        email: param.email,
        admin: param.admin
      })


      setIsLoading(false)
      if (customerAccount !== null) {
        setAuthToken(param.AccessToken)
        setCurrentAccount({
          accountId: customerAccount?.myAccount.accountId ?? null,
          premiseId: customerAccount?.myAccount?.serviceAddresses[0].premiseId ?? null
        })

        return true
      } else {
        return false
      }
    } catch (error: any) {
      setIsLoading(false)
      return false
    }
  }

  const handleLogOut = async (): Promise<void> => {
    setIsLoading(true)
    setCheckingUser(false)
    setAuthToken('')
    setAccount(null)
    setBilling(null)
    setPayments(null)
    setUsage(null)
    await Auth.signOut()
    setIsLoading(false)
  }

  const handleRememberMe = (value: boolean): void => {
    setRememberMe(value)
  }

  const getAccount = async (param: IUser): Promise<AccountResponse | null> => {
    try {
      setAccount(null)
      const result = await APIs.getAccount(param)
      setAccount(result)
      setPremiseId(result?.myAccount?.serviceAddresses[0]?.premiseId)

      if (param.email !== undefined) {
        setUserEmail(param.email)
      }

      if (param.admin !== undefined) {
        setIsAdmin(param.admin)
      }
      return result
    } catch (error: any) {
      if (
        error.name === NOT_AUTHORIZED_EXCEPTION ||
        ((Boolean(error.response)) && error.response.status === 401)
      ) {
        await handleLogOut()
      }
      return null
    }
  }

  const getBilling = async (param: IBilling): Promise<BillingResponse | null> => {
    try {
      setBilling(null)
      const billingRes = await APIs.getBilling(param)
      setBilling(billingRes)
      return billingRes
    } catch (error: any) {
      if (
        error.name === NOT_AUTHORIZED_EXCEPTION ||
        ((Boolean(error.response)) && error.response.status === 401)
      ) {
        await handleLogOut()
      }
      return null
    }
  }

  const getDeposits = async (param: IBilling): Promise<DepositResponse | null> => {
    try {
      setBilling(null)
      const deposit = await APIs.getDeposits(param)
      setDeposits(deposit)
      return deposit
    } catch (error: any) {
      if (
        error.name === NOT_AUTHORIZED_EXCEPTION ||
        ((Boolean(error.response)) && error.response.status === 401)
      ) {
        await handleLogOut()
      }
      return null
    }
  }

  const getPayments = async (param: IPayments): Promise<PaymentResponse | null> => {
    try {
      setPayments(null)
      const paymentRes = await APIs.getPayments(param)
      setPayments(paymentRes)
      return paymentRes
    } catch (error: any) {
      if (
        error.name === NOT_AUTHORIZED_EXCEPTION ||
        ((Boolean(error.response)) && error.response.status === 401)
      ) {
        await handleLogOut()
      }
      return null
    }
  }

  const getUsage = async (param: IUsageV2): Promise<UsageResponse | null> => {
    try {
      const usageRes = await APIs.getUsage(param)
      setUsage(usageRes)
      return usageRes
    } catch (error: any) {
      if (
        error.name === NOT_AUTHORIZED_EXCEPTION ||
        ((Boolean(error.response)) && error.response.status === 401)
      ) {
        await handleLogOut()
      }
      return null
    }
  }


  const getMeterRead = async (param: IMeterHistory): Promise<MeterRead[] | null> => {
    try {
      const response = await APIs.getMeterReadHistory(param)

      if (response?.meterReadHistory !== null && response.meterReadHistory?.length > 0) {
        setMeterRead(response?.meterReadHistory)
        return response?.meterReadHistory
      }

      return null
    } catch (error: any) {
      if (
        error.name === NOT_AUTHORIZED_EXCEPTION ||
        (Boolean(error.response) && error.response.status === 401)
      ) {
        await handleLogOut()
        return null
      }
      return null
    }
  }

  const updateMailAddress = async (
    param: IUpdateMailAddress
  ): Promise<any | null> => {
    const response = await APIs.updateMailAddress({
      AccessToken: param.AccessToken,
      addressLine1: param.addressLine1,
      addressLine2: param.addressLine2,
      postal: param.postal,
      city: param.city,
      state: param.state
    })
    return response
  }


  useEffect(() => {
    const refreshData = async (): Promise<void> => {
      if (authToken !== '' && account === null && !isLoading) {
        setIsLoading(true)
        const accountRef = await getAccount({
          AccessToken: authToken,
          admin: isAdmin,
          email: userEmail
        })
        setCurrentAccount({
          accountId: accountRef?.myAccount.accountId ?? null,
          premiseId: accountRef?.myAccount?.serviceAddresses[0].premiseId ?? null
        })
        setCheckingUser(true)
        setIsLoading(false)
      }
    }
    refreshData()
  }, [])


  return {
    isLoading,
    premiseId,
    userEmail,
    checkingUser,
    account,
    payments,
    billing,
    usage,
    isAdmin,
    rememberMe,
    currentAccount,
    meterRead,
    deposits,
    handleLogin,
    handleLogOut,
    getAccount,
    getPayments,
    getBilling,
    getUsage,
    updateMailAddress,
    handleLoginFromAdmin,
    handleRememberMe,
    handleCurrentAccount,
    getMeterRead,
    getDeposits
  }
}
