import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import { Avatar, Box, Dialog, List, ListItem, Stack, Typography } from '@mui/material'
import { color } from 'config/color'
import type React from 'react'

interface EmailUnrecognizedDialogProps {
    open: boolean
    close: () => void
}

export const EmailUnrecognizedDialog: React.FC<EmailUnrecognizedDialogProps> = (props: EmailUnrecognizedDialogProps) => {
    return (
        <Dialog fullWidth open={props.open} onClose={() => { props.close() }}>
            <Box p={3} alignItems="center" justifyContent="center" >
                <Stack py={2} direction="column" gap={2} alignItems="center" justifyContent="center" borderBottom={`solid 1px ${color.white}`}>
                    <Box display="flex" justifyContent="right">
                        <Avatar sx={{ bgcolor: color.grey[100], width: 50, height: 50, p: 1 }}>
                            <EmailIcon fontSize='large' sx={{ color: color.primary[600] }} />
                        </Avatar>
                        <ErrorIcon sx={{ position: 'absolute', top: 90 }} color='error'></ErrorIcon>
                    </Box>
                    <Typography fontSize={24} color={color.grey[900]} fontWeight={600} textAlign="center">Email not recognized</Typography>
                    <Typography fontSize={16} color={color.grey[900]} textAlign="center">It looks like the email you provided is either not recognized or on file. Don&apos;t worry though, there is still plenty you can do:</Typography>
                </Stack>
                <List sx={{
                    listStyleType: 'disc',
                    p: 3,
                    fontSize: 14,
                    color: color.grey[700]
                }}>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography fontSize={16}>You can call us at  <Typography fontSize={14} display="inline" color={color.primary[700]} >(297) 280 4600</Typography> so that we can update the email address on your account.</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography fontSize={16}>If you just want to pay your bill, please head back to the main page and select the “Pay Your Bill” Tab</Typography>
                    </ListItem>
                </List>
            </Box >
        </Dialog >
    )
}
