import { Box, OutlinedInput, Typography } from '@mui/material'
import { MButton } from 'components'
import { MESSAGES } from 'config/projectConstants'
import { useAuth } from 'context'
import { isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import type React from 'react'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import UpdateEmailForm from './UpdateEmailDialog'

const UpdateEmail: React.FC = () => {
    const isMobile = useMobileBreakpoints()
    const { account, isAdmin } = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const [isEmailEditing, setIsEmailEditing] = useState<boolean>(false)
    const email = account?.myAccount?.email

    const handleEditEmailButtonClick = (): void => {
        if (isAdmin) {
            enqueueSnackbar(MESSAGES.ADMIN_ACCESS_ERROR, {
                variant: 'error',
                autoHideDuration: 3000
            })
        } else {
            setIsEmailEditing(!isEmailEditing)
        }
    }

    return (
        <>
            <Box p={3} border='1px solid #D7E3E9' borderRadius="4px">
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Box>
                        <Typography color="#010B0E" fontWeight={600} fontSize={isMobile ? 18 : 24} mb={1}>
                            Email Address
                        </Typography>
                        <Typography color="#5D6365" fontSize={isMobile ? 14 : 16}>
                            Here you can update your email address.
                        </Typography>
                    </Box>
                    {!isMobile && <Box alignItems="center">
                        <MButton fullWidth size="large" rounded="true" disabled={isNil(email)} variant="outlined" texttransform="none" onClick={handleEditEmailButtonClick}>
                            Edit Email
                        </MButton>
                    </Box>}
                </Box>
                <OutlinedInput fullWidth size="small" type="text" name="email" value={account?.myAccount?.email} disabled />
                {isMobile &&
                    <Box mt={3}>
                        <MButton fullWidth size="large" rounded="true" disabled={isNil(email)} variant="outlined" texttransform="none" onClick={handleEditEmailButtonClick}>
                            Edit Email
                        </MButton>
                    </Box>}
            </Box>
            {isEmailEditing && (
                <UpdateEmailForm
                    setIsEmailEditing={setIsEmailEditing}
                    open={isEmailEditing}
                />
            )}
        </>
    )
}

export default UpdateEmail
