import { Box, OutlinedInput } from '@mui/material'
import { ErrorText, FormLabel } from 'components'
import type React from 'react'
import { Controller, type Control, type FieldErrors } from 'react-hook-form'
import InputMask from 'react-input-mask'

interface TextInputMaskProps {
  fieldName: string
  fieldLabel: string
  fieldType: string
  control: Control<any>
  errors: FieldErrors<any>
  placeholder?: string
  fullWidth?: boolean
  mask?: string
}

export const TextInputMask: React.FC<TextInputMaskProps> = (
  props: TextInputMaskProps
) => {
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      render={({ field: { onChange, value, name } }): JSX.Element => (
        <Box mb={2} width={props.fullWidth === true ? '100%' : 'auto'}>
          <FormLabel>{props.fieldLabel.toUpperCase()}</FormLabel>
          <InputMask
            name={name}
            mask="(999) 999-9999"
            value={value}
            maskChar="_"
            type="text"
            onChange={onChange}
          >
            <OutlinedInput
              fullWidth
              placeholder={
                props.placeholder ??
                `Enter your ${props.fieldLabel.toLowerCase()}`
              }
            />
          </InputMask>

          {props.errors[props.fieldName] != null && (
            <ErrorText>
              {String(props.errors[props.fieldName]?.message)}
            </ErrorText>
          )}
        </Box>
      )}
    />
  )
}
