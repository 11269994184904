import { AuthorizedLayout } from 'components'
import { ROUTES_PATH } from 'config'
import { ForgotEmail, SignupConfirm, SignupSuccess } from 'containers/Auth'
import { SignInAsCustomer } from 'containers/Auth/SignInAsCustomer'
import { Account } from 'pages/account'
import { AccountPreferences } from 'pages/accountPreferences'
import {
  ForgotPassword,
  ForgotPasswordReset,
  SignIn,
  SignUp
} from 'pages/auth'
import { Billing } from 'pages/billing'
import { Contact, ContactUs } from 'pages/contact'
import { Dashboard } from 'pages/dashboard'
import { AuthorizedFaqs, FAQs } from 'pages/faqs'

import { AuthorizedServices, Services } from 'pages/services'
import { SystemUnderMaintenance } from 'pages/systemUnderMaintenance'
import { Usage } from 'pages/usage'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom'
import { AuthorizedRoute } from './AuthorizedRoute'
import ScrollToTop from './ScrollToTop'

const isSystemUnderMaintenance =
  process.env.REACT_APP_IS_SYSTEM_UNDER_MAINTENANCE

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<ScrollToTop />} />
      {isSystemUnderMaintenance === 'true'
        ? (
          <Route>
            <Route path="/" element={<SystemUnderMaintenance />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        )
        : (
          <Route>
            <Route path={ROUTES_PATH.SIGNIN} element={<SignIn />} />
            <Route
              path={ROUTES_PATH.SIGN_IN_AS_CUSTOMER}
              element={<SignInAsCustomer />}
            />
            <Route path={ROUTES_PATH.SIGNUP} element={<SignUp />} />
            <Route
              path={ROUTES_PATH.SIGNUP_CONFIRM}
              element={<SignupConfirm />}
            />
            <Route
              path={ROUTES_PATH.SIGNUP_SUCCESS}
              element={<SignupSuccess />}
            />
            <Route
              path={ROUTES_PATH.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
            <Route
              path={ROUTES_PATH.FORGOT_PASSWORD_RESET}
              element={<ForgotPasswordReset />}
            />
            <Route path={ROUTES_PATH.FORGOT_EMAIL} element={<ForgotEmail />} />
            <Route path={ROUTES_PATH.SERVICES} element={<Services />} />
            <Route path={ROUTES_PATH.FAQS} element={<FAQs />} />
            <Route path={ROUTES_PATH.CONTACT_US} element={<ContactUs />} />
            {/* <Route path={ROUTES_PATH.OUTAGES} element={<OutagesPage />} /> */}
            <Route path={ROUTES_PATH.DASHBOARD} element={<AuthorizedLayout />}>
              <Route
                index
                element={
                  <AuthorizedRoute>
                    <Dashboard />
                  </AuthorizedRoute>
                }
              />
              <Route
                path="*"
                element={<Navigate to={ROUTES_PATH.DASHBOARD} replace />}
              />
              <Route
                path={ROUTES_PATH.ACCOUNT}
                element={
                  <AuthorizedRoute>
                    <Account />
                  </AuthorizedRoute>
                }
              />
              <Route
                path={ROUTES_PATH.USAGE}
                element={
                  <AuthorizedRoute>
                    <Usage />
                  </AuthorizedRoute>
                }
              />
              <Route
                path={ROUTES_PATH.BILLING}
                element={
                  <AuthorizedRoute>
                    <Billing />
                  </AuthorizedRoute>
                }
              />
              <Route
                path={ROUTES_PATH.AUTHORIZED_SERVICES}
                element={
                  <AuthorizedRoute>
                    <AuthorizedServices />
                  </AuthorizedRoute>
                }
              />
              {/* <Route
                path={ROUTES_PATH.RESIDENT_RESOURCES}
                element={
                  <AuthorizedRoute>
                    <ResidentResources />
                  </AuthorizedRoute>
                }
              /> */}
              <Route
                path={ROUTES_PATH.CONTACT}
                element={
                  <AuthorizedRoute>
                    <Contact />
                  </AuthorizedRoute>
                }
              />
              <Route
                path={ROUTES_PATH.SETTINGS}
                element={
                  <AuthorizedRoute>
                    <AccountPreferences />
                  </AuthorizedRoute>
                }
              />
              {/* <Route
                path={ROUTES_PATH.AUTHORIZED_OUTAGES}
                element={
                  <AuthorizedRoute>
                    <AuthorizedOutages />
                  </AuthorizedRoute>
                }
              /> */}
              <Route
                path={ROUTES_PATH.AUTHORIZEDFAQS}
                element={
                  <AuthorizedRoute>
                    <AuthorizedFaqs />
                  </AuthorizedRoute>
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <AuthorizedRoute>
                  <AuthorizedLayout />
                </AuthorizedRoute>
              }
            ></Route>
          </Route>
        )}
    </Route>
  )
)

export const AppRoutes = (): JSX.Element => {
  return (
    <RouterProvider router={router} />
  )
}
