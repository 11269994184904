import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Box, Typography, styled } from '@mui/material'
import type React from 'react'
import { useEffect, useState } from 'react'

import {
  MButton,
  NoResults,
  ProgressLoaders,
  Text
} from 'components'
import { useAuth } from 'context'
import { useLocalStorage, useMobileBreakpoints } from 'utils/hooks'

import { ItemWrapper, TableHeader, TableItem } from 'components/ui/Table'
import { USER_TOKEN } from 'config'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'
import { formatValue } from 'utils/helpers/formatValue'
import { type MeterRead } from 'utils/hooks/types'
import * as XLSX from 'xlsx'

export const BillingHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 30px',
  background: 'rgba(132, 176, 218, 0.1)'
})

export const BillItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 30px',
  borderBottom: '1px solid #eee'
})

export function MeterHistory(): JSX.Element {
  const { meterRead, getMeterRead, currentAccount, premiseId } =
    useAuth()
  const isMobile = useMobileBreakpoints()
  const [savedAuthToken] = useLocalStorage(USER_TOKEN, null)
  const [isLoading, setIsLoading] = useState<boolean>(false)


  useEffect(() => {
    const fetchMeterReadHistory = async (): Promise<void> => {
      setIsLoading(true)
      try {
        await getMeterRead({
          AccessToken: savedAuthToken ?? '',
          premiseId: currentAccount?.premiseId ?? premiseId,
          startPeriod: dayjs().subtract(2, 'year').format('YYYY-MM-DD'),
          endPeriod: dayjs().format('YYYY-MM-DD')
        })
      } finally {
        setIsLoading(false)
      }
    }

    if (meterRead == null) {
      void fetchMeterReadHistory()
    }
  }, [currentAccount, savedAuthToken])

  const exportColumnToExcel = async (): Promise<void> => {
    if (meterRead != null && meterRead.length > 0) {
      const objectDatas = meterRead.map((item) => ({ ...item }))
      const titles = Object.keys(meterRead[0])

      const ws = XLSX.utils.aoa_to_sheet([titles])
      XLSX.utils.sheet_add_json(ws, objectDatas, {
        skipHeader: true,
        origin: 'A2'
      })

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Meter Read')

      const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array'
      })

      const finalData = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      FileSaver.saveAs(finalData, 'Meter Read History.xlsx')
    }
  }


  return (
    <Box bgcolor="#FFF" p={3}>
      {isLoading
        ? (
          <ProgressLoaders />
        )
        : meterRead == null || meterRead.length === 0
          ? (
            <NoResults />
          )
          : (
            <Box display="flex" flexDirection={isMobile ? 'column-reverse' : 'column'}>
              <Box>
                <MeterHistoryMobileView meterRead={meterRead} />
                {!isMobile && (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb="20px"
                    >
                      <Text fontSize="24px" color="#010B0E" fontWeight="500">
                        Meter Read History
                      </Text>
                      <MButton
                        variant="outlined"
                        texttransform="none"
                        rounded="true"
                        startIcon={<SaveAltIcon />}
                        onClick={exportColumnToExcel}
                        disabled={isLoading}
                      >
                        Export to Excel
                      </MButton>
                    </Box>
                    <Box mt={2}>
                      <TableHeader>
                        <ItemWrapper width="15%">Meter Number</ItemWrapper>
                        <ItemWrapper width="15%">Read Date</ItemWrapper>
                        <ItemWrapper width="10%">Current Read</ItemWrapper>
                        <ItemWrapper width="10%"># of Days</ItemWrapper>
                        <ItemWrapper width="10%">Read Type</ItemWrapper>
                        <ItemWrapper width="10%">Consumption </ItemWrapper>
                        <ItemWrapper width="15%">Unit of Measure</ItemWrapper>
                        <ItemWrapper width="15%">Consumption Month</ItemWrapper>
                      </TableHeader>
                      {meterRead.map((item, index) => (
                        <TableItem key={index}>
                          <ItemWrapper width="15%" small="true">
                            {item.badgeNumber}
                          </ItemWrapper>
                          <ItemWrapper width="15%" small="true">
                            {item.readDate}
                          </ItemWrapper>
                          <ItemWrapper width="10%" small="true">
                            {formatValue(item.currentRead, true, false)}
                          </ItemWrapper>
                          <ItemWrapper width="10%" small="true">
                            {item.numOfDays}
                          </ItemWrapper>
                          <ItemWrapper width="10%" small="true">
                            {item.readType}
                          </ItemWrapper>
                          <ItemWrapper width="10%" small="true">
                            {formatValue(item.consumption, true, false)}
                          </ItemWrapper>
                          <ItemWrapper width="15%" small="true">
                            {item.unitOfMeasure}
                          </ItemWrapper>
                          <ItemWrapper width="15%" small="true">
                            {item.consumptionMonth}
                          </ItemWrapper>
                        </TableItem>
                      ))}
                    </Box>
                  </>
                )}
                {isMobile && (
                  <MButton
                    variant="outlined"
                    texttransform="none"
                    rounded="true"
                    fullWidth
                    sx={{ mt: 2 }}
                    startIcon={<SaveAltIcon />}
                    onClick={exportColumnToExcel}
                    disabled={isLoading}
                  >
                    Export to excel
                  </MButton>
                )}
              </Box>
            </Box>
          )}
    </Box>
  )
}

interface MeterHistoryMobileViewProps {
  meterRead: MeterRead[]
}

const MeterHistoryMobileView: React.FC<MeterHistoryMobileViewProps> = ({ meterRead }) => {
  const isMobile = useMobileBreakpoints()

  return (
    <Box>
      {isMobile &&
        meterRead?.map((mh: any, index: number) => {
          return (
            <Box key={index} py={2} borderBottom="solid 1px #EDEFF0">
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Meter Number
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {mh.badgeNumber}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Read Date
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {mh.readDate}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Current Read
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {formatValue(mh.currentRead, true)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  # of Days
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {mh.numOfDays}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Read Type
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {mh.readType}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Consumption
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {formatValue(mh.consumption, true)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Unit of Measure
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {mh.unitOfMeasure}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography fontSize={14} color="#5D6365">
                  Consumption Month
                </Typography>
                <Typography fontSize={14} color="#010B0E" fontWeight={700}>
                  {mh.consumptionMonth}
                </Typography>
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}
