import { color } from 'config/color'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'

import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

interface DatePickerProps {
  value: string
  onChange: any
}

export const DatePicker: React.FC<DatePickerProps> = (
  props: DatePickerProps
) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography color={color.grey[700]} fontSize={14} fontWeight={400}>
        Select a day to preview:
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUIDatePicker
          format="MM/DD/YYYY"
          defaultValue={dayjs(props.value)}
          value={dayjs(props.value)}
          onChange={props.onChange}
          orientation="portrait"
          sx={{
            svg: { color: color.primary[600] }
          }}
        />
      </LocalizationProvider>
    </Box>
  )
}
