import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, Link, Typography } from '@mui/material'
import { color } from 'config/color'
import { useNavigate } from 'react-router-dom'

interface ServiceFooterProps {
    title: string
    desc: string
    link: string
    linkTitle: string
    icon: any
}

export const ServiceFooterButton: React.FC<ServiceFooterProps> = (props: ServiceFooterProps) => {
    const navigate = useNavigate()
    return <Box flex={1} p={2} bgcolor={color.background[50]} borderRadius={1}>
        <props.icon sx={{ color: color.primary[600] }} />
        <Typography color={color.grey[900]} fontSize="18px" >
            {props.title}
        </Typography>
        <Typography color={color.grey[700]} fontSize="14px">
            {props.desc}
        </Typography>
        <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(props.link) }}>
            <Box display="flex" alignItems="center">
                <Typography color={color.primary[600]} fontSize={12} >
                    {props.linkTitle}
                </Typography>
                <ArrowRightAltIcon sx={{ color: color.primary[600] }} />
            </Box>
        </Link>
    </Box >
}
