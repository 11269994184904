import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography
} from '@mui/material'

import { color } from 'config/color'

import { Text } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { CONTACT_PHONE, FAQS_ITEMS } from 'config'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IFAQs {
  isAuthorized?: boolean
}

export const Faqs = ({ isAuthorized = true }: IFAQs): JSX.Element => {
  const [expanded, setExpanded] = useState<number | false>(false)
  const isMobile = useMobileBreakpoints()

  const handleChange =
    (panel: number) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <>
      {isMobile && isAuthorized && <QuickLinks />}
      <Box bgcolor={color.white} mt={3} p={isMobile ? 3 : 5} borderRadius='4px'>
        <Text
          color={color.grey[900]}
          fontSize='24px'
          fontWeight='500'
          align={isAuthorized ? 'left' : 'center'}
        >
          Frequently Asked Questions
        </Text>
        <Box my={2} textAlign={isMobile || isAuthorized ? 'left' : 'center'}>
          <Typography color={color.grey[700]} fontSize='16px'>
            If you have additional questions, please contact us at:
            <span style={{ color: color.primary[600] }}>
              {' ' + CONTACT_PHONE}.
            </span>
            <span
              style={{
                display: 'block'
              }}
            >
              Monday-Friday 7:30 am  to 12:00 pm | 1:00 pm to 4:30 pm
            </span>
          </Typography>
        </Box>

        {FAQS_ITEMS.map((item: any, index: number) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            disableGutters={true}
            sx={{ '&:before': { height: '0px' } }}
          >
            <AccordionSummary
              sx={{
                py: 2,
                px: 3,
                bgcolor: expanded === index ? color.grey[100] : color.grey[50],
                borderBottom: `1px solid ${color.background[300]}`
              }}
              expandIcon={
                <IconButton
                  size='small'
                  disableRipple={true}
                  sx={{ bgcolor: color.grey[200] }}
                >
                  {expanded === index
                    ? (
                      <RemoveIcon sx={{ color: color.primary[600] }} />
                    )
                    : (
                      <AddIcon sx={{ color: color.primary[600] }} />
                    )}
                </IconButton>
              }
            >
              <Text color={color.grey[900]} fontSize='16px' padding='0 40px 0 0'>
                {item.title}
              </Text>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                background: color.grey[50],
                py: 2,
                px: 3,
                borderLeft: `4px solid ${color.primary[500]}`,
                borderBottom: `1px solid ${color.background[300]}`,
                ul: {
                  paddingLeft: '0'
                },
                '.accordion-list': {
                  listStyleType: 'none',
                  padding: '0',
                  marginBottom: '21px'
                },
                '.accordion-list::before': {
                  content: "''",
                  display: 'inline-block',
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: color.grey[500],
                  marginRight: '8px'
                }
              }}
            >
              <Text color={color.grey[700]} fontSize='14px'>
                <span dangerouslySetInnerHTML={{ __html: item.content }} />
              </Text>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  )
}
