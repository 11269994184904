import type React from 'react'
import { Box, styled } from '@mui/material'
import { MButton, Text } from 'components'
import { Controller, type Control } from 'react-hook-form'
import { color } from 'config/color'

interface FileUploadButtonProps {
  name: string
  label: string
  control: Control<any>
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = (
  props: FileUploadButtonProps
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange } }): JSX.Element => (
        <Box
          flexDirection={'row'}
          display='flex'
          gap={2}
          mb={2}
          alignItems='center'
        >
          <Text color={color.grey[900]} fontSize="16px"> {props.label} </Text>
          <MButton
            variant='outlined'
            component='label'
            type='button'
            size='medium'
            rounded='true'
            sx={{ fontSize: 16 }}
            texttransform='none'
          >
            Choose File
            <VisuallyHiddenInput
              type='file'
              onChange={(e) => {
                onChange(
                  e.target.files instanceof FileList
                    ? e.target.files[0].name
                    : null
                )
              }}
            />
          </MButton>
        </Box>
      )}
    />
  )
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})
