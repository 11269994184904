/* eslint-disable react/prop-types */
import { Box } from '@mui/material'
import { Text } from 'components'
import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export interface ContactInfoItem {
  image: React.ElementType
  title: string
  description: string
}

interface IContactInformationProps {
  items: ContactInfoItem[]
}

export const ContactInformation: React.FC<IContactInformationProps> = ({ items }) => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box display='flex' flexDirection='column' gap={3} mt='20px'>
      {items.map((item: any) => (
        <Box
          key={item.title}
          flex={1}
          display='flex'
          flexDirection='row'
          p={2}
          gap={1}
          borderRadius='4px'
          alignItems='center'
          bgcolor={color.grey[50]}
        >
          <Box
            display='flex'
            borderRadius='50%'
            bgcolor={color.background[300]}
            p={2}
          >
            <item.image sx={{ color: color.primary[700], fontSize: 24 }} />
          </Box>
          <Box>
            <Text
              align={isMobile ? 'left' : 'center'}
              color={color.grey[900]}
              fontSize='16px'
              fontWeight='600'
            >
              {item.value}
            </Text>
            <Text
              color={color.grey[900]}
              fontSize='16px'
              fontWeight='500'
            >
              {item.title}
            </Text>
            <Text
              align={isMobile ? 'left' : 'center'}
              color={color.grey[700]}
              fontSize='16px'
              fontWeight='400'
            >
              <Box
                sx={{
                  a: {
                    color: color.primary[700],
                    textDecoration: 'none'
                  }
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.description
                  }}
                />
              </Box>
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
