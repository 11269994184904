import EmailIcon from '@mui/icons-material/Email'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import {
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    styled
} from '@mui/material'
import { MButton, SvgImage, Text } from 'components'
import { UnsavedChangesDialog } from 'components/ui/Dialog/UnsavedChangesDialog'
import { EMAI, MESSAGES, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { forEach, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

const NOTIFICATIONS = {
    SERVICESxEMAI: 'SERVICESxEMAI',
    BILLINGxEMAI: 'BILLINGxEMAI',
    OUTAGExEMAI: 'OUTAGExEMAI'
}

const INIT_NOTIFICATION = {
    [NOTIFICATIONS.SERVICESxEMAI]: false,
    [NOTIFICATIONS.BILLINGxEMAI]: false,
    [NOTIFICATIONS.OUTAGExEMAI]: false
}

type INotificationChecks = Record<keyof typeof NOTIFICATIONS, boolean>

interface SettingItemType {
    title: string
    icon: string
}

interface INotificationPreferences {
    setIsPrefUpdated: (arg0: boolean) => void
}

interface INotifUpdatePayload {
    object: string
    value: string
    value2: string
}

interface INotifUpdatedChecks {
    key: boolean
    index: string
}

export const NotificationPreferences = ({ setIsPrefUpdated }: INotificationPreferences): JSX.Element => {
    const { account, isAdmin, userEmail } = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const [authToken] = useLocalStorage(USER_TOKEN, '')
    const [notificationChecks, setNotificationChecks] =
        useState<INotificationChecks>(INIT_NOTIFICATION as INotificationChecks)
    const [origNotificationChecks, setOrigNotificationChecks] =
        useState<INotificationChecks>(INIT_NOTIFICATION as INotificationChecks)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isMobile = useMobileBreakpoints()

    const [showDialog, setShowDialog] = useState<boolean>(false)

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            showDialog &&
            currentLocation.pathname !== nextLocation.pathname
    )

    useEffect(() => {
        const notificationPreferences =
            account?.myAccount?.notificationPreferences != null
                ? account.myAccount.notificationPreferences
                : INIT_NOTIFICATION
        setNotificationChecks(notificationPreferences as INotificationChecks)
        setOrigNotificationChecks(notificationPreferences as INotificationChecks)
    }, [account])

    const handleNotificationsChange = (event: any): void => {
        const notifChecks = {
            ...notificationChecks,
            [event.target.name]: event.target.checked
        }

        setNotificationChecks(notifChecks)
        setShowDialog(!isEqual(origNotificationChecks, notifChecks))
    }

    const generateUpdatePayload = (
        updatedNotifChecks: INotifUpdatedChecks[]
    ): INotifUpdatePayload[] => {
        const updatedNotifChecksPayload: INotifUpdatePayload[] = []

        forEach(updatedNotifChecks, ({ index, key }) => {
            const [object, value] = index.split('x')
            updatedNotifChecksPayload.push({
                object,
                value,
                value2: key ? 'Y' : 'N'
            })
        })

        return updatedNotifChecksPayload
    }

    const hasEMAIUpdate = (
        updatedNotifChecks: INotifUpdatedChecks[]
    ): boolean => {
        let hasEMAI = true

        forEach(updatedNotifChecks, ({ index, key }) => {
            if (!index.includes(EMAI) && key) {
                hasEMAI = false
            }
        })

        return hasEMAI
    }

    const handleDiscardChanges = (): void => {
        setNotificationChecks(origNotificationChecks)
    }

    const handleSaveChanges = async (): Promise<void> => {
        const updatedNotifChecks: INotifUpdatedChecks[] = []

        forEach(notificationChecks, (key, index: string) => {
            if (notificationChecks[index as keyof INotificationChecks] !== origNotificationChecks[index as keyof INotificationChecks]) {
                updatedNotifChecks.push({ key, index })
            }
        })

        const body: any = {
            AccessToken: authToken,
            accountId: account?.myAccount.accountId,
            admin: isAdmin,
            email: userEmail
        }

        body.updates = generateUpdatePayload(updatedNotifChecks)

        try {
            setIsLoading(true)
            await APIs.updateUser(body)
            setIsPrefUpdated(true)
            enqueueSnackbar(MESSAGES.PREFERENCES_UPDATED_SUCCESS, {
                variant: 'success',
                autoHideDuration: 3000
            })

            if (!hasEMAIUpdate(updatedNotifChecks)) {
                enqueueSnackbar(MESSAGES.PREFERENCES_WARN, {
                    variant: 'warning',
                    autoHideDuration: 6000
                })
            }
            setOrigNotificationChecks(notificationChecks)
        } catch (e: any) {
            if (e.response?.status === 400) {
                enqueueSnackbar(
                    `${e.response.data.message}. ${MESSAGES.CONTACT_CUSTOMER_ERROR}`,
                    {
                        variant: 'error',
                        autoHideDuration: 5000
                    }
                )
                setNotificationChecks(origNotificationChecks)
            }
        }
        setIsLoading(false)
        setShowDialog(false)
    }

    const SettingItem: React.FC<SettingItemType> = (props: SettingItemType) => (
        <Box display='flex' justifyContent='center' alignItems='center'>
            <SvgImage name={props.icon} width={20} height={20} />
            <Text fontSize='16px' color={color.grey[900]} nowrap='true' margin='0 0 0 12px'>
                {props.title}
            </Text>
        </Box>
    )

    return (
        <Box mt={0} width="100%">
            <UnsavedChangesDialog
                open={blocker.state === 'blocked'}
                onSave={handleSaveChanges}
                onConfirm={blocker.proceed}
                onClose={blocker.reset}
                isLoading={isLoading}
            />

            <Box mb={2}>
                <Box display='flex' alignItems='center' my={2} gap={2}>
                    {isMobile
                        ? (
                            <Avatar
                                sx={{
                                    bgcolor: `${color.grey[100]}`,
                                    width: isMobile ? 50 : 80,
                                    height: isMobile ? 50 : 80
                                }}
                            >
                                <NotificationsIcon fontSize='small' sx={{ color: `${color.primary[600]}` }} />
                            </Avatar>
                        )
                        : (
                            <NotificationsIcon fontSize='large' sx={{ color: `${color.primary[600]}` }} />
                        )}
                    <Typography
                        color={color.grey[900]}
                        fontSize={isMobile ? 18 : 24}
                        fontWeight={600}
                    >
                        Don&apos;t miss any information!
                    </Typography>
                </Box>
                <Typography
                    color={color.grey[700]}
                    fontSize={isMobile ? 14 : 16}
                    textAlign='justify'
                >
                    WEB Aruba Portal offers different ways to receive your
                    notifications. Please choose your preferences below.<br /> Note that you may
                    still receive phone calls, for urgent issues, even if you do not
                    select &quot;Call me.&quot;
                </Typography>
            </Box>
            <Box>
                {isMobile
                    ? <Box display="flex" flexDirection="column" gap={3}>
                        <Box border={`solid 1px ${color.background[300]}`} borderRadius="4px">
                            <Box display="flex" bgcolor="#F7F9FB" borderRadius="4px" p={2} gap={1}>
                                <EmailIcon fontSize='medium' sx={{ marginTop: 1, color: color.primary[600] }} />
                                <Box>
                                    <Typography color={color.grey[900]} fontSize={16}>Via Email</Typography>
                                    <Typography color="#5D6365" fontSize={14}>Send me an email.</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                                    <Box display="flex" gap={1}>
                                        <ReceiptLongIcon fontSize='medium' sx={{ color: color.primary[600] }} />
                                        <Typography color={color.grey[900]} fontSize={16}>Billing</Typography>
                                    </Box>
                                    <Checkbox
                                        checked={notificationChecks.BILLINGxEMAI}
                                        onChange={handleNotificationsChange}
                                        name={NOTIFICATIONS.BILLINGxEMAI}
                                        color="primary"
                                    />
                                </Box>
                                <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                                    <Box display="flex" gap={1}>
                                        <FileCopyIcon fontSize='medium' sx={{ color: color.primary[600] }} />
                                        <Typography color={color.grey[900]} fontSize={16}>Services</Typography>
                                    </Box>
                                    <Checkbox
                                        checked={notificationChecks.SERVICESxEMAI}
                                        onChange={handleNotificationsChange}
                                        name={NOTIFICATIONS.SERVICESxEMAI}
                                        color="primary"
                                    />
                                </Box>
                                <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                                    <Box display="flex" gap={1}>
                                        <ReceiptLongIcon fontSize='medium' sx={{ color: color.primary[600] }} />
                                        <Typography color={color.grey[900]} fontSize={16}>Outages</Typography>
                                    </Box>
                                    <Checkbox
                                        checked={notificationChecks.BILLINGxEMAI}
                                        onChange={handleNotificationsChange}
                                        name={NOTIFICATIONS.BILLINGxEMAI}
                                        color="primary"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    : <Box overflow="auto">
                        <TableView>
                            <thead>
                                <tr>
                                    <th />
                                    <th className="border-column">
                                        <SettingItem title="Via Email" icon="MailFilledIcon" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <SettingItem title="Billing" icon="BillingIcon" />
                                    </td>
                                    <td className="border-column">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={notificationChecks.BILLINGxEMAI}
                                                    onChange={handleNotificationsChange}
                                                    name="BILLINGxEMAI"
                                                    color="primary"
                                                />
                                            }
                                            label="Send me an email"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SettingItem title="Services" icon="ServicesIcon2" />
                                    </td>
                                    <td className="border-column">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={notificationChecks.SERVICESxEMAI}
                                                    onChange={handleNotificationsChange}
                                                    name={NOTIFICATIONS.SERVICESxEMAI}
                                                    color="primary"
                                                />
                                            }
                                            label="Send me an email"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SettingItem title="Outages" icon="OutagesIcon" /></td>
                                    <td className="border-column">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={notificationChecks.OUTAGExEMAI}
                                                    onChange={handleNotificationsChange}
                                                    name={NOTIFICATIONS.OUTAGExEMAI}
                                                    color="primary"
                                                />
                                            }
                                            label="Send me an email"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </TableView>
                    </Box>}

                <Box
                    mt={2}
                    gap={2}
                    display='flex'
                    flexDirection={isMobile ? 'column' : 'row'}
                    justifyContent={'flex-end'}
                >
                    <MButton
                        variant='outlined'
                        type='button'
                        size='large'
                        rounded='true'
                        texttransform='none'
                        fullWidth
                        sx={{ width: isMobile ? '100%' : '200px' }}
                        disabled={
                            isEqual(origNotificationChecks, notificationChecks) || isLoading
                        }
                        onClick={handleDiscardChanges}
                    >
                        Discard Changes
                    </MButton>

                    <MButton
                        variant='contained'
                        texttransform='none'
                        rounded='true'
                        sx={{ width: isMobile ? '100%' : '200px' }}
                        size='large'
                        fullWidth
                        disabled={
                            isEqual(origNotificationChecks, notificationChecks) || isLoading
                        }
                        loading={isLoading}
                        onClick={handleSaveChanges}
                    >
                        Save Changes
                    </MButton>
                </Box>
            </Box>
        </Box>
    )
}

const TableView = styled('table')`
  border-radius: 3px;
  width: 100%;
  border: 1px solid ${color.background[300]};
  border-collapse: collapse;

  .border-column {
    border-left: 2px solid ${color.background[300]};
  }

  thead {
    background: ${color.background[50]};
    text-align: center;
  }

  tbody {
    tr {
      &:nth-of-type(even) {
        background: ${color.background[300]};
      }
    }
  }

  th,
  td {
    border: 0px;
    padding: 17px 33px;
    text-align: center;
    vertical-align: middle;

    input {
      margin-right: 10px;
    }

    svg {
      margin-right: 13px;
    }
  }
`
