import {
  Box,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { color } from 'config/color'
import type React from 'react'

interface YearDropDownProps {
  value: string
  onChange: any
  yearOptions: string[]
}

export const YearDropDown: React.FC<YearDropDownProps> = (
  props: YearDropDownProps
) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography color={color.grey[700]} fontSize={14} fontWeight={400}>
        Select the year to preview:
      </Typography>

      <Select
        value={props.value}
        onChange={props.onChange}
        displayEmpty
      >
        {props.yearOptions.map((year: string, index: number) => (
          <MenuItem key={index} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
