/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, ButtonBase, Container, Link, Typography, styled } from '@mui/material'
import Logo from 'assets/icons/ClientLogo.svg'
import BackgroundImage from 'assets/images/watermark.png'
import { MButton, NotificationBanner, Text } from 'components'
import { FOOTER_SOCIAL_LINKS, ROUTES_PATH, UNAUTH_TOPBAR_ITEMS } from 'config'
import { color } from 'config/color'
import type React from 'react'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IAuth {
  title?: string
  desc?: string
  subTitle?: string
  children: React.ReactNode
  isAuthPage?: boolean
}

export const AuthLayout = ({
  title,
  desc,
  isAuthPage = false,
  children
}: IAuth): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMobileBreakpoints()
  const [open, setOpen] = useState<boolean>(true)

  const authTitle = useMemo(() => {
    const pageData = UNAUTH_TOPBAR_ITEMS.find(
      (item) => item.href === location.pathname
    )

    return pageData?.title
  }, [location.pathname])

  const handleOpenInNewTab = (url: string): void => {
    window.open(url, '_blank')
  }

  const isFullScreen = useMemo(() => {
    if (
      location.pathname === ROUTES_PATH.SIGNUP_SUCCESS ||
      location.pathname === ROUTES_PATH.FORGOT_PASSWORD ||
      location.pathname === ROUTES_PATH.PAY ||
      location.pathname === ROUTES_PATH.FORGOT_EMAIL
    ) {
      return true
    }

    return false
  }, [location.pathname])

  return (
    <>
      {open && (
        <NotificationBanner onClose={() => { setOpen(false) }} />
      )}
      <Wrapper isFullScreen={isFullScreen} isMobile={isMobile}>
        {isAuthPage
          ? (
            <Box display="flex" flexDirection="column" mt={5} mb={2} gap={3} alignItems="center" justifyContent="center">
              <Link href="/" my={3}><img src={Logo} height={70} alt="Logo" /></Link>
              <Typography textAlign="center" color={color.grey[900]} fontSize={32}>{title}</Typography>
              <Typography textAlign="center" color={color.grey[700]} fontSize={18} width={isMobile ? '90%' : '60%'}>{desc}</Typography>
            </Box>
          )
          : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={4}
              px={3}
              sx={{ backgroundColor: isMobile ? color.white : color.background[50], borderBottom: isMobile ? `solid 1px ${color.white}` : '0px' }}
            >
              <Box display="flex" alignItems="center">
                <Link href="/">
                  <img src={Logo} height={isMobile ? 25 : 40} alt="Logo" />
                </Link>
                {!isMobile && authTitle && (
                  <Text
                    color={color.black}
                    fontSize="32px"
                    fontWeight="600"
                    margin="0 0 0 20px"
                  >
                    {authTitle}
                  </Text>
                )}
              </Box>
              <MButton
                variant="outlined"
                rounded="true"
                texttransform="none"
                onClick={() => {
                  navigate(ROUTES_PATH.SIGNIN)
                }}
              >
                Back to Login
              </MButton>
            </Box>
          )}
        <Container>
          <Content>
            {isMobile && authTitle && (
              <Text
                color={color.black}
                fontSize="24px"
                fontWeight="600"
                padding="20px 0 20px 0"
              >
                {authTitle}
              </Text>
            )}
            <Box width="100%" mb={5}>
              {children}
            </Box>
          </Content>
          <Background src={BackgroundImage} alt="Background" />
          <Footer>
            <Text color={color.grey[300]} fontSize="14px">
              © {new Date().getFullYear()}. All rights reserved.
            </Text>
            <Box display="flex" textAlign="right" gap={1}>
              {FOOTER_SOCIAL_LINKS.map((item, index) => (
                <ButtonBase key={index} onClick={() => { handleOpenInNewTab(item.link) }} sx={{ bgcolor: color.background[900], borderRadius: 0.5, p: 0.5 }}>
                  <item.icon width={32} height={32} sx={{ color: color.white }} />
                </ButtonBase>
              ))}
            </Box>
          </Footer>
        </Container>
      </Wrapper >
    </>
  )
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen' && prop !== 'isMobile'
})<{ isFullScreen?: boolean, isMobile?: boolean }>(
  ({ isFullScreen, isMobile }) => ({
    background:
      isFullScreen && !isMobile
        ? `linear-gradient(to bottom, ${color.background[50]} 50%, ${color.background[900]} 50%)`
        : `linear-gradient(to bottom, ${color.background[50]} 50vh, ${color.background[900]} 50vh)`,
    display: 'flex',
    flexDirection: 'column',
    height: isFullScreen && !isMobile ? '100vh' : '100%',
    position: 'relative'
  })
)

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  position: 'relative',
  zIndex: 1
})

const Footer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
  paddingTop: '3vh',
  paddingBottom: '3vh'
})

const Background = styled('img')({
  backgroundRepeat: 'no-repeat',
  zIndex: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '25%',
  position: 'absolute'
})
