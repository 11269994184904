import WaterDropIcon from '@mui/icons-material/WaterDrop'
import { Box, Typography } from '@mui/material'
import { ReactComponent as UsageIcon } from 'assets/icons/UsageIcon.svg'
import { Text } from 'components'
import { color } from 'config/color'
import { type IDashboardStats } from 'containers/Dashboard/Dashboard'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'


interface IProps {
  dashboardStats: IDashboardStats
}

export const PowerCompare = ({ dashboardStats }: IProps): JSX.Element => {
  const [compare, setCompare] = useState<any[]>()
  const startDate = dayjs().subtract(1, 'year').format('MMMM YYYY')
  const endDate = dayjs().format('MMMM YYYY')
  const isMobile = useMobileBreakpoints()
  useEffect(() => {
    setCompare([
      {
        id: 1,
        title: 'Monthly Average',
        image: UsageIcon,
        value: `${dashboardStats.monthlyAverage} m³`
      },
      {
        id: 2,
        title: 'Total Usage',
        image: WaterDropIcon,
        value: `${dashboardStats.totalUsage} m³`
      }
    ])
  }, [dashboardStats])

  return (
    <Box height="100%" bgcolor={color.white} borderRadius={1}>
      <Box p={3}>
        <Box mb={3}>
          <Typography color={color.grey[900]} fontSize="18px" fontWeight="600">
            Previous 12 months
          </Typography>
          <Typography color={color.grey[900]} fontSize="12px">
            {`${startDate} - ${endDate}`}
          </Typography>
        </Box>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
          {compare?.map((item: any) => (
            <Box key={item.id} display="flex" flex={1} flexDirection={isMobile ? 'row' : 'column'} px={2} gap={1} border={`1px solid ${color.background[300]}`}
              borderRadius={1} alignItems="center" py={isMobile ? 2 : 4}>
              <Box display="flex" alignItems="center" justifyContent="center" borderRadius="50%" bgcolor={color.background[50]} p={2}>
                {item.id === 1 ? <item.image fill={color.primary[700]} height={40} width={40} /> : <item.image sx={{ color: color.primary[700], fontSize: 40 }} />}
              </Box>
              <Box>
                <Text
                  align={isMobile ? 'left' : 'center'}
                  color={color.black}
                  fontSize="18px"
                  fontWeight="600"
                >
                  {item.value}
                </Text>
                <Text align={isMobile ? 'left' : 'center'} color={color.grey[700]} fontSize="14px">
                  {item.title}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
