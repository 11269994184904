import Download from '@mui/icons-material/Download'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Box, Typography, styled } from '@mui/material'
import { MButton, ProgressLoaders, Text } from 'components'
import { MESSAGES, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { formatValue, useLocalStorage } from 'utils'
import { exportExcel } from 'utils/helpers/exportExcel'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { NoData } from './NoData'
import { BillItem, BillingHeader, StyledScrollbar } from './PaymentHistory'

export const BillingHistory = (): JSX.Element => {
  const { billing } = useAuth()
  const isMobile = useMobileBreakpoints()
  const { account, currentAccount, getBilling, isAdmin, userEmail } =
    useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === billing?.accountId) {
      return
    }
    void getBilling({
      AccessToken: authToken,
      accountId: currentAccount?.accountId ?? account?.myAccount.accountId,
      startDate: dayjs().subtract(2, 'year').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      admin: isAdmin,
      email: userEmail
    })
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, userEmail, isAdmin, authToken])

  const handleExportExcel = (): void => {
    const excelData = [
      ['Date', 'Amount'],
      ...(billing?.billing.slice().reverse().map((bill) => [bill.billDate, bill.billAmount]) ?? [])
    ]
    exportExcel(excelData, 'Billing History', 'billing_history_data.xlsx')
  }


  const handleBillClick = (_event: any, billUrl: string): void => {
    if (!isNil(billUrl) || billUrl === '') {
      window.open(billUrl, '_blank', 'noopener,noreferrer')
    } else {
      enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  return (
    <Wrapper>
      {(billing?.billing !== null) && billing?.billing.length === 0 && (
        <NoData transactionType="billing" />
      )}
      {billing !== null
        ? billing?.billing.length !== 0 && <>
          {isMobile
            ? billing?.billing.slice().reverse().map((bill, index) => {
              return <BillingEntryMobile key={index} date={bill.billDate} amount={bill.billAmount} url={bill.billDisplayURL} />
            })
            : <Box >
              <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <Typography color={color.grey[900]} fontSize={isMobile ? 18 : 24} fontWeight={600}>
                  Billing History
                </Typography>
                <MButton
                  variant="outlined"
                  texttransform="none"
                  rounded="true"
                  size="large"
                  startIcon={<Download />}
                  onClick={handleExportExcel}
                >
                  Export to Excel
                </MButton>
              </Box>
              <BillingHeader>
                <ItemWrapper>Bill Date</ItemWrapper>
                <ItemWrapper>Bill Amount</ItemWrapper>
                <ItemWrapper>Bill Print File</ItemWrapper>
              </BillingHeader>
              <StyledScrollbar maxHeight="350px" overflow="auto">
                {billing?.billing.slice().reverse().map((bill, index) => {
                  return (
                    <BillItem key={index}>
                      <ItemWrapper small="true">{dayjs(bill.billDate).format('MM-DD-YYYY')}</ItemWrapper>
                      <ItemWrapper small="true">{formatValue(bill.billAmount)}</ItemWrapper>
                      <ItemWrapper>
                        <Box display="flex" alignItems="center">
                          <MButton texttransform='none' onClick={(e) => { handleBillClick(e, bill.billDisplayURL) }}>
                            <Text fontSize="14px" color={color.grey[900]} margin="0 10px 0 0">
                              View Bill
                            </Text>
                            <PictureAsPdfIcon />
                          </MButton>
                        </Box>
                      </ItemWrapper>
                    </BillItem>
                  )
                }
                )}
              </StyledScrollbar>
              <Typography mt={5} fontSize='18px'>Your billing and payment information will be available starting in November 2024. For previous billing information, please contact our Call Center at 280-4600 or reach out via WEB ARUBA CHAT on WhatsApp at 525-4600.</Typography>
            </Box>}
        </>
        : <ProgressLoaders height="100%" />
      }
      {
        isMobile && <MButton
          sx={{ width: '100%' }}
          size="large"
          variant="outlined"
          rounded="true"
          texttransform="none"
          onClick={handleExportExcel}
        >
          Export to Excel
        </MButton>
      }
    </Wrapper >
  )
}

export const Wrapper = styled(Box)({
  background: color.white,
  padding: '24px 20px',
  marginBottom: '10px'
})

export const ItemWrapper = styled(Box)<{ small?: string }>(({ small }) => ({
  width: '25%',
  color: color.black,
  fontSize: small === 'true' ? '14px' : '16px',
  fontWeight: small === 'true' ? '400' : 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: 5
}))

interface BillingEntryMobileProps {
  date: string
  amount: string
  url: string
}

const BillingEntryMobile: React.FC<BillingEntryMobileProps> = (props: BillingEntryMobileProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const handleBillClick = (_event: any, billUrl: string): void => {
    if (!isNil(billUrl) || billUrl === '') {
      window.open(billUrl, '_blank', 'noopener,noreferrer')
    } else {
      enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }


  return <Box py={2} borderBottom={`solid 1px ${color.background[50]}`}>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color={color.grey[700]}>Bill Date</Typography>
      <Typography fontSize={14} color={color.grey[900]} fontWeight={700}>{dayjs(props.date).format('MM-DD-YYYY')}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color={color.grey[700]}>Payment Amount</Typography>
      <Typography fontSize={14} color={color.grey[900]} fontWeight={700}>{formatValue(props.amount)}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color={color.grey[700]}>Bill Print File</Typography>
      <Typography fontSize={14} color={color.grey[900]} fontWeight={700}><MButton onClick={(e) => { handleBillClick(e, props.url) }} padding="0px" texttransform='none'><u>View Bill</u></MButton></Typography>
    </Box>
  </Box>
}
