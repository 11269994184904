import HelpIcon from '@mui/icons-material/Help'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Link, OutlinedInput } from '@mui/material'
import { ErrorText, MButton, Text } from 'components'
import { MESSAGES, ROUTES_PATH } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { APIs } from 'services'
import * as yup from 'yup'
import { AccountNumberHelpDialog } from 'components/ui/Dialog/AccountNumberHelpDialog'

const schema = yup
  .object({
    account: yup.string().required('Account number is required')
  })
  .required()

interface IForgotEmailForm {
  account: string
}

interface IUserAccount {
  email: string
  accountId: string
}

export function Form(): JSX.Element {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [userAccount, setUserAccount] = useState<IUserAccount>({
    email: '',
    accountId: ''
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IForgotEmailForm>({
    mode: 'all',
    defaultValues: {
      account: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: any): Promise<void> => {
    setIsLoading(true)
    const { account } = data
    const body: any = {
      accountId: account
    }
    try {
      const response = await APIs.getEmailAddress(body)
      const { message, status } = response
      if (status === 1 && message?.email) {
        setUserAccount({
          email: message?.email,
          accountId: account
        })
        setIsSubmitted(true)
      }
      if (status === 0 && response?.message?.message) {
        enqueueSnackbar(message.message || MESSAGES.WRONG_ERROR, {
          variant: 'error',
          autoHideDuration: 5000
        })
      }
    } catch (e: any) {
      const { message } = e
      enqueueSnackbar(message || MESSAGES.WRONG_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    } finally {
      setIsLoading(true)
    }
  }

  const handleLogin = (): void => {
    navigate(ROUTES_PATH.SIGNIN)
  }

  const handleBack = (): void => {
    setIsSubmitted(false)
  }

  if (isSubmitted) {
    return (
      <Box>
        <Text color="#5D6365" fontSize="16px">
          The email address associated to the account number{' '}
          {userAccount?.accountId} is:
        </Text>
        <Box
          display="flex"
          justifyContent="center"
          py={1.25}
          mt={1.5}
          sx={{
            background: '#F6F6F6',
            borderRadius: '2px'
          }}
        >
          <Text color="#010B0E" fontSize="16px">
            {userAccount?.email}
          </Text>
        </Box>
        <Box mt={3} textAlign="center">
          <MButton
            variant="contained"
            size="large"
            rounded="true"
            disabled={!isValid}
            texttransform="none"
            sx={{ width: '200px' }}
            onClick={handleLogin}
          >
            Back to Login
          </MButton>
        </Box>

        <Box mt={1}>
          <Link
            onClick={handleBack}
            underline="none"
            sx={{ '&:hover': { cursor: 'pointer' } }}
          >
            <Text color="#007395" fontSize="16px" align="center">
              Go Back
            </Text>
          </Link>
        </Box>
      </Box>
    )
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <Text color="#5D6365" fontSize="16px">
        Please enter your account number and we will retrieve the associated
        email address.
      </Text>
      <Box my={2}>
        <Box display="flex" alignItems="center">
          <Box display="flex" gap="5px">
            <FormLabel sx={{ textTransform: 'uppercase' }}>
              Account number
            </FormLabel>
            <Link
              component="button"
              onClick={() => {
                setOpen(true)
              }}
            >
              <HelpIcon sx={{ fontSize: 18, paddingBottom: '2px' }} />
            </Link>
          </Box>


        </Box>

        <Controller
          name="account"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <OutlinedInput
              name={name}
              onChange={onChange}
              value={value}
              type="text"
              fullWidth
            />
          )}
        />
        {errors.account && <ErrorText>{errors.account.message}</ErrorText>}
        <AccountNumberHelpDialog
          open={open}
          onClose={() => { setOpen(false) }}
        />
      </Box>

      <Box my={3} textAlign="center">
        <MButton
          variant="contained"
          type="submit"
          size="large"
          rounded="true"
          disabled={!isValid}
          loading={isLoading}
          texttransform="none"
          sx={{ width: '200px' }}
        >
          Submit
        </MButton>
      </Box>

      <Box mt={1}>
        <Link href={ROUTES_PATH.SIGNIN} underline="none">
          <Text color="#007395" fontSize="16px" align="center">
            Back to Login
          </Text>
        </Link>
      </Box>
    </form>
  )
}
