import CloseIcon from '@mui/icons-material/Close'
import AccountNumberHelp1 from 'assets/images/account_number_help_1.png'
import AccountNumberHelp2 from 'assets/images/account_number_help_2.png'
import Box from '@mui/material/Box'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { Avatar, Dialog, IconButton, Typography } from '@mui/material'
import { color } from 'config/color'


export const AccountNumberHelpDialog = ({
    open,
    isLoading,
    onClose
}: any): JSX.Element => {
    const isMobile = useMobileBreakpoints()

    const handleClose = (_event: any, reason: string): void => {
        if (reason === 'backdropClick') return
        onClose()
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
            >
                <Box p={5}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton onClick={onClose} disabled={isLoading}>
                            <CloseIcon color="primary" />
                        </IconButton>
                    </Box>
                    <Typography mb={5} fontSize={24} fontWeight={600} color={color.grey[900]} textAlign='center'>
                        Where can I find my account number?
                    </Typography>
                    <Box display='flex' gap={5} flexDirection={isMobile ? 'column' : 'row'}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                backgroundColor: color.background[50],
                                padding: isMobile ? '20px 0px' : '30px 20px',
                                border: `1px solid ${color.background[300]}`,
                                borderRadius: '3px',
                                width: isMobile ? '100%' : '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar sx={{ bgcolor: color.background[200], width: 34, height: 34, p: 1, justifyContent: 'center' }}>
                                <Typography color={color.primary[600]} fontSize={20} fontWeight={700}>1</Typography>
                            </Avatar>
                            <Typography sx={{ margin: '10px 0 42px 0', color: color.grey[700] }}>
                                Get one of your previous bills
                            </Typography>
                            <img
                                src={AccountNumberHelp1}
                                alt="Account Number Help"
                                width={isMobile ? '90%' : '100%'}
                            />
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                backgroundColor: color.background[50],
                                padding: isMobile ? '20px 0px' : '30px 20px',
                                border: `1px solid ${color.background[300]}`,
                                borderRadius: '3px',
                                width: isMobile ? '100%' : '50%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar sx={{ bgcolor: color.background[200], width: 34, height: 34, p: 1, justifyContent: 'center' }}>
                                <Typography color={color.primary[600]} fontSize={20} fontWeight={700}>2</Typography>
                            </Avatar>
                            <Typography
                                style={{ margin: '10px 0px 0px 0px', color: color.grey[700] }}
                                paragraph
                            >
                                {'Check the center of the bill, under'}
                            </Typography>
                            <Typography style={{ marginBottom: '20px', color: color.grey[700] }}>
                                “Account Number”
                            </Typography>
                            <img
                                src={AccountNumberHelp2}
                                alt="Account Number Help"
                                width={isMobile ? '90%' : '100%'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </Box >
    )
}
