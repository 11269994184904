import {
  MenuItem,
  Select,
  type SelectChangeEvent,
  Tab,
  Tabs as MuiTabs,
  Typography,
  styled,
  Box
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { color } from 'config/color'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { tabSx } from 'containers/Billing'

export interface ITabOption {
  title: string
  content: JSX.Element
}

export interface ITabs {
  tabOptions: ITabOption[]
  activeTab: number
  handleTabChange: (_e: any, newActiveTab: number) => void
  handleSelectChange: (event: SelectChangeEvent) => void
}

export const Tabs = (props: ITabs): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const isMobile = useMobileBreakpoints()

  return (
    <Box mt={3}>
      {isMobile
      ? (
        <Select
          variant="standard"
          fullWidth
          value={String(props.activeTab)}
          onOpen={() => {
            setIsOpen(true)
          }}
          onClose={() => {
            setIsOpen(false)
          }}
          onChange={props.handleSelectChange}
          sx={{
            bgcolor: color.white,
            borderBottom: `solid 1px ${color.background[50]}`,
            p: 2
          }}
          disableUnderline={true}
          IconComponent={() =>
            isOpen
          ? (
              <ExpandLess sx={{ color: color.primary[500] }} />
            )
          : (
              <ExpandMore sx={{ color: color.primary[500] }} />
            )
          }
        >
          {props.tabOptions.map((tab, index) => {
            return (
              <MenuItem key={index} value={index}>
                <Typography
                  fontSize={24}
                  fontWeight={600}
                  color={color.background[900]}
                  alignItems="center"
                >
                  {tab.title}
                </Typography>
              </MenuItem>
            )
          })}
        </Select>
      )
      : (
        <StyledMuiTabs
          variant="fullWidth"
          value={props.activeTab}
          onChange={props.handleTabChange}
          TabIndicatorProps={{
            sx: { background: color.primary[500], height: 5 }
          }}
        >
          {props.tabOptions.map((tab, index) => {
            return (
              <Tab
                key={index}
                label={tab.title}
                sx={{
                  ...tabSx,
                  borderLeft:
                    index === 0 ? 0 : `solid 1px ${color.background[50]}`
                }}
              />
            )
          })}
        </StyledMuiTabs>
      )}
      {props.tabOptions[props.activeTab].content}
    </Box>
  )
}

const StyledMuiTabs = styled(MuiTabs)({
  '& .MuiTab-root': {
    background: color.white,
    borderBottom: `solid 1px ${color.background[200]}`,
    borderLeft: `solid 1px ${color.background[200]}`,
    color: color.primary[700]
  },
  '& .MuiTab-root.Mui-selected': {
    backgroundColor: color.background[900],
    color: color.white
  }
})
