export const color = {
    primary: {
        50: '#ECF3F6',
        100: '#E4F1F6',
        200: '#D7EDF6',
        300: '#C2E7F5',
        400: '#9EDDF4',
        500: '#63CBF2',
        600: '#00AEEF',
        700: '#027FAE',
        800: '#00597A',
        900: '#003549'
    },
    secondary: {
        50: '#E4F4F6',
        100: '#D7F2F6',
        200: '#C2EFF5',
        300: '#9EEAF4',
        400: '#63E1F2',
        500: '#00D2EF',
        600: '#00BBD4',
        700: '#029FB5',
        800: '#028294',
        900: '#026573'
    },
    background: {
        50: '#F4F5F5',
        100: '#EFF2F2',
        200: '#EAEEEF',
        300: '#E1E8EA',
        400: '#D2DEE2',
        500: '#B9CDD5',
        600: '#8FB1BE',
        700: '#4A8398',
        800: '#005270',
        900: '#003A4F'
    },
    grey: {
        50: '#FBFBFB',
        100: '#F7F7F7',
        200: '#EEEEEE',
        300: '#E3E3E3',
        400: '#D0D1D1',
        500: '#B0B3B3',
        600: '#8E9293',
        700: '#5D6365',
        800: '#343C3E',
        900: '#010B0E'
    },
    success: {
        50: '#EFFCEE',
        100: '#E5FAE3',
        200: '#D3F6D0',
        300: '#B5F0B0',
        400: '#84E67C',
        500: '#50DB44',
        600: '#30BD23',
        700: '#1B9C10',
        800: '#0D7604',
        900: '#0A6402'
    },
    error: {
        50: '#FFF0EF',
        100: '#FFD9D8',
        200: '#FFCCCA',
        300: '#FDA7A3',
        400: '#F37772',
        500: '#DE4C45',
        600: '#C2241D',
        700: '#A50B04',
        800: '#8B0A04',
        900: '#5E0501'
    },
    warning: {
        50: '#F8EFE8',
        100: '#F8E9DE',
        200: '#F8E0CE',
        300: '#F8D1B3',
        400: '#F9B786',
        500: '#FB8C3C',
        600: '#DF6F1E',
        700: '#C2590C',
        800: '#A94903',
        900: '#8B3A00'
    },
    water: {
        50: '#E7F3FF',
        100: '#BDDEFF',
        200: '#92C8FF',
        300: '#67B3FF',
        400: '#3D9EFF',
        500: '#1286FA',
        600: '#0069D1',
        700: '#0054A8',
        800: '#004080',
        900: '#002B57'
    },
    electricity: {
        50: '#FFF9E9',
        100: '#FFECB9',
        200: '#FFDE89',
        300: '#FFD158',
        400: '#FFC428',
        500: '#DDA616',
        600: '#BB8A08',
        700: '#996F00',
        800: '#775600',
        900: '#553E00'
    },
    gas: {
        50: '#F4E7FF',
        100: '#E2BDFF',
        200: '#CF92FF',
        300: '#BC67FF',
        400: '#AA3DFF',
        500: '#9412FA',
        600: '#7500D1',
        700: '#5E00A8',
        800: '#480080',
        900: '#310057'
    },
    gradient: {
        primary: ['#203D35', '#71837D'],
        white: ['#EEF0F0', '#F5F5F5']
    },
    black: '#000000',
    white: '#FFFFFF'
}
