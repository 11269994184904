import { AuthLayout } from 'components'
import { AuthWrapper } from 'containers/Auth'
import { Services as ServicesContainer } from 'containers/Services'

export const Services: React.FC = () => {
  return (
    <AuthLayout>
      <AuthWrapper>
        <ServicesContainer />
      </AuthWrapper>
    </AuthLayout>
  )
}
