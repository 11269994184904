import { Link } from '@mui/material'
import TwitterIcon from 'assets/icons/Social/TW.svg'
import { Text } from 'components'
import { color } from 'config/color'
import propTypes from 'prop-types'
import type React from 'react'

interface Social {
    icon: React.ElementType | null
    link: string
    title: string
}

interface BillingQuestionsLinkProps {
    social: Social
    index: number
}

const BillingQuestionsLink = ({ social, index }: BillingQuestionsLinkProps): JSX.Element => {
    return (
        <Link
            key={index}
            display='flex'
            mb={2}
            alignItems='center'
            sx={{
                textDecoration: 'none',
                cursor: 'pointer'
            }}
            onClick={() => {
                window.open(social.link)
            }}
            target="_blank"
            rel="noopener noreferrer"
        >
            {social.icon === null
                ? <img
                    src={TwitterIcon}
                    alt="Twitter"
                    style={{
                        width: '18px',
                        height: '18px',
                        marginLeft: '3px'
                    }}
                />
                : <social.icon sx={{ color: color.primary[600] }} />}
            <Text
                fontSize="14px"
                block="false"
                color={color.grey[900]}
                margin="0 0 0 10px"
            >
                {social.title}
            </Text>
        </Link>
    )
}

BillingQuestionsLink.propTypes = {
    social: propTypes.shape({
        icon: propTypes.elementType,
        link: propTypes.string.isRequired,
        title: propTypes.string.isRequired
    }).isRequired
}

export default BillingQuestionsLink
