import { type SelectChangeEvent } from '@mui/material'
import { Box } from '@mui/system'
import { type ITabOption, Tabs } from 'components'
import { color } from 'config/color'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { MeterHistory } from './MeterHistory'
import { UsageViewGraph } from './UsageViewGraph'

const DEFAULT_FILTERS = {
  activeTab: 0,
  monthly: dayjs().year(),
  daily: dayjs().format('YYYY-MM'),
  hourly: dayjs().format('YYYY-MM-DD')
}


const getUsageViewTabOptions = (
  filters: any,
  setFilters: any
): ITabOption[] => [
    {
      title: 'Monthly View',
      content: (
        <UsageViewGraph
          filters={filters}
          setFilters={setFilters}
          viewType="monthly"
        />
      )
    },
    {
      title: 'Meter Read History',
      content: (
        <MeterHistory />

      )
    }
  ]

export const UsageView = (): JSX.Element => {
  const [filters, setFilters] =
    useState<Record<string, string | number>>(DEFAULT_FILTERS)

  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabChange = (_e: any, newActiveTab: number): void => {
    setFilters({
      ...filters,
      activeTab: newActiveTab
    })
    setActiveTab(newActiveTab)
  }
  const handleSelectChange = (event: SelectChangeEvent): void => {
    setFilters({
      ...filters,
      activeTab: Number(event.target.value)
    })
    setActiveTab(Number(event.target.value))
  }

  useEffect(() => {
    setActiveTab(Number(filters.activeTab))
  }, [filters])

  return (
    <Box bgcolor={color.white} mt={3}>
      <Tabs
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        handleSelectChange={handleSelectChange}
        tabOptions={getUsageViewTabOptions(filters, setFilters)}
      />
    </Box>
  )
}
