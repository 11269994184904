import type React from 'react'
import { Box, TextareaAutosize, styled } from '@mui/material'
import { FormLabel } from 'components'
import { Controller, type Control } from 'react-hook-form'
import { color } from 'config/color'

interface TextAreaProps {
    fieldName: string
    fieldLabel: string
    control: Control<any>
    placeholder?: string
}

export const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
    return <Controller
        name={props.fieldName}
        control={props.control}
        render={({ field: { onChange, value, name } }): JSX.Element => (
            <Box mb={2}>
              <FormLabel>{props.fieldLabel}</FormLabel>
              <Textarea
                placeholder={props.placeholder}
                onChange={onChange}
                value={value}
                name={name}
              />
              <span
                style={{
                  color: color.grey[700],
                  fontSize: '12px',
                  fontWeight: '400'
                }}
              >
                (*) Indicates a required field
              </span>
            </Box>

        )}
    />
}

export const Textarea = styled(TextareaAutosize)({
  width: '100%',
  height: '188px !important',
  resize: 'none',
  font: 'inherit',
  border: `1px solid ${color.background[300]} !important`,
  ':focus': {
    border: `1px solid ${color.primary[700]} !important`
  },
  ':hover:not(:focus)': {
    border: `1px solid ${color.black} !important`
  },
  ':focus-visible': {
    outline: `1px solid ${color.white} !important`
  },
  '::placeholder': {
    color: color.grey[400]
  }
})
