import DownloadIcon from '@mui/icons-material/Download'
import {
  Box, Typography
} from '@mui/material'
import { ReactComponent as UsageIcon } from 'assets/icons/UsageIcon.svg'
import { MButton, ProgressLoaders } from 'components'
import { MESSAGES, RATES_LINK, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export const UtilityBill: React.FC = () => {
  const { account, currentAccount, getAccount, isAdmin, userEmail } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [unbilledCharges, setUnbilledCharges] = useState<boolean>(false)

  const handleDownloadBill = (): void => {
    if (!isNil(account?.myAccount?.billDisplayURL)) {
      window.open(account?.myAccount?.billDisplayURL, '_blank', 'noopener,noreferrer')
    } else {
      enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  useEffect(() => {
    if (((account?.myAccount?.unbilledCharges?.payments) != null && (account?.myAccount?.unbilledCharges?.payments) !== '0') || ((account?.myAccount?.unbilledCharges?.adjustments) != null && (account?.myAccount?.unbilledCharges?.adjustments) !== '0') || ((account?.myAccount?.unbilledCharges?.lateFees) != null && (account?.myAccount?.unbilledCharges?.lateFees) !== '0')) {
      setUnbilledCharges(true)
    }
  }, [account])

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  const handleViewRates = (): void => {
    window.open(RATES_LINK, '_blank')
  }

  return (
    <Box bgcolor={color.white} pb={5}>
      {account !== null
        ? <>
          <Box display="flex" justifyContent="space-between" alignItems="center" p={3} borderBottom={`solid 1px ${color.white}`}>
            <Box my={2}>
              <Typography color={color.grey[900]} fontSize={isMobile ? 18 : 24} fontWeight={600}>
                Account Billing Summary
              </Typography>
              <Typography display="inline" color={color.grey[700]} fontSize={12}>
                Account Number:
              </Typography>
              <Typography display="inline" fontSize={12} color={color.grey[900]} fontWeight={600} ml={0.5}>{account?.myAccount?.accountId}</Typography>
              <Box>
                <Typography display="inline" color={color.grey[700]} fontSize={12}>
                  Residential Service Bill Period:
                </Typography>
                <Typography display="inline" fontSize={12} color={color.grey[900]} fontWeight={600} ml={0.5}>{`${dayjs(account?.myAccount?.billStartDt).format('M/DD/YYYY')} to ${dayjs(account?.myAccount?.billEndDt).format('M/DD/YYYY')}`}</Typography>
              </Box>
            </Box>
            {!isMobile && <Box display="flex" alignItems="center" gap={2}>
              <MButton
                variant="outlined"
                texttransform="none"
                rounded="true"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadBill}
              >
                View PDF File
              </MButton>
              <MButton
                variant="outlined"
                texttransform="none"
                rounded="true"
                size="large"
                startIcon={<UsageIcon fill={color.primary[700]} />}
                onClick={handleViewRates}
              >
                View Rates
              </MButton>
            </Box>}
          </Box>
          <Box>
            <Box mx={isMobile ? 2 : 3}>
              {((account?.myAccount?.latestBillAmount) != null) && <BillingEntry title='Total Bill this Period' amount={account?.myAccount?.latestBillAmount} />}
              {((account?.myAccount?.previousBalance) != null) && <BillingEntry title='Previous Balance' amount={account?.myAccount?.previousBalance} />}
              {((account?.myAccount?.latestPayment) != null) && <BillingEntry title='Payments Received' amount={account?.myAccount?.latestPayment} />}
              {((account?.myAccount?.endingBalOnBill) != null) && <BillingEntry title='Balance Due' amount={account?.myAccount?.endingBalOnBill} />}
              {((account?.myAccount?.lateFees) != null && (account?.myAccount?.lateFees) !== '0') && <BillingEntry title='Late Payment/Penalty Fees' amount={account?.myAccount?.lateFees} />}
              {((account?.myAccount?.adjustments) != null && (account?.myAccount?.adjustments) !== '0') && <BillingEntry title='Adjustments' amount={account?.myAccount?.adjustments} />}

              {unbilledCharges && <Typography fontSize={14} fontWeight={700} color={color.grey[900]} my={2}>Recent Activity</Typography>}
              {((account?.myAccount?.unbilledCharges?.payments) != null && (account?.myAccount?.unbilledCharges?.payments) !== '0') && <BillingEntry title='Payments' amount={account?.myAccount?.unbilledCharges?.payments} />}
              {((account?.myAccount?.unbilledCharges?.adjustments) != null && (account?.myAccount?.unbilledCharges?.adjustments) !== '0') && <BillingEntry title='Adjustments' amount={account?.myAccount?.unbilledCharges?.adjustments} />}
              {((account?.myAccount?.unbilledCharges?.lateFees) != null && (account?.myAccount?.unbilledCharges?.lateFees) !== '0') && <BillingEntry title='Late Fees' amount={account?.myAccount?.unbilledCharges?.lateFees} />}
            </Box>

          </Box>
          <Box display="flex" justifyContent="space-between" mx={2} px={1} py={2} bgcolor={color.background[50]}>
            <Typography fontSize={16} fontWeight={700} color={color.grey[900]}>Total Current Charges</Typography>
            <Typography fontSize={16} fontWeight={700} color={color.grey[900]}>{((account?.myAccount?.accountBalance) != null) && formatValue(account?.myAccount?.accountBalance)}</Typography>
          </Box>
        </>
        : <Box pt={5}> <ProgressLoaders height='100%' /> </Box>}
    </Box >
  )
}

const BillingEntry: React.FC<BillingEntryProps> = (props: BillingEntryProps) => {
  return <Box display="flex" justifyContent="space-between" borderBottom={`solid 1px ${color.white}`} mb={1}>
    <Typography fontSize={14} color={color.grey[900]}>{props.title}</Typography>
    <Typography fontSize={14} color={color.grey[900]}>{formatValue(props.amount)}</Typography>
  </Box >
}

interface BillingEntryProps {
  title: string
  amount: string
}
