import { Box, Typography } from '@mui/material'
import { color } from 'config/color'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { defaultOptions, screenType, useWindowSize } from 'utils'
import { type UsageData, type UsageMonthlyData } from 'utils/hooks/types'

interface IChartData {
  date: string[]
  value: number[]
}

interface IProps {
  dashboardUsage: UsageData | null
}

export const PowerUsage = ({ dashboardUsage }: IProps): JSX.Element => {
  const { width } = useWindowSize()
  const [usageData, setUsageData] = useState<IChartData>({
    date: [],
    value: []
  })

  useEffect(() => {
    if (((dashboardUsage?.usageHistory) != null) && dashboardUsage?.usageHistory.length > 0 && (dashboardUsage?.usageHistory[0].usageData) != null) {
      const usageData = dashboardUsage.usageHistory[0].usageData
      const date: string[] = usageData.map((item: UsageMonthlyData) =>
        dayjs(item.period).format('MMM')
      )
      const value: number[] = usageData.map((item: UsageMonthlyData) =>
        parseFloat((+item.value).toFixed(2))
      )
      setUsageData({ date, value })
    } else {
      setUsageData({ date: [], value: [] })
    }
  }, [dashboardUsage])

  const options: any = {
    ...defaultOptions,
    chart: {
      animations: {
        enabled: false
      }
    },
    series: [
      {
        name: 'Usage m³',
        data: usageData.value
      }
    ],
    xaxis: {
      categories: usageData.date
    }
  }

  return (
    <Box bgcolor="white" borderRadius={1}>
      <Box padding={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <Box>
              <Typography color={color.grey[900]} fontWeight="600" fontSize={screenType(width, 'sm') ? '15px' : '18px'}>
                Usage History
              </Typography>
              <Typography color={color.grey[700]} fontSize="12px">
                Cubic Meter
              </Typography>
            </Box>
            <Typography color={color.grey[700]} fontSize="12px">
              {`${dayjs().subtract(1, 'year').format('MMMM YYYY')} - ${dayjs().format('MMMM YYYY')}`}
            </Typography>
          </Box>
        </Box>
        <Chart
          options={options}
          series={options.series}
          type="bar"
          height="100%"
        />
      </Box>
    </Box>
  )
}
