import { Box, IconButton } from '@mui/material'
import { PersonAdd } from '@mui/icons-material'
import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils'
import { MButton } from 'components'

export const AddUserBox = ({ setIsNewUserDialogOpen }: { setIsNewUserDialogOpen: (open: boolean) => void }): JSX.Element => {
  const isMobile = useMobileBreakpoints()

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={color.grey[50]}
      border={`1px dashed ${color.background[300]}`}
      height={isMobile ? 163 : 163}
      width={isMobile ? '100%' : '50%'}
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Box>
        <Box display="flex" flexDirection="column" gap={1} alignItems="center">
          <IconButton
            sx={{
              bgcolor: color.background[200]
            }}
          >
            <PersonAdd
              sx={{ color: color.background[700], fontSize: '32px' }}
            />
          </IconButton>

          <MButton
            variant="outlined"
            type="button"
            rounded="true"
            texttransform="none"
            sx={{ width: isMobile ? 280 : 120, mt: 1 }}
            onClick={() => { setIsNewUserDialogOpen(true) }}
          >
            Add User
          </MButton>
        </Box>
      </Box>
    </Box>
  )
}
