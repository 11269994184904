import { styled } from '@mui/material'
import { color } from 'config/color'

export const ErrorText = styled('p')`
  color: ${color.error[700]};
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-style: normal;
  letter-spacing: 0.05em;
  text-transform: none;
  padding-top: 10px;

  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
`
