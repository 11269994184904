import AccountIcon from '@mui/icons-material/AccountCircle'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import Language from '@mui/icons-material/Language'
import MailIcon from '@mui/icons-material/Mail'
import PaymentsIcon from '@mui/icons-material/Payments'
import PinDrop from '@mui/icons-material/PinDrop'
import QuizIcon from '@mui/icons-material/Quiz'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

import { Agriculture, GasMeter, Grass, House, InsertPageBreakOutlined, Instagram, PaymentsOutlined, Pool, ReceiptOutlined, Store, WhatsApp, YouTube } from '@mui/icons-material'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { ROUTES_PATH } from './routes'

export const EFFICIENCY_LINK = 'https://www.moval.org/mvu/efficiency-progs.html'
export const OUTAGES_LINK = 'https://WEB Arubawater.org/report-outage'
export const RATES_LINK = 'https://webaruba.com/your-water-bill/water-rates'
export const CONTACT_EMAIL = 'info@webaruba.com'
export const CONTACT_PHONE = '+297 280 4600'
export const WEBSITE = 'https://webaruba.com'
export const ADDRESS = 'Balashi 76, Oranjestad, Aruba'
export const PAYMENT_LINK = 'https://webaruba.com/your-water-bill/making-payments'

export const PAY_TOOLS = [
  {
    title: 'Paperless Billing',
    Icon: InsertPageBreakOutlined,
    navigate: ROUTES_PATH.SETTINGS
  },
  {
    title: 'Pay Bill',
    Icon: PaymentsOutlined,
    navigate: 'https://webaruba.com/your-water-bill/making-payments'
  },
  {
    title: 'View Bill',
    Icon: ReceiptOutlined,
    navigate: ROUTES_PATH.BILLING
  }

]

export const LOGIN_ITEMS = [
  {
    title: 'Start or Stop Service',
    icon: FileCopyIcon,
    link: ROUTES_PATH.SERVICES
  },
  {
    title: 'Pay Bill',
    icon: PaymentsIcon,
    link: PAYMENT_LINK
  },
  {
    title: 'FAQ',
    icon: QuizIcon,
    link: ROUTES_PATH.FAQS
  },
  {
    title: 'Contact Us',
    icon: ChatBubbleIcon,
    link: ROUTES_PATH.CONTACT_US
  }
]

export const SERVICE_ITEMS = [
  {
    title: 'Start Service',
    content:
      'To start new MVU electrical service, please call customer service at 1-844-341-6469.<br><br> Please have the following information available:',
    list: [
      'Service address.',
      'Social security number & driver\'s license number.',
      'Federal Tax ID# (if business).',
      'Primary phone number.',
      'Date to start service (additional fees apply for same day service, weekends, or holidays).',
      'Mailing address (if different from service address).'
    ],

    subcontent:
      '*Please note. There will be a $15.00 activation fee and a $5.00 per person identity verification charge applied to your account.'
  },
  {
    title: 'Stop service',
    content: 'To discontinue MVU residential or commercial electric service please call customer service at 1-844-341-6469. Please have the following information available:',
    list: ['Service address.', 'Personal identification information.']
  },
  {
    title: 'Move service',
    content: 'If you are moving within the MVU service territory please call customer service at 1-844-341-6469 and we\'d be happy to help you transfer your account to your new address.'
  }
]


export const SIDEBAR_ITEMS = [
  { title: 'Dashboard', icon: DashboardIcon, href: ROUTES_PATH.DASHBOARD },
  { title: 'Usage', icon: null, href: ROUTES_PATH.USAGE },
  { title: 'Billing', icon: ReceiptLongIcon, href: ROUTES_PATH.BILLING },
  { title: 'Services', icon: FileCopyIcon, href: ROUTES_PATH.AUTHORIZED_SERVICES },
  { title: 'Contact Us', icon: ChatBubbleIcon, href: ROUTES_PATH.CONTACT },
  { title: 'My Account', icon: AccountIcon, href: ROUTES_PATH.ACCOUNT },
  { title: 'Account Preferences', icon: SettingsOutlinedIcon, href: ROUTES_PATH.SETTINGS },
  { title: 'FAQ', icon: QuizIcon, href: ROUTES_PATH.AUTHORIZEDFAQS }
]

export const UNAUTH_TOPBAR_ITEMS = [
  { title: 'Services', href: ROUTES_PATH.SERVICES },
  { title: 'Contact Us', href: ROUTES_PATH.CONTACT_US },
  { title: 'Frequently Ask Questions', href: ROUTES_PATH.FAQS }
]

export const TOPBAR_ITEMS = [
  { title: 'Usage', href: ROUTES_PATH.USAGE },
  { title: 'Billing', href: ROUTES_PATH.BILLING },
  { title: 'Services', href: ROUTES_PATH.AUTHORIZED_SERVICES },
  { title: 'Contact Us', href: ROUTES_PATH.CONTACT },
  { title: 'My Account', href: ROUTES_PATH.ACCOUNT },
  { title: 'Account Preferences', href: ROUTES_PATH.SETTINGS },
  { title: 'Dashboard', href: ROUTES_PATH.PORTAL_DASHBOARD },
  { title: 'FAQ', href: ROUTES_PATH.AUTHORIZEDFAQS }

]

export const METER_CONNECTIONS = [
  {
    title: 'Building a house',
    icon: House,
    link: 'https://webaruba.com/new-meter-connection/building-house'
  },
  {
    title: 'Building a Commercial building',
    icon: Store,
    link: 'https://webaruba.com/new-meter-connection/commercial-building'
  },
  {
    title: 'Extra water meter on a property',
    icon: GasMeter,
    link: 'https://webaruba.com/new-meter-connection/extra-water-meter-property'
  },
  {
    title: 'Water meter for commercial farming and/or husbandries',
    icon: Agriculture,
    link: 'https://webaruba.com/new-meter-connection/commercial-farming-husbandry'
  },
  {
    title: 'Water meter for non-commercial horticulture and/or husbandries',
    icon: Grass,
    link: 'https://webaruba.com/new-meter-connection/non-commercial-horticulture-husbandry'
  },
  {
    title: 'Water meter for a pool',
    icon: Pool,
    link: 'https://webaruba.com/new-meter-connection/pool'
  }
]

export const MESSAGES = {
  VERIFICATION_CODE_CHECK:
    'A verification code has been sent to your email. Please check your inbox and enter the code below.',
  VERIFICATION_RESENT:
    'A new verification code has been resent. Please allow a few minutes for its delivery. Kindly check your spam folder as well.',
  ACCOUNT_VERIFIED:
    'Your account has been successfully verified. Please proceed to create a password for registration.',
  REQUIRED_NAME_ERROR: 'Please provide your name in the required field.',
  REQUIRED_EMAIL_ERROR:
    'Please enter a valid email address in the required field.',
  REQUIRED_TELEPHONE_ERROR:
    'Please provide a valid telephone number in the required field.',
  REQUIRED_ACCOUNT_NUMBER:
    'Please enter a valid account number in the required field.',
  REQUIRED_SERVICE_ADDRESS:
    'Please enter a valid service address in the required field.',
  SELECT_ONE_ERROR: 'Please select a topic before proceeding.',
  REQUIRED_SUBJECT_ERROR: 'Please enter a subject in the required field.',
  CONTACT_CUSTOMER_ERROR:
    'If this issue persists, please get in touch with our customer service team.',
  OUTAGE_RETRIEVE_ERROR: 'An error occurred while retrieving outage data: ',
  SOMETHING_WRONG_ERROR: 'Oops, something went wrong: ',
  PREFERENCES_UPDATED_SUCCESS:
    'Your preferences have been successfully updated.',
  PREFERENCES_WARN: 'Note: Standard message and data rates may apply.',
  PASSWORD_UPDATED_SUCCESS:
    'Your password has been changed successfully. Please log in using your new password.',
  PASSWORD_MATCH_ERROR: 'New Password and Confirm New Password do not match.',
  ZIP_CODE_ERROR: 'Please provide a valid zip code.',
  STREET_NAME_ERROR: 'Please enter a valid street name.',
  DATE_STOP_SERVICE_ERROR:
    'Please specify the date you would like to stop your service from.',
  WRONG_ERROR: 'Oops, an unexpected error occurred.',
  ENTER_DETAIL_ERROR:
    'Please provide details of your safety concern in the comments section.',
  SERVICE_REQUEST_SUCCESS:
    'Your service request has been successfully submitted.',
  RETRIEVE_ERROR:
    'We are currently experiencing technical difficulties and unable to retrieve your information. Please try again later.',
  IC_RETRIEVE_ERROR: 'Payments are temporarily unavailable.',
  ACCOUNT_RETRIEVE_ERROR:
    'An error occurred while retrieving your account information. Please try again later.',
  FORM_SUBMIT_ERROR:
    'An error occurred while submitting the form. Please try again.',
  FORM_SUBMIT_SUCCESS:
    'Your request has been submitted successfully. You will receive a response within 24 hours. You will now be redirected to the Dashboard.',
  BILL_PDF_ERROR:
    'Your billing and payment information will be available starting in November 2024. For previous billing information, please contact our Call Center at 280-4600 or reach out via WEB ARUBA CHAT on WhatsApp at 525-4600.',
  ACCOUNT_UPDATED_SUCCESS:
    'Your account details has been successfully updated.',
  ACCOUNT_UPDATED_ERROR:
    'An error occurred while updating your account details. Please try again later.',
  UPDATE_LIMIT_EXCEEDED:
    'Please note that you can only update your mailing address once within a 24-hour period. Kindly try again later.',
  USER_CONFIRM_WARNING:
    'Please confirm your email address by entering the confirmation code sent to your inbox.',
  OUTAGES_ERROR:
    'An error occurred while retrieving your outage information. Please try again later.',
  OUTAGES_API_ERROR:
    'An error occurred while fetching outage information. Please try again later.',
  PAYMENT_INFO:
    'If you have made a payment, it may take a few minutes for the balance to reflect the payment.',
  ADMIN_ACCESS_ERROR: 'Administrators are not permitted to do this action.',
  VERIFY_PAY_BILL_ERROR:
    'An error occurred while retrieving your account information. Please double-check your details and try again.',
  VERIFY_PAY_BILL_ERROR_TEMP:
    'Please double check your account number or last name/business name. If this problem persists, call us at: (297) 280 4600',
  EMAIL_UPDATED_SUCCESS:
    'Your email has been changed successfully. Please log in using your new email.',
  INVALID_TELEPHONE_ERROR:
    'Please provide a valid telephone number.',
  IC_PAPERLESS_INFO:
    'If you\'ve updated your preferences, please allow up to 10 minutes for it to reflect in your account',
  CLIPBOARD_COPY:
    'Address copied to clipboard.',
  CLIPBOARD_COPY_ERROR:
    'Failed to copy address.'
}

export const REGEX = {
  FIND_UNDERSCORE: /^[^_]*$/
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const USAGE_MENU_ITEMS = [
  { title: 'Calendar', label: 'Monthly View', icon: 'CalendarIcon' },
  { title: 'CalendarOne', label: 'Daily View', icon: 'Calendar1Icon' },
  { title: 'Clock', label: 'Hourly View', icon: 'ClockIcon' }
]

export const BILLING_QUESTIONS = [
  {
    title: 'Can I view my bill online?',
    content:
      'Yes! Using our online portal "WEBCare" you can view your current water bill by clicking <a href="billing">Billing Module</a>. You can view your water consumption history and payments received at WEB Aruba also. Furthermore, if you register in WEBCare, you can view more details related to your authenticated account.'
  },
  {
    title: 'I don\'t understand my bill, what does it all mean?',
    content:
      'Your monthly water bill has a lot of information on it, <a href="https://www.webaruba.com/your-water-bill/understanding-your-bill" target="_blank">click here</a> for an in-depth explanation of what everything means on your water bill.'
  },
  {
    title: 'How do I make a payment online?',
    content:
      'Visit <a href="https://webaruba.com/your-water-bill/making-payments" target="_blank">https://webaruba.com/your-water-bill/making-payments</a>'
  },
  {
    title: 'I can\'t pay my water bill, can I make a payment arrangement?',
    content:
      'Yes, each customer can make a payment arrangement with WEB Aruba twice per year. Payment arrangements can be requested by account holders by personally visiting WEB Aruba main office in Balashi and bringing a valid ID. If you are not the account holder, you must bring your valid ID, the valid ID of the account holder, and an authorization letter from the account holder.'
  }
]

export const CONTACT_INFORMATION = [
  {
    image: Language,
    title: 'Website',
    description: `<a target="_blank" rel="noopener noreferrer" href=${WEBSITE}>${WEBSITE}</a>`
  },
  {
    image: EmailIcon,
    title: 'Email',
    description: `<a href="mailto:${CONTACT_EMAIL}">Send an Email</a>`
  },
  {
    image: PinDrop,
    title: 'Address',
    description: ADDRESS
  }
]

export const CONTACT_SOCIAL_ITEMS = [

  {
    icon: FacebookIcon,
    title: '/webaruba',
    link: 'https://www.facebook.com/webaruba'
  },
  {
    icon: YouTubeIcon,
    title: '@webarubanv',
    link: 'https://www.youtube.com/user/webarubanv'
  },
  {
    icon: Instagram,
    title: '@webarubanv',
    link: 'https://www.instagram.com/webarubanv'
  },
  {
    icon: WhatsApp,
    title: 'WEB Aruba Chat',
    link: 'https://api.whatsapp.com/message/VTT3XYVOFRKBM1'
  }
]

export const FOOTER_SOCIAL_LINKS = [

  {
    icon: FacebookIcon,
    link: 'https://www.facebook.com/webaruba'
  },
  {
    icon: WhatsApp,
    link: 'https://api.whatsapp.com/message/VTT3XYVOFRKBM1'
  },
  {
    icon: YouTube,
    link: 'https://www.youtube.com/user/webarubanv'
  },

  {
    icon: Instagram,
    link: 'https://www.instagram.com/webarubanv'
  }

]


export const TOPIC_ITEMS = ['WEB Aruba Plant Tour',
  'Vacancy',
  'High Usage',
  'Meter Request',
  'Leakage in WEB Aruba pipeline',
  'What is my last payment date',
  'Others']


export const EFFICIENCY_DATA = {
  title: 'Efficiency',
  subtitle: '',
  type: 'items',
  itemBackground: true,
  items: [
    {
      title: 'AC or HP Tune-up Rebate',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Business Rebate Application',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Commercial Energy Audit...',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Energy Efficiency Program...',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    }
  ]
}

export const ServicesFooterButtonData = [
  {
    title: 'Frequently Ask Questions',
    desc: 'Do you have any doubts or questions? Please visit our frequently questions to find your answer.',
    link: ROUTES_PATH.FAQS,
    linkTitle: 'Go to FAQ',
    icon: QuizIcon
  },
  {
    title: 'Contact Us',
    desc: 'Need additional assistance? Contact us, we are here to help you.',
    link: ROUTES_PATH.CONTACT_US,
    linkTitle: 'Go to Contact Us',
    icon: MailIcon
  }
]

export const ServiceFormRequestType = {
  startService: 'START',
  stopService: 'STOP',
  meterReadVerification: 'MRV',
  safety: 'SAFETY'
}

export const FAQS_ITEMS = [
  {
    title: 'What is Home?',
    content: ['Returns you to the WEBCare log in screen.']
  },
  {
    title: 'What is Log off/sign off?',
    content: [
      `Logging in means you are being an authenticated customer that is allowed to see/view all account related information regarding WEB Aruba. 
      Logging off means you are signing out of the system, no longer wanting to view the account related information.`
    ]
  },
  {
    title: 'What is Reset password?',
    content: [
      `This page allows you to change your password.
      Will also validate the email address that is registered into the WEBCare portal. If for any reason you have not registered into the portal, it will give you the ability to register or contact WEB Aruba for more assistance.`
    ]
  },
  {
    title: 'What is Forgot Email Address?',
    content: [
      'Due to account personal information, the field will only show the first and last characters in the email address.'
    ]
  },
  {
    title: 'What is Problems Signing In?',
    content: [
      'This will direct you to WEB Aruba contact information if you are having any further challenges accessing and or logging into your account.'
    ]
  },
  {
    title: 'What is My account?',
    content: [
      `Allows you to view and edit General Information related to your account.
      Allows you to view all service accounts tied to your account with WEB Aruba.
      Allows you to default to your service account page.
      Allows you to view your current bill details and will redirect you to making payment with WEB Aruba.
      Allows you to view your current bill in PDF format.`
    ]
  },
  {
    title: 'What is Dashboard?',
    content: [
      'Allows you to view all current billing, usage details on one page. Allows you to make changes to your paperless billing.'
    ]
  },
  {
    title: 'What is Billing?',
    content: [
      `Displays your billing history for the last 12 months. You can obtain more detailed information by clicking on the available links. You can view your billing history in a graphical format by clicking on the chart button.
      Displays miscellaneous account information, such as last bill date & last bill amount.
      Displays the amounts billed, payments, adjustments and your running balance. Payments made at a bank, ATM or on-line payments can take several days to register in our system.
      Displays your deposit information.`
    ]
  },
  {
    title: 'What is Paperless Billing?',
    content: [
      'Allows your to make changes to how you would like to receive your Billing Statement from WEB Aruba. There is one option for selection (Electronic PDF format or Receiving bill through Postal Service).'
    ]
  },
  {
    title: 'What is Start or Stop Service?',
    content: [
      `Displays the history and status of any service order requests for your account.
      Displays the WEB Aruba Link to all documents and forms.
      Access to view Information on New Meter Connections by the provided links.`
    ]
  },
  {
    title: 'What is Usage?',
    content: [
      `Displays the dates that your meter was read, the reading of the meter and the billing days.
      Allows you to view your usage history displayed in a table and chart.
      View Rates, is a link that will display a list of WEB Aruba Current Service Rates.`
    ]
  },
  {
    title: 'What is Contact Us?',
    content: [
      'This will direct you to WEB Aruba Contact Information and allows you to contact us by filling out our Inquiry form.'
    ]
  },
  {
    title: 'What is Account Preferences?',
    content: [
      'Allows you to update your account preferences for Marketing Communications and Paperless Billing.'
    ]
  },
  {
    title: 'Additional Web Aruba FAQs',
    content: ['Visit <a href="https://webaruba.com/faq" target="_blank">https://webaruba.com/faq</a> for additional FAQs.']
  },
  {
    title: 'WEBCare Working Hours',
    content: ['Visit <a href="https://webaruba.com/contact" target="_blank">https://webaruba.com/contact</a> to view all WEB Aruba working hours.']
  }
]
