import { Box, Typography } from '@mui/material'
import { GenericDialog, MButton } from 'components'
import { color } from 'config/color'

interface IRemoveUserDialog {
  open: boolean
  isLoading: boolean
  guestUser: {
    personName: string
    personId: string
  }
  accountId: string
  serviceAddress: string
  onClose: () => void
  unlinkPerson: () => void
}

export const RemoveUserDialog = ({
  guestUser,
  serviceAddress,
  accountId,
  open,
  isLoading,
  onClose,
  unlinkPerson
}: IRemoveUserDialog): JSX.Element => {
  return (
    <GenericDialog open={open} onClose={onClose} iconType="error">
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="column">
            <Typography color={color.grey[900]} fontWeight={500} fontSize={20} align='center'>
              Unlinking user from an account
            </Typography>

            <Typography color={color.grey[700]} fontWeight={400} fontSize={16} align='center'>
              You’re about to unlink <span style={{ fontWeight: 'bold', color: color.grey[900] }}>{guestUser.personName}</span> from this account. The user will no longer be able to view it in their portal. However, you can relink them at any time in the future if needed.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <RemoveUserDialogCard accountId={accountId} serviceAddress={serviceAddress} />
        </Box>

        <Box display="flex" flexDirection="column" px={3} py={1} gap={1}>
          <MButton
            loading={isLoading}
            type="submit"
            variant="contained"
            rounded="true"
            texttransform="none"
            onClick={unlinkPerson}
          >
            Unlink User
          </MButton>

          <MButton
            variant="text"
            type="button"
            rounded="true"
            texttransform="none"
            onClick={onClose}
          >
            No, go back to My Account
          </MButton>
        </Box>
      </Box>
    </GenericDialog>
  )
}

export const RemoveUserDialogCard = ({ accountId, serviceAddress }: { accountId: string, serviceAddress: string }): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      bgcolor={color.background[50]}
      p={2}
    >
      <Box display="flex" flexDirection="column" gap={0.5} pt={1}>
        <Typography color={color.grey[700]} fontSize={10} fontWeight={400}>
          ACCOUNT NUMBER
        </Typography>
        <Typography color={color.grey[900]} fontSize={14} fontWeight={400}>
          {accountId}
        </Typography>

        <Typography color={color.grey[700]} fontSize={10} fontWeight={400}>
          SERVICE ADDRESS
        </Typography>
        <Typography color={color.grey[900]} fontSize={14} fontWeight={400}>
          {serviceAddress}
        </Typography>
      </Box>
    </Box>
  )
}
