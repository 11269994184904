import { Box, Typography } from '@mui/material'
import { MButton } from 'components'
import { color } from 'config/color'
import { useLocalStorage, useMobileBreakpoints } from 'utils'
import { AddUserBox } from './AddUserBox'
import { LinkedUserBox } from './LinkedUserBox'
import { AccountTable } from './AccountTable'
import { NewUserDialog } from './NewUserDialog'
import { useEffect, useState } from 'react'
import { RemoveUserDialog } from './RemoveUserDialog'
import { useAuth } from 'context'
import { type ISecondaryPerList } from 'utils/hooks/types'
import { APIs, type IUnlinkGuest } from 'services'
import { USER_TOKEN } from 'config'
import { ResponseDialog } from './ResponseDialog'

export const SecondaryUser = ({ secondaryPerList }: { secondaryPerList: ISecondaryPerList[] }): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  const { account, currentAccount, getAccount } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const { serviceAddresses, accountId } = account?.myAccount ?? {}
  const serviceAddress = serviceAddresses?.filter(i => i.accountId === accountId)[0]?.serviceAddress ?? ''
  const [isNewUserDialogOpen, setIsNewUserDialogOpen] =
    useState<boolean>(false)
  const [isResponseDialogOpen, setIsResponseDialogOpen] =
    useState<boolean>(false)
  const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] =
    useState<boolean>(false)
  const [responseType, setResponseType] =
    useState<'success' | 'error' | 'warning' | null>(null)
  const [dialogType, setDialogType] =
    useState<'add' | 'link' | 'unlink' | null>(null)
  const [personChar, setPersonChar] = useState<boolean>(false)
  const [didChange, setDidChange] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [guestUser, setGuestUser] =
    useState<{
      personName: string
      personId: string
    }>({
      personName: '',
      personId: ''
    })

  useEffect(() => {
    if (currentAccount?.accountId === account?.myAccount?.accountId && !didChange) {
      return
    }
    if (didChange) {
      void getAccount({
        AccessToken: authToken,
        accountId
      })
    }
    setDidChange(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didChange])


  const addUserSubmit = async (data: any): Promise<void> => {
    if ((account?.myAccount.accountId) == null) return
    setIsLoading(true)
    try {
      const body = {
        AccessToken: authToken,
        parentAccount: currentAccount?.accountId ?? account?.myAccount.accountId,
        personName: data.name,
        emailAddress: data.email,
        phoneNumber: data.phoneNumber
      }

      const response = await APIs.addGuest(body)

      if (response.personId !== null) {
        setPersonChar(response.personCharValue ?? false)
        setResponseType('success')
        setDialogType('add')
        setIsResponseDialogOpen(true)
      }
    } catch (e: any) {
      const { data } = e.response
      console.error('SignUp.Form.GUEST', data)
    }

    setIsLoading(false)
    setIsNewUserDialogOpen(false)
  }

  const unlinkPerson = async (): Promise<void> => {
    setIsLoading(true)
    try {
      if (accountId === undefined || guestUser.personId === '') return

      const body: IUnlinkGuest = {
        AccessToken: authToken,
        personId: guestUser.personId,
        accountId,
        unlinkFrom: 'PER' // Make sure the value is either "PER" or "ACT"
      }

      const response = await APIs.unlinkGuest(body)

      if (response?.success === 'true') {
        setResponseType('success')
        setDialogType('unlink')
        setIsResponseDialogOpen(true)
      }

      setIsRemoveUserDialogOpen(false)
    } catch (error) {
      console.error(error)
      setResponseType('error')
    }
    setIsLoading(false)
  }

  return (
    <Box
      bgcolor={color.white}
      p={3}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={2}
    >
      <div
        style={{
          // position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: color.grey[600],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 999
        }}
      >
        <Typography
          color={color.grey[800]}
          fontSize={16}
          fontWeight={400}
          textAlign="center"
        >
          This feature is not yet available.
        </Typography>
      </div>

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="flex-start"
        justifyContent="space-between"
        gap={1}
        sx={{ opacity: 0.5, pointerEvents: 'none' }}

      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            color={color.grey[900]}
            fontSize={isMobile ? 18 : 24}
            fontWeight={600}
          >
            Secondary Users
          </Typography>

          <Typography color={color.grey[800]} fontSize={16} fontWeight={400}>
            Enable up to 2 users to view into the same account, simplifying collaboration. Start sharing now!
          </Typography>
        </Box>

        <MButton
          variant="outlined"
          type="button"
          rounded="true"
          texttransform="none"
          sx={{ width: isMobile ? '100%' : '220px', display: secondaryPerList.length === 2 ? 'none' : 'block' }}
          onClick={() => {
            setIsNewUserDialogOpen(true)
          }}
        >
          Add User
        </MButton>
      </Box>


      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        gap={2}
        sx={{ opacity: 0.5, pointerEvents: 'none' }}
      >

        {secondaryPerList.length > 0 && secondaryPerList.map(secondaryPerson => {
          return (
            <LinkedUserBox
              key={secondaryPerson.personId}
              secondaryPerson={secondaryPerson}
              setIsRemoveUserDialogOpen={setIsRemoveUserDialogOpen}
              setGuestUser={setGuestUser}
            />
          )
        })}

        {secondaryPerList.length === 0 && ([1, 2].map((i) => (
          <AddUserBox key={i} setIsNewUserDialogOpen={setIsNewUserDialogOpen} />
        )))}

        {secondaryPerList.length === 1 && <AddUserBox setIsNewUserDialogOpen={setIsNewUserDialogOpen} />}

        {/* <LinkedUserBox setIsRemoveUserDialogOpen={setIsRemoveUserDialogOpen} /> */}


      </Box>

      <AccountTable
        serviceAddresses={serviceAddresses ?? []}
      />

      <NewUserDialog
        isLoading={isLoading}
        open={isNewUserDialogOpen}
        addUserSubmit={addUserSubmit}
        onClose={() => {
          setIsNewUserDialogOpen(false)
        }}
      />

      <ResponseDialog
        type={responseType}
        dialogType={dialogType}
        personName={guestUser.personName}
        personChar={personChar}
        accountId={accountId ?? ''}
        setDidChange={setDidChange}
        open={isResponseDialogOpen}
        onClose={() => {
          setIsResponseDialogOpen(false)
        }}
      />

      {/* <SelectAccountsDialog
        open={isSelectAccountsDialogOpen}
        onClose={() => {
          setIsSelectAccountsDialogOpen(false)
        }}
      /> */}

      <RemoveUserDialog
        isLoading={isLoading}
        open={isRemoveUserDialogOpen}
        serviceAddress={serviceAddress}
        accountId={accountId ?? ''}
        onClose={() => {
          setIsRemoveUserDialogOpen(false)
        }}
        guestUser={guestUser}
        unlinkPerson={unlinkPerson}
      />
    </Box>
  )
}
