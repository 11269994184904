import { Box } from '@mui/material'
import { Text } from 'components'
import { CONTACT_INFORMATION } from 'config'
import { color } from 'config/color'
import { CallCenterOperator } from './CallCenterOperator'
import { ContactInformation } from './ContactInformation'
import ContactMap from './ContactMap'
import { FindUs } from './FindUs'

interface IProps {
  isAuthenticated?: boolean
}

export const ContactUsByEmail = ({
  isAuthenticated = true
}: IProps): JSX.Element => {
  const contactAddress = [CONTACT_INFORMATION[CONTACT_INFORMATION.length - 1]]
  const contactWebsiteEmail = CONTACT_INFORMATION.slice(0, 2)

  return (
    <Box display='flex' flexDirection='column' flex={1}>
      <Box mb={2}>
        <Text fontSize='22px' fontWeight='700' color={color.grey[900]}>
          Find us at
        </Text>

      </Box>
      <ContactMap />
      <ContactInformation items={(contactAddress)} />
      <CallCenterOperator />
      <ContactInformation items={(contactWebsiteEmail)} />
      <FindUs isAuthenticated={isAuthenticated} />

    </Box>
  )
}
