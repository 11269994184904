import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, MenuItem, Tabs as MuiTabs, Select, Tab, Typography, styled, type SelectChangeEvent, type SxProps } from '@mui/material'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { color } from 'config/color'
import type React from 'react'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import {
  BillingHistory,
  BillingQuestions,
  PaymentHistory,
  UtilityBill
} from './components'
import { DepositRecords } from './components/DepositRecords'

interface BillingTabs {
  title: string
  content: JSX.Element
}
const BILLING_TABS: BillingTabs[] = [
  { title: 'Water Bill', content: <UtilityBill /> },
  { title: 'Billing History', content: <BillingHistory /> },
  { title: 'Payment History', content: <PaymentHistory /> },
  { title: 'Deposit History', content: <DepositRecords /> },
  { title: 'Billing Questions', content: <BillingQuestions /> }
]

export const tabSx: SxProps = {
  p: 3,
  textTransform: 'none',
  fontSize: 18,
  color: color.background[900],
  fontWeight: 400
}

export const Billing: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const isMobile = useMobileBreakpoints()
  const handleTabChange = (_e: any, newActiveTab: number): void => {
    setActiveTab(newActiveTab)
  }
  const [isOpen, setIsOpen] = useState(false)
  const handleSelectChange = (event: SelectChangeEvent): void => {
    setActiveTab(Number(event.target.value))
  }

  return (
    <>
      {isMobile && <QuickLinks />}
      <Box mt={3}>
        {isMobile
          ? <Select variant="standard" fullWidth value={String(activeTab)} onOpen={() => { setIsOpen(true) }} onClose={() => { setIsOpen(false) }} onChange={handleSelectChange} sx={{ bgcolor: color.white, borderBottom: `solid 1px ${color.background[50]}`, p: 2 }} disableUnderline={true} IconComponent={() => isOpen ? <ExpandLessIcon sx={{ color: color.primary[500] }} /> : <ExpandMoreIcon sx={{ color: color.primary[500] }} />}>
            {
              BILLING_TABS.map((tab, index) => {
                return <MenuItem key={index} value={index}><Typography fontSize={24} fontWeight={600} color={color.background[900]} alignItems="center">{tab.title}</Typography></MenuItem>
              })
            }
          </Select>
          : <Tabs variant='fullWidth' value={activeTab} onChange={handleTabChange} TabIndicatorProps={{ sx: { background: color.primary[500], height: 5 } }}>
            {
              BILLING_TABS.map((tab, index) => {
                return <Tab key={index} label={tab.title} sx={{ ...tabSx, borderLeft: index === 0 ? 0 : `solid 1px ${color.background[50]}` }} />
              })
            }
          </Tabs>}
        {BILLING_TABS[activeTab].content}
      </Box >
    </>

  )
}

export const Tabs = styled(MuiTabs)({
  '& .MuiTab-root': {
    background: color.white,
    borderBottom: `solid 1px ${color.background[50]}`
  },
  '& .MuiTab-root.Mui-selected': {
    backgroundColor: color.background[900],
    color: color.white
  }
})
