import { Grid } from '@mui/material'
import { AuthLayout } from 'components'
import { AuthTitle } from 'containers/Pay/Pay'
import { AuthMain, AuthWrapper } from '../SignIn'
import { Form } from './component'

export function ForgotEmail(): JSX.Element {
  return (
    <AuthLayout
      title="Welcome to WEBCare"
      desc="Log in to view your bill, how to make a payment, track your water usage, and set your communication preferences."
      isAuthPage
    >
      <AuthWrapper isAuthPage>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <AuthMain isRounded>
              <AuthTitle title="Forgot Email Address" />
              <Form />
            </AuthMain>
          </Grid>
        </Grid>
      </AuthWrapper>
    </AuthLayout>
  )
}
