import { Phone } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { CONTACT_PHONE } from 'config'
import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export const CallCenterOperator: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box
      display='flex'
      flexDirection='column'
      key={1}
      flex={1}
      flexWrap='wrap'
      sx={{
        alignItems: 'center',
        backgroundColor: color.grey[50],
        padding: '15px',
        boxSizing: 'border-box'
      }}
      mt={2}
    >
      <Box display='flex' width='100%' alignItems='center'>
        <Phone
          sx={{
            color: color.primary[700],
            backgroundColor: color.background[300],
            padding: '16px',
            borderRadius: '100px',
            fontSize: '24px',
            marginRight: '10px'
          }}
        />
        <Box>
          <Typography color={color.grey[900]} fontSize='16px' fontWeight='500'>
            Call Center Operator
          </Typography>

          <Box
          >
            <Box
              flexDirection={isMobile ? 'column' : 'row'}
              display='flex'
              gap={isMobile ? 1 : 2}
              mb={2}
            >
              <Typography color={color.grey[900]} fontSize='16px' fontWeight='400'>
                <span style={{ color: color.grey[700] }}>Tel:</span> {CONTACT_PHONE}
              </Typography>

            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: color.background[50],
          padding: '15px',
          width: '100%',
          mt: '15px',
          boxSizing: 'border-box',
          mb: 0
        }}
      >
        <Typography color={color.grey[900]} fontSize='14px' fontWeight='500' mb={1}>
          Hours Operations:
        </Typography>

        <Box flexDirection={isMobile ? 'column' : 'row'} display='flex' gap={2}>
          <Typography color={color.grey[900]} fontSize='14px' fontWeight='400'>
            7:30 am  to 12:00 pm  - 1:00 pm to 4:30 pm
          </Typography>

        </Box>
      </Box>
    </Box>
  )
}
