import { Box, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IItem {
  Icon: any
  title: string
  route: string
}

export const Item = ({ title, Icon, route }: IItem): JSX.Element => {
  const navigate = useNavigate()
  const isMobile = useMobileBreakpoints()


  const handleItem = (): void => {
    if (title === 'Pay Bill') {
      window.open(route, '_blank')
    } else {
      navigate(route)
    }
  }

  return (
    <Box sx={{ cursor: 'pointer' }} width={isMobile ? 'auto' : '22%'} display="flex" onClick={handleItem} alignItems="center" p={1} borderRadius={1} bgcolor={color.white} mb={2}>
      <IconButton sx={{ background: color.background[50], borderRadius: '2px' }}>
        <Icon sx={{ color: color.primary[600] }} />
      </IconButton>
      <Typography ml={1} color={color.grey[900]} fontSize={16}>{title}</Typography>
    </Box>
  )
}
