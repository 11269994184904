import {
    Box,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material'
import { SvgImage } from 'components'
import { type SyntheticEvent } from 'react'
import { color } from 'config/color'

interface YesNoRadioProps {
    value: boolean
    onChange: (event: SyntheticEvent<Element, Event>, checked: boolean) => void
}

export const YesNoRadio: React.FC<YesNoRadioProps> = (
    props: YesNoRadioProps
) => {
    return (
        <RadioGroup row value={props.value ? 'Y' : 'N'}>
            <Box display='flex' flexWrap='nowrap'>
                <Box pl={1} bgcolor={color.background[400]}>
                    <FormControlLabel
                        name='yes'
                        value='Y'
                        onChange={props.onChange}
                        control={
                            <Radio
                                icon={
                                    <SvgImage name='RadioButtonOffIcon' width={20} height={20} />
                                }
                                checkedIcon={
                                    <SvgImage name='RadioButtonOnIcon' width={20} height={20} />
                                }
                            />
                        }
                        label='Yes'
                    />
                </Box>

                <Divider
                    sx={{ display: 'inline', color: color.background[300] }}
                    orientation='vertical'
                    flexItem
                />

                <Box pl={1} bgcolor={color.background[400]}>
                    <FormControlLabel
                        name='no'
                        value='N'
                        onChange={props.onChange}
                        control={
                            <Radio
                                icon={
                                    <SvgImage name='RadioButtonOffIcon' width={20} height={20} />
                                }
                                checkedIcon={
                                    <SvgImage name='RadioButtonOnIcon' width={20} height={20} />
                                }
                            />
                        }
                        label='No'
                    />
                </Box>
            </Box>
        </RadioGroup>
    )
}
