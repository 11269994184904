import { Box, Dialog, Typography } from '@mui/material'
import type React from 'react'
import { ErrorRounded, InfoRounded, CheckCircleRounded, WarningRounded } from '@mui/icons-material'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface AlertDialogProps {
    type: 'info' | 'warning' | 'error' | 'success'
    message: string
    open: boolean
    handleClick: () => void
}

export const AlertDialog: React.FC<AlertDialogProps> = ({ open, handleClick, type, message }) => {
    const isMobile = useMobileBreakpoints()
    return (
        <Dialog open={open} onClick={handleClick} onClose={handleClick}>
            <Box textAlign="center" px={isMobile ? 3 : 5} py={7} maxWidth={420}>
                {type === 'info' && <InfoRounded sx={{ fontSize: 70 }} color={type} />}
                {type === 'warning' && <WarningRounded sx={{ fontSize: 70 }} color={type} />}
                {type === 'error' && <ErrorRounded sx={{ fontSize: 70 }} color={type} />}
                {type === 'success' && <CheckCircleRounded sx={{ fontSize: 70 }} color={type} />}
                <Box mt={2}>
                    <Typography textTransform="capitalize" textAlign="center" fontSize={24} fontWeight={600} color="#010B0E" mb={1}>{type}</Typography>
                    <Typography textAlign="center" fontSize={16} fontWeight={400} color="#5D6365">{message}</Typography>
                </Box>
            </Box>
        </Dialog>
    )
}
