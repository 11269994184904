import BarChartIcon from '@mui/icons-material/BarChart'
import { Box, Link, Typography } from '@mui/material'
import { ROUTES_PATH } from 'config'
import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export const NoResults = (): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={isMobile ? 5 : 10}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={isMobile ? '100%' : '50%'}
        gap={1}
        textAlign="center"
      >
        <BarChartIcon
          sx={{
            color: color.grey[500],
            backgroundColor: color.grey[100],
            fontSize: 50,
            borderRadius: '50%',
            padding: 3
          }}
        />
        <Typography color={color.grey[900]} fontWeight={500} fontSize={24}>
          No Results Found
        </Typography>

        <Typography color={color.grey[700]} fontWeight={400} fontSize={16}>
          We couldn’t find any data for your meter. If you believe this is an error, please
          <Link
            ml={0.5}
            href={ROUTES_PATH.CONTACT}
          >
            <Typography
              component="span"
              sx={{ textDecoration: 'underline' }}
              color={color.primary[600]}
              fontWeight={400}
              fontSize={16}
            >
              Contact Us.
            </Typography>
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}
