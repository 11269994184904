import MailIcon from '@mui/icons-material/Mail'
import PinDropIcon from '@mui/icons-material/PinDrop'
import StoreIcon from '@mui/icons-material/Store'
import { Avatar, Box, Grid, Link, Typography, styled } from '@mui/material'
import LoginLeft from 'assets/images/login-left.png'
import { AuthLayout } from 'components'
import { LOGIN_ITEMS, ROUTES_PATH, WELCOME_DESCRIPTION, WELCOME_TITLE } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import type React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { Item, LoginForm } from './component'

const PAYMENT_OPTIONS_LINK = 'https://webaruba.com/your-water-bill/making-payments'
const GOOGLE_MAPS_LINK = 'https://maps.app.goo.gl/RJ8gjpYLaebrSfYu6'

export const SignIn: React.FC = () => {
  const { handleLogOut } = useAuth()
  const navigate = useNavigate()
  const isMobile = useMobileBreakpoints()

  useEffect(() => {
    void handleLogOut()
    navigate(ROUTES_PATH.SIGNIN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <AuthLayout
      title={WELCOME_TITLE}
      desc={WELCOME_DESCRIPTION}
      isAuthPage={true}
    >
      <AuthWrapper isAuthPage={true}>
        <Grid container>
          <Grid item sm={0} md={6}>
            <Box
              sx={{
                backgroundSize: 'cover',
                backgroundImage: `url("${LoginLeft}")`,
                height: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthMain>
              <LoginForm />
            </AuthMain>
          </Grid>
          {!isMobile && <Box width="100%" mt="60px">
            <Typography color={color.grey[300]} align="center" fontSize={24} mb={3}>How can we help you?</Typography>

            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              {LOGIN_ITEMS.map((item, index) => (
                <Item key={index} Icon={item.icon} title={item.title} route={item.link} />
              ))}
            </Box>
          </Box>}
        </Grid>
      </AuthWrapper>
      {isMobile && <Box my={3}>
        <Typography color={color.grey[300]} align="center" fontSize={24} mb={3}>How can we help you?</Typography>
        <Box alignItems="center" justifyContent="center">
          {LOGIN_ITEMS.map((item) => {
            return (
              <Item
                key={item.title}
                Icon={item.icon}
                title={item.title}
                route={item.link}
              />
            )
          })}
        </Box>
      </Box>}
      {isMobile
        ? <Box display="flex" flexDirection="column">
          <Box bgcolor={color.background[900]} px={3} py={4}>
            <OtherPaymentOptionsContent />
          </Box>
        </Box>
        : <Box display="flex" gap={2} bgcolor={color.background[900]} px={3} py={4} mt={5} borderRadius={1}>
          <Box width="100%">
            <OtherPaymentOptionsContent />
          </Box>
        </Box>}
    </AuthLayout>
  )
}


export const OtherPaymentOptionsContent: React.FC = () => {
  const isMobile = useMobileBreakpoints()


  return <>
    <Box display="flex" alignItems="center" justifyContent="center" gap="5px">
      <PinDropIcon sx={{ color: color.primary[600] }} />
      <Typography color={color.white} fontSize={18} fontWeight={400}>Other Payment Options</Typography>
    </Box>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} my={5} gap={3}>
      <Box display="flex" flex={1} flexDirection='column' p={3} gap={3} border={`solid 1px ${color.background[800]}`} borderRadius={1}>
        <Avatar sx={{ background: color.background[800], p: 2 }}>
          <MailIcon
            sx={{ color: color.secondary[100], fontSize: 40 }}
          />
        </Avatar>
        <Box>
          <Typography color={color.white} fontSize={18} fontWeight={400} mb={3}>Payment Options</Typography>
          <Typography color={color.white} fontSize={16}>Click <Link target="_blank" rel="noopener" sx={{ color: color.secondary[300], textDecorationColor: color.secondary[300] }} href={PAYMENT_OPTIONS_LINK} > here</Link> to learn how to make a payment.</Typography>
          <Typography color={color.white} fontSize={16}>Unfortunately, we don&apos;t accept checks or money orders.</Typography>
        </Box>
      </Box>
      <Box flex={1} display="flex" flexDirection='column' p={3} gap={3} border={`solid 1px ${color.background[800]}`} borderRadius={1}>
        <Avatar sx={{ background: color.background[800], p: 2 }}>
          <StoreIcon
            sx={{ color: color.secondary[100], fontSize: 40 }}
          />
        </Avatar>
        <Box>
          <Typography color={color.white} fontSize={18} fontWeight={400} mb={3}>Walk-in</Typography>
          <Typography color={color.white} fontSize={16}>WEB Aruba customers have the option to pay their bills at the counters at the Balashi Office.</Typography>
        </Box>

        <Link href={GOOGLE_MAPS_LINK} target="_blank" rel="noopener" sx={{ color: color.secondary[300], textDecorationColor: color.secondary[300] }}>Get Directions</Link>

      </Box>
    </Box >
  </>
}
export const AuthWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isAuthPage'
})<{ isAuthPage?: boolean }>(({ isAuthPage }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: (isAuthPage ?? false) ? '40px 0' : '0'
}))

export const AuthMain = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRounded'
})<{ isRounded?: boolean }>(({ isRounded }) => ({
  backgroundColor: color.white,
  padding: '40px',
  borderRadius: (isRounded ?? false) ? '4px' : '0',
  h4: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '38px',
    textAlign: 'center',
    color: color.black,
    marginBottom: '32px',
    marginTop: 0
  }
}))
