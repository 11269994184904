import { CheckCircle } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { Box, Dialog, IconButton, Typography } from '@mui/material'
import { color } from 'config/color'
import { type ReactNode } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IGenericDialog {
  open: boolean
  onClose: () => void
  isLoading?: boolean
  title?: string
  children?: ReactNode
  iconType?: string
}

export const GenericDialog = ({
  open,
  onClose,
  isLoading,
  title,
  children,
  iconType
}: IGenericDialog): JSX.Element => {
  const isMobile = useMobileBreakpoints()

  const handleClose = (_event: any, reason: string): void => {
    if (reason === 'backdropClick') return
    onClose()
  }

  return (
    <div>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose} disabled={isLoading}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>

          <Box px={4} pb={4}>
            {iconType && <DialogIcon iconType={iconType} />}

            <Typography
              color={color.grey[700]}
              fontSize={isMobile ? 18 : 24}
              fontWeight={600}
              textAlign="center"
            >
              {title}
            </Typography>
            {children}
          </Box>
        </Box>
      </Dialog>
    </div>
  )
}

interface IDialogIcon {
  iconType: string
}
const DialogIcon = ({ iconType }: IDialogIcon): JSX.Element => {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      gap={1}
      borderRadius="4px"
      alignItems="center"
      mb={3}
    >
      {iconType === 'success' && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="80%"
          bgcolor={color.success[50]}
          p={2}
        >
          <CheckCircle sx={{ color: color.success[600], fontSize: 40 }} />
        </Box>
      )}

      {iconType === 'warning' && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="80%"
          bgcolor={color.warning[50]}
          p={2}
        >
          <ErrorOutlineIcon sx={{ color: color.warning[500], fontSize: 40 }} />
        </Box>
      )}

      {iconType === 'error' && (
        <Box
          borderRadius={80}
          bgcolor={color.error[50]}
          p={3}
          display='flex'
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bgcolor={color.error[600]}
            p={1}
          >
            <PriorityHighIcon sx={{ color: color.error[50], fontSize: 40 }} />
          </Box>
        </Box>

      )}
    </Box>
  )
}
