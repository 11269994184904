import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import { Avatar, Box, Dialog, Stack, Typography } from '@mui/material'
import type React from 'react'

interface EmailNotFoundDialogProps {
    open: boolean
    close: () => void
}

export const EmailNotFoundDialog: React.FC<EmailNotFoundDialogProps> = (props: EmailNotFoundDialogProps) => {
    return (
        <Dialog fullWidth open={props.open} onClose={() => { props.close() }}>
            <Box p={3} alignItems="center" justifyContent="center" >
                <Stack my={4} direction="column" gap={1} alignItems="center" justifyContent="center">
                    <Box display="flex" justifyContent="right">
                        <Avatar sx={{ bgcolor: '#F3FBFD', width: 50, height: 50, p: 1 }}>
                            <EmailIcon fontSize='large' sx={{ color: '#B5B7E6' }} />
                        </Avatar>
                        <ErrorIcon sx={{ position: 'absolute', top: 90 }} color='error'></ErrorIcon>
                    </Box>
                    <Typography fontSize={24} color="#010B0E" fontWeight={600} textAlign="center">Email Address Not Found</Typography>
                    <Typography fontSize={16} color="#010B0E" textAlign="center">The email address provided is not registered with WEB Aruba.</Typography>
                    <Typography fontSize={16} color="#010B0E" textAlign="center">Please try again with another email address or contact us at: 297 280 4600.</Typography>
                </Stack>
            </Box>
        </Dialog>
    )
}
