import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Breadcrumbs, IconButton, Link, styled } from '@mui/material'
import { MButton, Text } from 'components'
import { ROUTES_PATH, TOPBAR_ITEMS } from 'config'
import { color } from 'config/color'
import { useAuth, useSidebar } from 'context'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { screenType, useWindowSize } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import QuickLinks from './QuickLinks'
import { ServiceAddress } from './ServiceAddress'

export const TopBar: React.FC = () => {
  const { width } = useWindowSize()
  const location = useLocation()
  const navigate = useNavigate()
  const { openSidebar, setOpenSidebar } = useSidebar()
  const { account, handleLogOut } = useAuth()
  const [page, setPage] = useState<any>({})
  const isMobile = useMobileBreakpoints()

  useEffect(() => {
    const pageData = TOPBAR_ITEMS.find(
      (item) => item.href === location.pathname
    )
    setPage(pageData)
  }, [location.pathname])


  const handleLogout = async (): Promise<void> => {
    await handleLogOut()
    navigate(ROUTES_PATH.SIGNIN)
  }

  return (
    <>
      {isMobile
        ? <Box p={2} pt={3} bgcolor={color.white} display="flex" alignItems="center" justifyContent="space-between">
          <ServiceAddress />
          <IconButton onClick={() => { setOpenSidebar(!openSidebar) }}>
            <MenuIcon fontSize="large" sx={{ color: color.primary[600] }} />
          </IconButton>
        </Box >
        : <Wrapper>
          <BarInner>
            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" p={5} borderBottom={`solid 1px ${color.background[300]}`}>
              {location.pathname === ROUTES_PATH.DASHBOARD
                ? (
                  <Text
                    color={color.black}
                    fontWeight="300"
                    fontSize={screenType(width, 'sm') ? '20px' : '32px'}
                    block="false"
                  >
                    Welcome back&nbsp;
                    <Text
                      color={color.black}
                      fontSize={screenType(width, 'sm') ? '20px' : '32px'}
                      fontWeight="600"
                      block="false"
                    >
                      {account?.myAccount?.personName}
                    </Text>
                  </Text>
                )
                : (
                  <Box>
                    <Text
                      color={color.black}
                      fontWeight="600"
                      fontSize={screenType(width, 'sm') ? '20px' : '32px'}
                      margin="0 0 2px"
                    >
                      {page?.title}
                    </Text>
                    <Breadcrumbs>
                      <Link
                        underline="hover"
                        onClick={() => {
                          navigate(ROUTES_PATH.DASHBOARD)
                        }}
                      >
                        <Text
                          color={color.background[900]}
                          fontSize={screenType(width, 'sm') ? '10px' : '12px'}
                        >
                          Home Page
                        </Text>
                      </Link>
                      <Text
                        color={color.grey[700]}
                        fontSize={screenType(width, 'sm') ? '10px' : '12px'}
                      >
                        {page?.title}
                      </Text>
                    </Breadcrumbs>
                  </Box>
                )}
              <MButton
                variant="text"
                texttransform="none"
                endIcon={<LogoutIcon />}
                onClick={handleLogout}
              >
                Log out
              </MButton>
            </Box>

            {!isMobile && <QuickLinks />}
          </BarInner>
        </Wrapper >}

    </>

  )
}

const Wrapper = styled(Box)`
  margin-left: auto;
  width: calc(100vw - 240px);
  margin-left: 240px;
  background-color: ${color.background[50]};
  position: fixed;
  z-index: 999;
`

const BarInner = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
