import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Accordion as MUIAccordion,
  Typography
} from '@mui/material'
import { color } from 'config/color'
import type React from 'react'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface AccordionProps {
  key?: number
  title: string
  children?: React.ReactNode
}

export const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const isMobile = useMobileBreakpoints()
  const handleChange = (): void => {
    setExpanded(!expanded)
  }
  return (
    <MUIAccordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        '&:before': { height: '0px' }
      }}
      disableGutters={true}
    >
      <AccordionSummary
        sx={{
          bgcolor: expanded ? color.grey[100] : color.grey[50],
          borderBottom: `1px solid ${color.background[200]}`
        }}
        expandIcon={
          <IconButton
            size="small"
            disableRipple={true}
            sx={{ bgcolor: color.grey[200] }}
          >
            {expanded
            ? (
              <RemoveIcon sx={{ color: color.primary[600] }} />
            )
            : (
              <AddIcon sx={{ color: color.primary[600] }} />
            )}
          </IconButton>
        }
      >
        <Typography
          color={color.grey[900]}
          fontSize={isMobile ? 14 : 16}
          mr={2}
          p={isMobile ? 0 : 1}
        >
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: color.grey[50],
          borderLeft: `4px solid ${color.primary[500]}`,
          borderBottom: `1px solid ${color.background[300]}`,
          ul: {
            paddingLeft: '0'
          },
          '.accordion-list': {
            listStyleType: 'none',
            padding: '0',
            marginBottom: '21px'
          },
          '.accordion-list::before': {
            content: "''",
            display: 'inline-block',
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            backgroundColor: color.grey[500],
            marginRight: '8px'
          }
        }}
      >
        {props.children}
      </AccordionDetails>
    </MUIAccordion>
  )
}
