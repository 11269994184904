import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Grid, type SvgIconTypeMap, Typography } from '@mui/material'
import { type OverridableComponent } from '@mui/material/OverridableComponent'
import { METER_CONNECTIONS, ROUTES_PATH, ServicesFooterButtonData } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import type React from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ServiceFooterButton } from './component/ServiceFooterButton'


export const Services: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  const { account } = useAuth()


  return (
    <Box mt={3} bgcolor={color.white} width="100%">

      <Box p={isMobile ? 3 : 5}>
        <Typography
          fontSize={isMobile ? 18 : 20}
          fontWeight={600}
          color="#010B0E"
        >
          New Meter Connections
        </Typography>
        <Typography fontSize={14} color="#5D6365">
          Find out all you need to know about requesting a new meter connection.
        </Typography>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={2}
          my={2}
        >
          {METER_CONNECTIONS.slice(0, 3).map((item, index) => {
            return <ServiceButton
              key={index}
              title={item.title}
              icon={item.icon}
              onClick={() => {
                window.open(item.link, '_blank')
              }}
            />
          })}
        </Box>

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={2}
          my={2}
        >
          {METER_CONNECTIONS.slice(3, 6).map((item, index) => {
            return <ServiceButton
              key={index}
              title={item.title}
              icon={item.icon}
              onClick={() => {
                window.open(item.link, '_blank')
              }}
            />
          })}
        </Box>

      </Box>


      <Box p={isMobile ? 3 : 5}>
        <Typography
          fontSize={18}
          fontWeight={600}
          color={color.grey[900]}
          mb={2}
        >
          Didn&apos;t find what you were looking for? Visit our FAQ page or
          contact us directly.
        </Typography>
        <Box
          display="flex"
          gap={2}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          {ServicesFooterButtonData.map((item, index) => (
            <ServiceFooterButton
              key={index}
              title={item.title}
              desc={item.desc}
              link={account != null && index === 0 ? ROUTES_PATH.AUTHORIZEDFAQS : account != null && index === 1 ? ROUTES_PATH.CONTACT : item.link}
              linkTitle={item.linkTitle}
              icon={item.icon}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

interface ServiceButtonProps {
  icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> | null
  desc?: string
  title: string
  onClick: () => void
}

export const ServiceButton: React.FC<ServiceButtonProps> = (
  props: ServiceButtonProps
) => {
  const isMobile = useMobileBreakpoints()
  return (
    <Grid
      flex={1}
      item
      xs={isMobile ? 12 : 4}
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      onClick={() => {
        props.onClick()
      }}
    >
      <Box
        p={2}
        border={`1px solid ${color.background[300]}`}
        borderRadius={1}
        display="flex"
        flexDirection="column"
        height="80%"
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <OpenInNewIcon
            sx={{ color: color.grey[400], width: 24, height: 24 }}
          />
        </Box>
        <Box mt={2}>
          {props.icon !== null && (
            <props.icon
              sx={{ width: 40, height: 40, color: color.primary[300] }}
            />
          )}
          <Typography fontSize={18} color={color.grey[700]}>
            {props.title}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}
