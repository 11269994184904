import { Box, Link } from '@mui/material'
import { Text } from 'components'
import { CONTACT_SOCIAL_ITEMS } from 'config'
import { color } from 'config/color'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IProps {
  isAuthenticated?: boolean
}

export const FindUs = ({ isAuthenticated = true }: IProps): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box>
      {isAuthenticated && (
        <Box
          display='flex'
          py={3}
          gap={2}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Text color={color.black} fontWeight='500' fontSize='18px'>
            Find us at:
          </Text>
          {CONTACT_SOCIAL_ITEMS.slice(0, 4).map((social, index) => {
            return (
              <Link
                key={index}
                display='flex'
                mb={2}
                alignItems='center'
                sx={{
                  textDecoration: 'none',
                  fontWeight: '400px',
                  fontSize: '16px'
                }}
                href={social.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                <social.icon sx={{ color: color.primary[600] }} />
                <Text
                  fontSize='14px'
                  block='false'
                  color={color.grey[900]}
                  margin='0 0 0 10px'
                >
                  {social.title}
                </Text>
              </Link>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
