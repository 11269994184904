import { Box, Link, Typography } from '@mui/material'
import { GenericDialog, MButton } from 'components'
import { ROUTES_PATH } from 'config'
import { color } from 'config/color'
import { useNavigate } from 'react-router-dom'

interface IResponseDialog {
  open: boolean
  type: 'success' | 'error' | 'warning' | null
  dialogType: 'add' | 'link' | 'unlink' | null
  personName: string
  accountId: string
  personChar: boolean
  onClose: () => void
  setDidChange: (value: boolean) => void
}
export const ResponseDialog = ({
  open,
  type,
  personChar,
  dialogType,
  personName,
  accountId,
  onClose,
  setDidChange
}: IResponseDialog): JSX.Element => {
  const navigate = useNavigate()

  if (type === null || dialogType === null) {
    return <></>
  }
  return (
    <Box>
      <GenericDialog open={open} onClose={onClose} iconType={type}>
        <Box display="flex" flexDirection="column" gap={2}>
          {dialogType === 'link' && type === 'success' && !personChar && (
            <Box display="flex" flexDirection="column">
              <Typography
                color={color.grey[900]}
                fontWeight={500}
                fontSize={24}
                align='center'
              >
                User added but not linked!
              </Typography>

              <Typography
                color={color.grey[700]}
                fontWeight={400}
                fontSize={16}
                align='center'
              >
                You have successfully added a secondary user, once this secondary user finishes the registration flow it will be linked to your selected accounts.
              </Typography>
            </Box>
          )}

          {dialogType === 'unlink' && type === 'success' && (
            <Box display="flex" flexDirection="column">
              <Typography
                color={color.grey[900]}
                fontWeight={500}
                fontSize={24}
                align='center'
              >
                User unlinked
              </Typography>

              <Typography
                color={color.grey[700]}
                fontWeight={400}
                fontSize={16}
                align='center'
              >
                You have successfully unlinked <span style={{ fontWeight: 'bolder' }}> {personName}</span> from <span style={{ fontWeight: 'bolder' }}>Account: {accountId}</span> . However, you can relink them at any time in the future if needed.
              </Typography>
            </Box>
          )}

          {dialogType === 'add' && type === 'success' && (
            <Box display="flex" flexDirection="column">
              <Typography
                color={color.grey[900]}
                fontWeight={500}
                fontSize={24}
                align='center'
              >
                User added and linked!
              </Typography>

              <Typography
                color={color.grey[700]}
                fontWeight={400}
                fontSize={16}
                align='center'
              >
                You have successfully added and linked a secondary user to your selected account(s). If you can’t see this user linked, please {' '}
                <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(ROUTES_PATH.CONTACT) }}>
                  Contact Us
                </Link>.
              </Typography>
            </Box>
          )}

          <Box display="flex" flexDirection="column" gap={1}>
            <MButton
              type="submit"
              variant="contained"
              rounded="true"
              texttransform="none"
              onClick={() => {
                setDidChange(true)
                onClose()
              }}
            >
              Go back to My Account
            </MButton>
          </Box>
        </Box>
      </GenericDialog>
    </Box>
  )
}
