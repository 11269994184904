import { type IAuthForm } from 'containers/Auth/SignIn/component'
import type React from 'react'
import { createContext, useContext } from 'react'
import { type IBilling, type IMeterHistory, type IPayments, type IUpdateMailAddress, type IUsageV2, type IUser } from 'services'
import { useProvideAuth, type UseProvideAuthType } from 'utils'
import { type AccountResponse, type BillingResponse, type CurrentAccountType, type DepositResponse, type MeterRead, type PaymentResponse, type UsageResponse } from 'utils/hooks/types'

interface IAuthProvider {
  children: React.ReactNode
}

const authContext = createContext<UseProvideAuthType>({
  isLoading: false,
  checkingUser: false,
  premiseId: '',
  userEmail: '',
  account: null,
  payments: null,
  billing: null,
  usage: null,
  isAdmin: false,
  getAccount: async function (_param: IUser): Promise<AccountResponse | null> {
    throw new Error('Function not implemented.')
  },
  getPayments: async function (_param: IPayments): Promise<PaymentResponse | null> {
    throw new Error('Function not implemented.')
  },
  getBilling: async function (_param: IBilling): Promise<BillingResponse | null> {
    throw new Error('Function not implemented.')
  },
  getUsage: async function (_param: IUsageV2): Promise<UsageResponse | null> {
    throw new Error('Function not implemented.')
  },
  updateMailAddress: async function (_param: IUpdateMailAddress): Promise<any> {
    throw new Error('Function not implemented.')
  },
  handleLogin: async function (_data: IAuthForm): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  handleLoginFromAdmin: async function (_param: IUser): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  handleLogOut: async function (_url?: string | undefined): Promise<void> {
    throw new Error('Function not implemented.')
  },
  rememberMe: false,
  handleRememberMe: function (_rememberMe: boolean): void {
    throw new Error('Function not implemented.')
  },
  currentAccount: null,
  handleCurrentAccount: function (_param: CurrentAccountType): void {
    throw new Error('Function not implemented.')
  },
  deposits: null,
  meterRead: null,
  getMeterRead: async function (_param: IMeterHistory): Promise<MeterRead[] | null> {
    throw new Error('Function not implemented.')
  },
  getDeposits: async function (_param: IBilling): Promise<DepositResponse | null> {
    throw new Error('Function not implemented.')
  }
})

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = (): UseProvideAuthType => useContext(authContext)
