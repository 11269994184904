import Box from '@mui/material/Box'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { MButton } from 'components'
import { Typography } from '@mui/material'
import { GenericDialog } from 'components/ui/Dialog'
import { color } from 'config/color'

interface IPaperlessBillingDialog {
    open: boolean
    onClose: () => void
    onConfirm: () => Promise<void>
    isLoading: boolean
    paperlessBilling: boolean
}

export const PaperlessBillingDialog = ({
    open,
    onClose,
    onConfirm,
    isLoading,
    paperlessBilling
}: IPaperlessBillingDialog): JSX.Element => {
    const isMobile = useMobileBreakpoints()

    return (
        <Box>
            <GenericDialog
                open={open}
                onClose={onClose}
                isLoading={isLoading}
                title={`${paperlessBilling ? 'Enroll' : 'Unenroll'
                    } from Paperless Billing`}
                iconType='warning'
            >
                <Box>
                    <Typography
                        color={color.grey[700]}
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                    >
                        {`You are about to  ${paperlessBilling ? 'enroll' : 'unenroll'
                            } for paperless billing services.`}
                    </Typography>

                    <Typography
                        color={color.grey[700]}
                        fontSize={isMobile ? 14 : 16}
                        textAlign={isMobile ? 'justify' : 'center'}
                        mb={3}
                    >
                        Do you want to proceed?
                    </Typography>

                    <Box display='flex' flexDirection='column' gap={1}>
                        <MButton
                            variant='contained'
                            size='large'
                            rounded='true'
                            fullWidth
                            onClick={onConfirm}
                            texttransform='none'
                            loading={isLoading}
                        >
                            {`Yes, ${paperlessBilling ? 'enroll' : 'unenroll'
                                } to Paperless Billing`}
                        </MButton>
                        <MButton
                            variant='outlined'
                            type='button'
                            size='large'
                            rounded='true'
                            fullWidth
                            onClick={onClose}
                            texttransform='none'
                            disabled={isLoading}
                        >
                            Discard Changes, back to Account Preferences
                        </MButton>
                    </Box>
                </Box>
            </GenericDialog>
        </Box>
    )
}
