import { yupResolver } from '@hookform/resolvers/yup'
import { Box, OutlinedInput } from '@mui/material'
import { Auth } from 'aws-amplify'
import { ErrorText, MButton } from 'components'
import { ROUTES_PATH } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { BackToLogin } from 'containers/Auth/SignUp/component'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { APIs } from 'services'
import * as yup from 'yup'
import { EmailNotFoundDialog } from './EmailNotFoundDialog'

const schema = yup
  .object({
    email: yup.string().email().required('Email is required')
  })
  .required()

interface IForgotPwdForm {
  email: string
}

export const Form: React.FC = () => {
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IForgotPwdForm>({
    mode: 'all',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })
  const [authError, setAuthError] = useState<string>('')
  const [showEmailUnrecognizedModal, setShowEmailUnrecognizedModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = async (data: IForgotPwdForm): Promise<void> => {
    try {
      setIsLoading(true)
      const registerData = await APIs.checkEmail({ email: data.email })
      if (registerData.emailExists === true) {
        await Auth.forgotPassword(data.email)
        navigate(ROUTES_PATH.FORGOT_PASSWORD_RESET, {
          state: { email: data.email }
        })
      }
    } catch (error) {
      if ((error as any).response?.data?.emailExists === false) {
        setShowEmailUnrecognizedModal(true)
      } else if (error instanceof Error) {
        setAuthError(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <Box my={2}>
        <FormLabel>EMAIL ADDRESS</FormLabel>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              name={name}
              placeholder="Enter your email address"
              onChange={onChange}
              value={value}
              type="text"
              fullWidth
            />
          )}
        />
        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
        {authError && <ErrorText>{authError}</ErrorText>}
      </Box>

      <Box mt={3} textAlign="center">
        <MButton
          variant="contained"
          type="submit"
          size="large"
          rounded="true"
          loading={isLoading}
          disabled={!isValid}
          sx={{ width: '200px', marginTop: '10px', marginBottom: '10px' }}
        >
          Next
        </MButton>
      </Box>

      <BackToLogin />
      <EmailNotFoundDialog open={showEmailUnrecognizedModal} close={() => { setShowEmailUnrecognizedModal(false) }} />
    </form>
  )
}
