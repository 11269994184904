import { Box, MenuItem, Select, Typography } from '@mui/material'
import { FormLabel } from 'components'
import { color } from 'config/color'
import type React from 'react'
import { Controller, type Control, type FieldErrors } from 'react-hook-form'

interface DropDownProps {
  fieldName: string
  fieldLabel: string
  fieldType: string
  control: Control<any>
  errors: FieldErrors<any>
  placeholder?: string
  options: string[]
}

export const DropDown: React.FC<DropDownProps> = (props: DropDownProps) => {
  return (
    <Box mb={2}>
      <Controller
        name={props.fieldName}
        control={props.control}
        render={({ field }) => (
          <Box>
            <FormLabel>{props.fieldLabel.toUpperCase()}</FormLabel>

            <Select
              fullWidth
              displayEmpty
              renderValue={(value) =>
                value.length !== 0
                  ? (
                    <Typography>{value}</Typography>
                  )
                  : (
                    <Typography color={color.grey[400]} fontSize="medium">
                      {props.placeholder}
                    </Typography>
                  )
              }
              {...field}
            >
              {props.options.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      />
    </Box>
  )
}
