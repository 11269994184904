import { Box, Typography, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { Download } from '@mui/icons-material'
import ArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpwardIcon from '@mui/icons-material/KeyboardArrowUp'
import { MButton, ProgressLoaders } from 'components'
import { USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { formatValue, useLocalStorage } from 'utils'
import { exportExcel } from 'utils/helpers/exportExcel'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { ItemWrapper, Wrapper } from './BillingHistory'
import { NoData } from './NoData'

export const PaymentHistory: React.FC = () => {
  const { currentAccount, payments, getPayments, isAdmin, userEmail, account } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === payments?.accountId) {
      return
    }
    void getPayments({
      AccessToken: authToken,
      accountId: currentAccount?.accountId ?? account?.myAccount?.accountId,
      startDate: dayjs().subtract(2, 'year').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      admin: isAdmin,
      email: userEmail
    })
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, userEmail, isAdmin, authToken])


  const handleExportExcel = (): void => {
    const excelData = [
      ['Date', 'Amount', 'Status'],
      ...(payments?.payment?.map((payment) => [payment.paymentDate, payment.paymentAmount, payment.paymentTenderType]) ?? [])
    ]
    exportExcel(excelData, 'Payment History', 'payment_history_data.xlsx')
  }

  const toggleSortOrder = (): void => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }

  const sortedPayments = payments?.payment
    ? [...payments.payment].sort((a, b) => {
      const dateA = new Date(a.paymentDate).getTime()
      const dateB = new Date(b.paymentDate).getTime()
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA
    })
    : []

  return (
    <Wrapper>
      {(payments?.payment !== null) && payments?.payment.length === 0 && (
        <NoData transactionType="payment" />
      )}
      {payments !== null
        ? payments?.payment.length !== 0 && <Box mb="20px">
          {!isMobile && <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <Typography color={color.grey[900]} fontSize={isMobile ? 18 : 24} fontWeight={600}>
              Payment History
            </Typography>
            <MButton
              variant="outlined"
              texttransform="none"
              rounded="true"
              size="large"
              startIcon={<Download />}
              onClick={handleExportExcel}
            >
              Export to Excel
            </MButton>
          </Box>}
          {isMobile
            ? <Box mb={3}>
              {sortedPayments.map((payment, index) => (
                <PaymentEntryMobile key={index} date={payment.paymentDate} amount={payment.paymentAmount} method={payment.paymentTenderType} />
              ))}
            </Box>
            : <Box>
              <BillingHeader>
                <ItemWrapper sx={{ whiteSpace: 'nowrap' }}>Payment Date
                  {sortOrder === 'asc' ? <ArrowUpwardIcon onClick={toggleSortOrder} sx={{ color: `${color.primary[600]}` }} /> : <ArrowDownwardIcon onClick={toggleSortOrder} sx={{ color: `${color.primary[600]}` }} />}
                </ItemWrapper>
                <ItemWrapper>Payment Amount </ItemWrapper>

              </BillingHeader>
              <StyledScrollbar maxHeight="350px" overflow="auto">
                {sortedPayments.slice().reverse().map((payment, index) => (
                  <BillItem key={index}>
                    <ItemWrapper small="true">{dayjs(payment.paymentDate).format('MM/YYYY')}</ItemWrapper>
                    <ItemWrapper small="true">
                      {formatValue(payment.paymentAmount)}
                    </ItemWrapper>

                  </BillItem>
                ))}
              </StyledScrollbar>
            </Box>
          }
          {
            isMobile && <MButton
              sx={{ width: '100%' }}
              size="large"
              variant="outlined"
              rounded="true"
              texttransform="none"
              onClick={handleExportExcel}
            >
              Export to Excel
            </MButton>
          }
        </Box >
        : <ProgressLoaders height="100%" />}
    </Wrapper >
  )
}

export const BillingHeader = styled(Box)({
  display: 'flex',
  padding: '12px 30px',
  background: color.background[50]
})

export const BillItem = styled(Box)({
  display: 'flex',
  padding: '10px 30px',
  borderBottom: '1px solid #eee'
})

interface PaymentEntryMobileProps {
  date: string
  amount: string
  method: string
}

const PaymentEntryMobile: React.FC<PaymentEntryMobileProps> = (props: PaymentEntryMobileProps) => {
  return <Box py={2} borderBottom={`solid 1px ${color.background[50]}`}>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color={color.grey[700]}>Payment Date</Typography>
      <Typography fontSize={14} color={color.grey[900]} fontWeight={700}>{dayjs(props.date).format('MM-DD-YYYY')}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color={color.grey[700]}>Payment Amount</Typography>
      <Typography fontSize={14} color={color.grey[900]} fontWeight={700}>{formatValue(props.amount)}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color={color.grey[700]}>Payment Method</Typography>
      <Typography fontSize={14} color={color.grey[900]} fontWeight={700}>{props.method}</Typography>
    </Box>
  </Box>
}

export const StyledScrollbar = styled(Box)({
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: color.primary[600],
    borderRadius: '4px'
  }
})
