import AlertIcon from 'assets/icons/Alert.svg'
import BillDownloadPdf from 'assets/icons/BillDownload.svg'
import BillingIcon from 'assets/icons/Billing.svg'
import Box1Icon from 'assets/icons/Box1.svg'
import Box2Icon from 'assets/icons/Box2.svg'
import Calendar1Icon from 'assets/icons/Calendar-1.svg'
import CalendarIcon from 'assets/icons/Calendar.svg'
import CampaignIcon from 'assets/icons/Campaign.svg'
import ClockIcon from 'assets/icons/Clock.svg'
import CloseIcon from 'assets/icons/Close.svg'
import CloseModal from 'assets/icons/CloseModal.svg'
import CloseSmallIcon from 'assets/icons/CloseSmall.svg'
import ContactIcon from 'assets/icons/Contact.svg'
import DownloadIcon from 'assets/icons/Download.svg'
import EfficiencyIcon from 'assets/icons/Efficiency.svg'
import EmptyIcon from 'assets/icons/Empty.svg'
import GreenCheckIcon from 'assets/icons/GreenCheck.svg'
import InfoIcon from 'assets/icons/Info.svg'
import LocationIcon from 'assets/icons/Location.svg'
import MailFilledIcon from 'assets/icons/Mail.svg'
import MessageIcon from 'assets/icons/Message.svg'
import NoDataIcon from 'assets/icons/NoData.svg'
import OutagesIcon from 'assets/icons/Outages.svg'
import PayIcon from 'assets/icons/Pay.svg'
import PhoneCallIcon from 'assets/icons/Phone.svg'
import PlusIcon from 'assets/icons/Plus.svg'
import QuestionMark from 'assets/icons/QuestionMark.svg'
import RadioButtonOffIcon from 'assets/icons/RadioButtonOff.svg'
import RadioButtonOnIcon from 'assets/icons/RadioButtonOn.svg'
import ServicesIcon from 'assets/icons/Services.svg'
import ServicesIcon2 from 'assets/icons/ServicesIcon.svg'
import BackIcon from 'assets/icons/Services/Back.svg'
import ServiceOneIcon from 'assets/icons/Services/Icon-1.svg'
import ServiceTwoIcon from 'assets/icons/Services/Icon-2.svg'
import ServiceThreeIcon from 'assets/icons/Services/Icon-3.svg'
import ServiceFourIcon from 'assets/icons/Services/Icon-4.svg'
import ServiceIcon from 'assets/icons/Services/Icon.svg'
import MailIcon from 'assets/icons/Services/Mail.svg'
import StartServiceIcon from 'assets/icons/Services/StartService.svg'
import BillingSIcon from 'assets/icons/Sidebar/Billing.svg'
import ContactSIcon from 'assets/icons/Sidebar/Contact.svg'
import DashboardSIcon from 'assets/icons/Sidebar/Dashboard.svg'
import EfficiencySIcon from 'assets/icons/Sidebar/Efficiency.svg'
import ServicesSIcon from 'assets/icons/Sidebar/Services.svg'
import UsageSIcon from 'assets/icons/Sidebar/Usage.svg'
import SidebarCloseIcon from 'assets/icons/SidebarClose.svg'
import SidebarOpenIcon from 'assets/icons/SidebarOpen.svg'
import PhoneIcon from 'assets/icons/Social/ContactUsPhone.svg'
import EmailIcon from 'assets/icons/Social/Email.svg'
import FBIcon from 'assets/icons/Social/FB.svg'
import InIcon from 'assets/icons/Social/In.svg'
import TWIcon from 'assets/icons/Social/TW.svg'
import BillTIcon from 'assets/icons/Topbar/Bill.svg'
import PayTIcon from 'assets/icons/Topbar/Pay.svg'
import UsageIcon from 'assets/icons/UsageIcon.svg'
import WrongIcon from 'assets/icons/Wrong.svg'
import FacebookIcon from 'assets/icons/facebook.svg'
import LinkedinIcon from 'assets/icons/linkedin.svg'
import TwitterIcon from 'assets/icons/twitter.svg'
import PhoneIphoneIcon from 'assets/icons/PhoneIphoneIcon.svg'
import ViewRatesIcon from 'assets/icons/ViewRates.svg'
import CheckboxIconBlue from 'assets/icons/CheckboxIconBlue.svg'
import CheckboxOutline from 'assets/icons/CheckboxOutline.svg'

interface IProps {
  name: string
  width?: number
  height?: number
  className?: string
}

const IMAGES: any = {
  BillingIcon,
  ContactIcon,
  UsageIcon,
  EfficiencyIcon,
  LocationIcon,
  OutagesIcon,
  ServicesIcon,
  PlusIcon,
  AlertIcon,
  CloseIcon,
  SidebarCloseIcon,
  DashboardSIcon,
  UsageSIcon,
  BillingSIcon,
  ServicesSIcon,
  EfficiencySIcon,
  CampaignIcon,
  ContactSIcon,
  ServicesIcon2,
  BillTIcon,
  PayTIcon,
  SidebarOpenIcon,
  Box1Icon,
  Box2Icon,
  EmptyIcon,
  WrongIcon,
  CloseSmallIcon,
  PayIcon,
  GreenCheckIcon,
  Calendar1Icon,
  CalendarIcon,
  ClockIcon,
  EmailIcon,
  FBIcon,
  InIcon,
  PhoneIcon,
  TWIcon,
  DownloadIcon,
  BillDownloadPdf,
  InfoIcon,
  ServiceIcon,
  ServiceOneIcon,
  ServiceTwoIcon,
  ServiceThreeIcon,
  ServiceFourIcon,
  MailIcon,
  BackIcon,
  StartServiceIcon,
  MessageIcon,
  MailFilledIcon,
  PhoneCallIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  NoDataIcon,
  QuestionMark,
  CloseModal,
  RadioButtonOffIcon,
  RadioButtonOnIcon,
  PhoneIphoneIcon,
  ViewRatesIcon,
  CheckboxIconBlue,
  CheckboxOutline
}

export function SvgImage({ name, width = 0, height = 0, className }: IProps): JSX.Element {
  return (
    <img
      src={IMAGES[name]}
      width={width}
      height={height}
      className={className}
      alt="SVG"
      color=''
    />
  )
}
