import { Box } from '@mui/material'
import 'dayjs/locale/en'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  DropDown,
  MButton,
  ProgressLoaders,
  Text,
  TextInput,
  TextInputMask
} from 'components'
import { MESSAGES, REGEX, ROUTES_PATH, TOPIC_ITEMS, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

interface IProps {
  isAuthenticated?: boolean
}

interface FormType {
  name: string
  accountNumber: string
  serviceAddress: string
  email: string
  number: string
  topic: string
  subject: string
  comments: string
}

export const Form = ({ isAuthenticated = false }: IProps): JSX.Element => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { account, getAccount, currentAccount, isAdmin, userEmail } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const schema = yup
    .object({
      name: yup.string().required(MESSAGES.REQUIRED_NAME_ERROR),
      accountNumber: yup.string().required(MESSAGES.REQUIRED_ACCOUNT_NUMBER),
      serviceAddress: yup.string().required(MESSAGES.REQUIRED_SERVICE_ADDRESS),
      email: yup.string().required(MESSAGES.REQUIRED_EMAIL_ERROR),
      number: yup
        .string()
        .matches(REGEX.FIND_UNDERSCORE, MESSAGES.INVALID_TELEPHONE_ERROR)
        .required(MESSAGES.REQUIRED_TELEPHONE_ERROR),
      topic: yup.string().required(MESSAGES.SELECT_ONE_ERROR),
      subject: yup.string().required(MESSAGES.REQUIRED_SUBJECT_ERROR)
    })
    .required()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: account?.myAccount?.personName ?? '',
      accountNumber: account?.myAccount?.accountId ?? '',
      serviceAddress:
        account?.myAccount?.serviceAddresses[0]?.serviceAddress ?? '',
      email: account?.myAccount?.email ?? '',
      number:
        account?.myAccount?.mobileNumber ??
        account?.myAccount?.alternateNumber ??
        '',
      topic: '',
      subject: '',
      comments: ''
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (!isAuthenticated) return

    if (
      prevAccountId === currentAccount?.accountId ||
      currentAccount?.accountId === account?.myAccount?.accountId
    ) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId(
      currentAccount?.accountId ?? account?.myAccount?.accountId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  const onSubmit = async (data: FormType): Promise<void> => {
    try {
      setIsLoading(true)
      const body = {
        ...data,
        AccessToken: authToken
      }

      await APIs.contactUs(body)

      enqueueSnackbar(MESSAGES.FORM_SUBMIT_SUCCESS, {
        variant: 'success'
      })
      setTimeout(() => {
        navigate(ROUTES_PATH.DASHBOARD)
      }, 7000)
    } catch (error) {
      enqueueSnackbar(MESSAGES.FORM_SUBMIT_ERROR, {
        variant: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {(account !== null && isAuthenticated) || !isAuthenticated
        ? (
          <Box display="flex" flexDirection="column" flex={1} gap={3} mr={2}>
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <Box pb={2}>
                <Box mb={2}>
                  <Text fontSize="22px" fontWeight="700" color={color.grey[900]}>
                    Contact us by email
                  </Text>
                  <span
                    style={{
                      color: color.grey[700],
                      fontSize: '12px',
                      fontWeight: '400'
                    }}
                  >
                    Please allow 1-2 business days for response.
                  </span>
                </Box>
                <TextInput
                  fieldLabel="Name *"
                  fieldName="name"
                  fieldType="text"
                  placeholder="Customer Name"
                  control={control}
                  errors={errors}
                />

                <TextInput
                  fieldLabel="Account Number *"
                  fieldName="accountNumber"
                  fieldType="text"
                  placeholder="Account Number"
                  control={control}
                  errors={errors}
                />

                <TextInput
                  fieldLabel="Service Address *"
                  fieldName="serviceAddress"
                  fieldType="text"
                  placeholder="Service Address"
                  control={control}
                  errors={errors}
                />

                <TextInput
                  fieldLabel="Email Address *"
                  fieldName="email"
                  fieldType="text"
                  placeholder="Email Address"
                  control={control}
                  errors={errors}
                />

                <TextInputMask
                  mask="(999) 999-9999"
                  fieldLabel="Contact Number *"
                  fieldName="number"
                  fieldType="text"
                  placeholder="Contact Number"
                  control={control}
                  errors={errors}
                />

                <DropDown
                  fieldLabel="Topic *"
                  fieldName="topic"
                  fieldType="text"
                  placeholder="Select one option"
                  options={TOPIC_ITEMS}
                  control={control}
                  errors={errors}
                />

                <TextInput
                  fieldLabel="Subject *"
                  fieldName="subject"
                  fieldType="text"
                  placeholder="Subject"
                  control={control}
                  errors={errors}
                />
              </Box>

              <TextInput
                fieldName="comments"
                fieldLabel="ADDITIONAL COMMENTS (OPTIONAL)"
                placeholder="Add any additional comment"
                control={control}
                fieldType="text"
                errors={errors}
                multiline
              />

              <MButton
                fullWidth
                variant="contained"
                type="submit"
                size="large"
                texttransform="none"
                rounded="true"
                disabled={!isValid}
                loading={isLoading}
              >
                Submit Form
              </MButton>
            </form>
          </Box>
        )
        : (
          <Box my={2}>
            <ProgressLoaders height="100%" />
          </Box>
        )}
    </>
  )
}
