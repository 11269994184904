import styled from '@emotion/styled'
import { InputLabel } from '@mui/material'
import { color } from 'config/color'

export const FormLabel = styled(InputLabel)<{ textcolor?: string }>(
  ({ textcolor }) => ({
    fontSize: 12,
    color: textcolor ?? color.grey[700],
    textTransform: 'uppercase',
    letterSpacing: 2,
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  })
)
