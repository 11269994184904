import { Box, Link, Typography, styled } from '@mui/material'
import { ProgressLoaders, Text } from 'components'
import { ROUTES_PATH, USER_TOKEN } from 'config'
import { color } from 'config/color'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
export const Bill: React.FC = () => {
  const { account, currentAccount, getAccount, isAdmin, userEmail } = useAuth()
  const isMobile = useMobileBreakpoints()
  const navigate = useNavigate()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (
      prevAccountId === currentAccount?.accountId ||
      currentAccount?.accountId === account?.myAccount?.accountId
    ) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId(
      currentAccount?.accountId ?? account?.myAccount?.accountId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])
  return (
    <>
      {account !== null
        ? (
          <Wrapper height="100%">
            <Box px={3} py={5}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={3}
              >
                <Box display="flex" flexDirection="column" gap={1}>
                  <Text color={color.black} fontSize="18px" fontWeight="700">
                    Water Bill
                  </Text>
                  <Text color={color.grey[700]} fontSize="12px">
                    Account Number: {account?.myAccount.accountId}
                  </Text>
                </Box>

              </Box>
              <Box
                display="flex"
                py={2}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Box display="flex" flexDirection="column" flex={1} gap={1}>
                  <Link
                    fontSize={14}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(ROUTES_PATH.BILLING)
                    }}
                  >
                    Details
                  </Link>
                  <Box
                    display="flex"
                    flexDirection={isMobile ? 'row' : 'column'}
                    justifyContent="space-between"
                    alignItems={isMobile ? 'center' : 'left'}
                  >
                    <Typography color={color.grey[700]} fontSize={12}>
                      Previous Balance:
                    </Typography>
                    <Typography
                      color={color.grey[900]}
                      fontWeight={600}
                      fontSize={18}
                    >
                      {account?.myAccount?.previousBalance != null &&
                        formatValue(account?.myAccount?.previousBalance)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={isMobile ? 'row' : 'column'}
                    justifyContent="space-between"
                    alignItems={isMobile ? 'center' : 'left'}
                  >
                    <Typography color={color.grey[700]} fontSize={12}>
                      Late Payment/Penalty Charges:
                    </Typography>
                    <Typography color="error" fontWeight={600} fontSize={18}>
                      {account?.myAccount?.lateFees != null &&
                        formatValue(account?.myAccount?.lateFees)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  gap={0.5}
                  my={isMobile ? 2 : 0}
                >
                  <Box
                    display="flex"
                    flexDirection={isMobile ? 'row' : 'column'}
                    justifyContent="space-between"
                    pt={isMobile ? 2 : 0}
                    borderTop={
                      isMobile ? `solid 1px ${color.background[400]}` : 'none'
                    }
                  >
                    <Typography
                      color={color.grey[900]}
                      fontSize={12}
                      letterSpacing={1}
                    >
                      BALANCE DUE
                    </Typography>
                    <Typography color={color.grey[700]} fontSize={10}>
                      Due Date:{' '}
                      {account?.myAccount?.dueDate != null &&
                        dayjs(account?.myAccount?.dueDate).format('MMM D, YYYY')}
                    </Typography>
                  </Box>
                  <Typography
                    fontWeight={700}
                    fontSize={isMobile ? '30px' : '52px'}
                    bgcolor={color.background[50]}
                    py={1}
                    borderRadius={1}
                    border={`solid 1px ${color.background[400]}`}
                    width="100%"
                    textAlign="center"
                  >
                    {account?.myAccount?.accountBalance != null &&
                      formatValue(account?.myAccount?.accountBalance)}
                  </Typography>
                </Box>

              </Box>
            </Box>
          </Wrapper>
        )
        : (
          <ProgressLoaders height="100%" />
        )}
    </>
  )
}

const Wrapper = styled(Box)({
  background: color.white,
  borderRadius: '4px'
})
